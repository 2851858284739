import router from './router'
import store from './store'
import vuetify from './vuetify'
import utils from './helpers/utilities'

require('./validation')
require('./bootstrap')
Vue.prototype.$utils = utils

window.hub = new Vue()

// vue-api-query
import { Model } from 'vue-api-query'
Model.$http = window.axios

// currencyfield
import VCurrencyField from 'v-currency-field'
Vue.use(VCurrencyField, {
    locale: 'de-DE',
    decimalLength: 2,
    autoDecimalMode: true,
    min: null,
    max: null
    //defaultValue: 0
})

// global component registration
import Error from './views/partial/Error'
Vue.component('Error', Error)

// global mixin
import RolesMixin from './mixins/RolesMixin'
Vue.mixin(RolesMixin)

const app = new Vue({
    router,
    store,
    vuetify
}).$mount('#app')
