<template>
    <v-row
        dense
        class="ma-0 pa-0"
    >
        <v-col cols="12" md="7">
            <ValidationProvider
                :vid="`items.${index}.name`"
                name="Artikel"
                rules="required|max:255"
                v-slot="{ errors }"
            >
                <v-autocomplete
                    v-model="selectedItem"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    :search-input.sync="searchItem"
                    :error-messages="errors"
                    cache-items
                    hide-no-data
                    hide-details
                    hide-selected
                    placeholder="Tippe, um die Suche zu beginnen"
                    color="blue-grey"
                    outlined
                    dense
                    class="ma-0 pa-0"
                    :clearable="isItemClearable"
                    @input="updatedItem"
                >
                    <template #label>
                        Artikel <span class="red--text"><strong>* </strong></span>
                    </template>
                </v-autocomplete>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" md="1">
            <ValidationProvider
                :vid="`items.${index}.quantity`"
                name="Anzahl"
                rules="required"
                v-slot="{ errors }"
            >
                <v-text-field
                    v-model.number="itemData.quantity"
                    :error-messages="errors"
                    color="blue-grey"
                    outlined
                    dense
                    class="ma-0 pa-0"
                    step=".1"
                    type="number"
                >
                    <template #label>
                        Anzahl <span class="red--text"><strong>* </strong></span>
                    </template>
                </v-text-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" md="2">
            <ValidationProvider
                :vid="`items.${index}.price`"
                name="Einzelpreis"
                rules="required"
                v-slot="{ errors }">
                <v-currency-field
                    v-model="price"
                    :error-messages="errors"
                    label="Einzelpreis *"
                    color="blue-grey"
                    prefix="€"
                    outlined
                    dense
                    class="ma-0 pa-0"
                ></v-currency-field>
            </ValidationProvider>
        </v-col>
        <v-col cols="12" md="2" class="ma-0 pa-0">
            <v-row dense>
                <v-col cols="11">
                    <v-currency-field
                        v-model="total"
                        label="Preis"
                        color="blue-grey"
                        prefix="€"
                        outlined
                        dense
                        class="ma-0 pa-0"
                        readonly
                    ></v-currency-field>
                </v-col>
                <v-col cols="1">
                    <v-btn depressed
                           color="secondary"
                           @click="removeItem"
                           v-show="showRemoveItemIcon"
                           icon
                    >
                        <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'FIInvoiceItem',
        props: {
            itemData: {
                type: Object,
                default: null
            },
            index: {
                type: Number,
                default: null
            },
            itemDataLength: {
                type: Number,
                default: null
            }
        },
        data () {
            return {
                items: [],
                selectedItem: null,
                searchItem: null
            }
        },
        mounted () {
            if (this.itemData) {
                this.items.push({
                    id: this.itemData.item_id,
                    name: this.itemData.name
                })
                Vue.set(this, 'selectedItem', this.itemData.item_id)
            }
        },
        computed: {
            showRemoveItemIcon () {
                return this.itemDataLength !== 1;
            },
            isItemClearable () {
                return this.itemData.name !== null;
            },
            price: {
                get: function () {
                    return this.itemData.price / 100
                },
                set: function (newValue) {
                    this.itemData.price = parseInt(newValue) * 100
                }
            },
            total: {
                get: function () {
                    return (this.itemData.price / 100) * this.itemData.quantity
                },
                set: function (newValue) {
                    this.itemData.total = parseInt(newValue) * 100
                }
            }
        },
        watch: {
            searchItem (value) {
                value && this.queryItem(value)
            }/*,
            selectedItem (value) {
                if (value === null)
                    return

                if (value === undefined) {
                    //Vue.set(this.itemData, 'quantity', null)
                    Vue.set(this.itemData, 'item_id', null)
                    Vue.set(this.itemData, 'name', null)
                    Vue.set(this.itemData, 'price', null)
                }
                else {
                    if (this.itemData.quantity === null) {
                        Vue.set(this.itemData, 'quantity', 1)
                    }
                    Vue.set(this.itemData, 'item_id', value)
                    Vue.set(this.itemData, 'name', this.items.find(x => x.id === value).name)
                    Vue.set(this.itemData, 'price', this.items.find(x => x.id === value).price)
                }
            }*/
        },
        methods: {
            updatedItem () {
                if (this.selectedItem === null)
                    return

                if (this.selectedItem === undefined) {
                    Vue.set(this.itemData, 'quantity', 1)
                    Vue.set(this.itemData, 'item_id', null)
                    Vue.set(this.itemData, 'name', null)
                    Vue.set(this.itemData, 'price', null)
                }
                else {
                    if (this.itemData.quantity === null) {
                        Vue.set(this.itemData, 'quantity', 1)
                    }
                    Vue.set(this.itemData, 'item_id', this.selectedItem)
                    Vue.set(this.itemData, 'name', this.items.find(x => x.id === this.selectedItem).name)
                    Vue.set(this.itemData, 'price', this.items.find(x => x.id === this.selectedItem).price)
                }
            },
            queryItem (v) {
                window.axios.get(`/fi/item?filter[name]=${v}`)
                    .then((response) => {
                        this.items = response.data
                    })
            },
            removeItem () {
                this.$emit('remove', this.index)
            }
        }
    }
</script>

<style scoped>

</style>
