<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-card>
                <v-card-title>Daten zu eingelagerten Reifen</v-card-title>
                <v-card-subtitle>erstellt von {{ wheelStorageData.created_by }} am {{ wheelStorageData.created_at }}</v-card-subtitle>
                <v-row class="ma-1">
                    <v-col cols="12" lg="3">
                        <v-card tile>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Eingelagert</v-list-item-title>
                                        <v-list-item-subtitle>{{ wheelStorageData.date_stored }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>km-Stand</v-list-item-title>
                                        <v-list-item-subtitle>{{ wheelStorageData.mileage }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="wheelStorageData.date_changed">
                                    <v-list-item-content>
                                        <v-list-item-title>Wechseltermin</v-list-item-title>
                                        <v-list-item-subtitle>{{ wheelStorageData.date_changed }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>RDKS</v-list-item-title>
                                        <v-list-item-subtitle>{{ wheelStorageData.has_tpms }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Radschrauben</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ wheelStorageData.has_wheel_bolts }}
                                            <template v-if="wheelStorageData.wheel_bolts_description">
                                                ({{ wheelStorageData.wheel_bolts_description }})
                                            </template>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Luftdruck (VA/HA)</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ wheelStorageData.pressure_front }}/{{ wheelStorageData.pressure_rear }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>Anzugsdrehmoment (VA/HA)</v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ wheelStorageData.torque_front }}/{{ wheelStorageData.torque_rear }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider></v-divider>
                                <div class="text-center">
                                    <v-btn
                                        class="ma-2"
                                        color="error"
                                        @click="destroyWheelStorage(customerId, vehicleId, wheelStorageId)"
                                    >
                                        Auslagern
                                    </v-btn>
                                    <v-divider></v-divider>
                                    <v-btn
                                        class="ma-2"
                                        color="error"
                                        @click="createWheelStorageProtocol(customerId, vehicleId, wheelStorageId)"
                                    >
                                        Rädereinlagerungsprotokoll erstellen
                                    </v-btn>
                                    <v-btn
                                        class="ma-2"
                                        color="error"
                                        @click="createWheelStorageLabel(customerId, vehicleId, wheelStorageId)"
                                    >
                                        Räderlabel erstellen
                                    </v-btn>
                                </div>
                            </v-list>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="9">
                        <v-card tile>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" lg="6">
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Vorderachse</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.wheels_front }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" lg="6">
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Hinterachse</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.wheels_rear }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" lg="4">
                                        <v-list dense>
                                            <v-subheader>Vorderachse Links</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Profiltiefe</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.depth_front_left }}mm</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>DOT</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.dot_front_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Reifenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_tyre_front_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Felgenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_rim_front_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-divider></v-divider>
                                        <v-list dense>
                                            <v-subheader>Hinterachse Links</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Profiltiefe</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.depth_rear_left }}mm</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>DOT</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.dot_rear_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Reifenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_tyre_rear_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Felgenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_rim_rear_left }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                    <v-col cols="12" lg="4" align="center">
                                        <v-img
                                            src="/img/vehicle_top_view.png"
                                            contain
                                            max-height="70%"
                                        ></v-img>
                                    </v-col>
                                    <v-col cols="12" lg="4">
                                        <v-list dense>
                                            <v-subheader>Vorderachse Rechts</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Profiltiefe</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.depth_front_right }}mm</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>DOT</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.dot_front_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Reifenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_tyre_front_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Felgenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_rim_front_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                        <v-divider></v-divider>
                                        <v-list dense>
                                            <v-subheader>Hinterachse Rechts</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Profiltiefe</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.depth_rear_right }}mm</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>DOT</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.dot_rear_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Reifenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_tyre_rear_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Felgenschäden</v-list-item-title>
                                                    <v-list-item-subtitle>{{ wheelStorageData.damage_rim_rear_right }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleWheelStorageDetails',
        data () {
            return {
                isError: false,
                customerId: this.$route.params.customer,
                vehicleId: this.$route.params.vehicle,
                wheelStorageId: this.$route.params.wheelstorage,
                wheelStorageData: []
            }
        },
        mounted () {
            this.fetchWheelStorageData()
        },
        methods: {
            createWheelStorageProtocol (customerId, vehicleId, wheelStorageId) {
                if (confirm('Möchten Sie ein Rädereinlagerungsprotokoll erstellen?')) {
                    let response = window.axios.put(`/crm/customer/${customerId}/vehicle/${vehicleId}/wheelstorage/create/${wheelStorageId}/protocol`)
                        .then(() => {
                            this.$router.push({ name: 'crm.vehicle.details', params: { customer: customerId, vehicle: vehicleId } })
                        })
                        .catch((e) => {
                            console.log(e)
                            console.log('NYI')
                        })
                }
            },
            createWheelStorageLabel (customerId, vehicleId, wheelStorageId) {
                if (confirm('Möchten Sie ein Räderlabel erstellen?')) {
                    let response = window.axios.put(`/crm/customer/${customerId}/vehicle/${vehicleId}/wheelstorage/create/${wheelStorageId}/label`)
                        .then(() => {
                            this.$router.push({ name: 'crm.vehicle.details', params: { customer: customerId, vehicle: vehicleId } })
                        })
                        .catch((e) => {
                            console.log(e)
                            console.log('NYI')
                        })
                }
            },
            destroyWheelStorage (customerId, vehicleId, wheelStorageId) {
                if (confirm('Möchten Sie die eingelagerten Räder wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}/wheelstorage/${wheelStorageId}`)
                        .then(() => {
                            this.$router.push({ name: 'crm.vehicle.details', params: { customer: customerId, vehicle: vehicleId } })
                        })
                        .catch(() => {
                            console.log('NYI')
                        })
                }
            },
            async fetchWheelStorageData () {
                this.isBusy = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/wheelstorage/${this.wheelStorageId}/details`)
                    .then((response) => {
                        this.wheelStorageData = response.data
                    })
                    .catch((e) => {
                        console.log(e)
                        console.log('NYI')
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
