var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isError)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitCustomerData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neuer Kunde")]:[_vm._v("Kunde Bearbeiten")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"6"}},[(_vm.$hasRole('admin|crm-admin'))?_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Kunde\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2):_vm._e()],1)],1),_vm._v(" "),_c('v-skeleton-loader',{attrs:{"type":"card","transition":"scale-transition","loading":_vm.isLoadingCustomerData}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[(_vm.isEdit)?_c('v-text-field',{attrs:{"label":"Kundennummer","disabled":"","outlined":"","dense":""},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}}):_vm._e(),_vm._v(" "),_c('v-select',{attrs:{"label":"Art","items":_vm.types,"item-text":"description","item-value":"id","color":"blue-grey","loading":_vm.isLoadingTypeData,"autofocus":"","outlined":"","dense":""},model:{value:(_vm.formData.type_id),callback:function ($$v) {_vm.$set(_vm.formData, "type_id", $$v)},expression:"formData.type_id"}}),_vm._v(" "),(_vm.isCompany)?_c('ValidationProvider',{attrs:{"name":"Firmenname","rules":((_vm.isCompany ? 'required|' : '') + "min:3|max:100")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Firmenname","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.company_name),callback:function ($$v) {_vm.$set(_vm.formData, "company_name", $$v)},expression:"formData.company_name"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(_vm.isCompany)?_c('v-switch',{attrs:{"label":"Ansprechpartner hinzufügen","color":"blue-grey","dense":""},model:{value:(_vm.addContactPerson),callback:function ($$v) {_vm.addContactPerson=$$v},expression:"addContactPerson"}}):_vm._e(),_vm._v(" "),(_vm.showContactPerson)?_c('v-row',[_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Titel","rules":("" + (_vm.isCompany ? '' : 'required'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Anrede","items":_vm.salutations,"item-text":"description","item-value":"id","error-messages":errors,"loading":_vm.isLoadingSalutationData,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.salutation_id),callback:function ($$v) {_vm.$set(_vm.formData, "salutation_id", $$v)},expression:"formData.salutation_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Titel","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Titel","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Vorname","rules":((_vm.isCompany ? '' : 'required|') + "min:3|max:70")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("Vorname" + (_vm.isCompany ? '' : '*')),"error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nachname","rules":((_vm.isCompany ? '' : 'required|') + "min:3|max:70")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("Nachname" + (_vm.isCompany ? '' : '*')),"error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Geburtsdatum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(!_vm.isCompany)?_c('v-text-field',{attrs:{"label":"Geburtsdatum","error-messages":errors,"color":"blue-grey","type":"date","outlined":"","dense":""},model:{value:(_vm.formData.date_of_birth),callback:function ($$v) {_vm.$set(_vm.formData, "date_of_birth", $$v)},expression:"formData.date_of_birth"}}):_vm._e()]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Telefon","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Telefon","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Telefax","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Telefax","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.fax),callback:function ($$v) {_vm.$set(_vm.formData, "fax", $$v)},expression:"formData.fax"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Mobil","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mobil","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"E-Mail-Adresse","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"E-Mail-Adresse","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]}}],null,true)})],1)],1)],1)],1)]}}],null,false,529306250)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }