<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Idee</template>
                                <template v-else>Idee</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Idee
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Kategorie" rules="required" v-slot="{ errors }">
                                <v-select
                                    v-model="formData.category_id"
                                    :error-messages="errors"
                                    :items="categoryData"
                                    item-text="description"
                                    item-value="id"
                                    :loading="isLoadingCategoryData"
                                    color="blue-grey"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Kategorie <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-select>
                            </ValidationProvider>
                            <ValidationProvider name="Titel" rules="required|min:3|max:100" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.title"
                                    :error-messages="errors"
                                    hint="kurze Zusammenfassung der Idee"
                                    color="blue-grey"
                                    counter="100"
                                    :loading="isLoadingIdeaBoardData"
                                    outlined
                                    dense
                                    autofocus
                                >
                                    <template #label>
                                        Titel <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Beschreibung" rules="required|min:10|max:1000" v-slot="{ errors }">
                                <v-textarea
                                    v-model="formData.text"
                                    :error-messages="errors"
                                    label="Beschreibung"
                                    color="blue-grey"
                                    counter="1000"
                                    :loading="isLoadingIdeaBoardData"
                                    outlined
                                ></v-textarea>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserIdeaBoardCreateEdit',
        data () {
            return {
                isLoading: false,
                isLoadingIdeaBoardData: false,
                isLoadingCategoryData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Ideenbox', disabled: false, to: { name: 'user.ideaboard.index' }, exact: true }
                ],
                formData: {
                    category_id: 1,
                    status_id: 1,
                    title: null,
                    text: null
                },
                categoryData: []
            }
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'user.ideaboard.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Idee-Details', disabled: false, to: {name: 'user.ideaboard.details', params: {ideaboard: this.$route.params.ideaboard}}, exact: true},
                        {text: 'Idee bearbeiten', disabled: false, to: {name: 'user.ideaboard.edit', params: {ideaboard: this.$route.params.ideaboard}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Idee', disabled: false, to: {name: 'user.ideaboard.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        mounted () {
            this.fetchCategoryData()

            if (this.isEdit) {
                this.fetchData()
            }
        },
        methods: {
            async fetchCategoryData () {
                this.isLoadingCategoryData = true

                await window.axios.get('/user/ideaboard/categories')
                    .then((response) => {
                        this.categoryData = response.data

                        // remove all
                        this.categoryData.splice(0, 1)
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCategoryData = false
                    })
            },
            async fetchData () {
                this.isLoadingIdeaBoardData = true

                let ideaBoardId = this.$route.params.ideaboard

                await window.axios.get(`/user/ideaboard/${ideaBoardId}/edit`)
                    .then((response) => {
                        this.formData = response.data
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingIdeaBoardData = false
                    })
            },
            async submitData() {
                this.isLoading = true

                if (this.isEdit) {
                    let ideaBoardId = this.$route.params.ideaboard

                    await window.axios.put(`/user/ideaboard/${ideaBoardId}/update`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'user.ideaboard.details',
                                    params: {ideaboard: ideaBoardId}
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
                else {
                    await window.axios.post('/user/ideaboard/create', this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'user.ideaboard.details',
                                    params: {ideaboard: response.data.ideaBoardId}
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
