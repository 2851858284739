<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Auftragsbestätigung / Haftungsausschluss {{ documentAssignmentData.number }}</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <!--<v-list-item :to="{ name: 'crm.vehicle.documentassignment.edit', params: { customer: this.$route.params.customer, vehicle: this.$route.params.vehicle, documentassignment: this.$route.params.documentassignment } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Bearbeiten</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>-->
                            <v-list-item @click="destroyDocumentAssignment(customerId, vehicleId, documentAssignmentId)">
                                <v-list-item-icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Löschen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="card"
                transition="scale-transition"
                :loading="isLoadingDocumentAssignmentData"
            >
                <embed :src="documentAssignmentUrl" type="application/pdf" width="100%" height="500px" />
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleDocumentAssignmentDetails',
        data () {
            return {
                isLoadingDocumentAssignmentData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true },
                    { text: 'Kunden-Fahrzeug', disabled: false, to: {name: 'crm.vehicle.details', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true },
                    { text: 'Auftragsbestätigung / Haftungsausschluss anzeigen', disabled: false, to: { name: 'crm.vehicle.documentassignment.details', params: { customer: this.$route.params.customer, vehicle: this.$route.params.vehicle, documentassignment: this.$route.params.documentassignment } }, exact: true }
                ],
                customerId: this.$route.params.customer,
                vehicleId: this.$route.params.vehicle,
                documentAssignmentId: this.$route.params.documentassignment,
                documentAssignmentData: [],
                documentAssignmentUrl: null
            }
        },
        mounted () {
            this.fetchDocumentAssignmentData()
        },
        methods: {
            async fetchDocumentAssignmentData () {
                this.isLoadingInvoiceData = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/documentassignment/${this.documentAssignmentId}/details`)
                    .then((response) => {
                        this.documentAssignmentData = response.data.documentAssignmentData
                        this.documentAssignmentUrl = response.data.documentAssignmentUrl
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingInvoiceData = false
                    })
            },
            destroyDocumentAssignment (customerId, vehicleId, documentAssignmentId) {
                if (confirm('Möchten Sie die Auftragsbestätigung wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}/documentassignment/${documentAssignmentId}`)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'crm.vehicle.details',
                                    params: { customer: customerId, vehicle: vehicleId }
                                })
                            }
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
        }
    }
</script>

<style scoped>

</style>
