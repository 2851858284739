<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitDocumentAssignmentData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Auftragsbestätigung</template>
                                <template v-else>Auftragsbestätigung bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Auftragsbestätigung
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Auftragsnummer" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.number"
                                    label="Auftragsnummer"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    disabled
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Adresse" rules="required" v-slot="{ errors }">
                                <v-select
                                    v-model="formData.address_id"
                                    :error-messages="errors"
                                    :items="addressData"
                                    item-text="description"
                                    item-value="id"
                                    color="blue-grey"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Adresse <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-select>
                            </ValidationProvider>
                            <v-row dense>
                                <v-col cols="12" md="6" v-if="customerData.phone">
                                    <v-switch
                                        v-model="formData.phone"
                                        :label="`Telefon: ${customerData.phone}`"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6" v-if="customerData.fax">
                                    <v-switch
                                        v-model="formData.fax"
                                        :label="`Telefax: ${customerData.fax}`"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6" v-if="customerData.mobile">
                                    <v-switch
                                        v-model="formData.mobile"
                                        :label="`Mobil: ${customerData.mobile}`"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6" v-if="customerData.email">
                                    <v-switch
                                        v-model="formData.email"
                                        :label="`E-Mail: ${customerData.email}`"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                            </v-row>
                            <ValidationProvider name="km-Stand" rules="required|numeric" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.mileage"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        km-Stand <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Wertsachen im Fahrzeug" rules="max:255" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.valuables"
                                    label="Wertsachen im Fahrzeug"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        v-model="formData.report_19_21_stvzo"
                                        label="Einholung von Gutachten nach §19 und §21 StVZO"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        v-model="formData.publication_reference"
                                        label="Fotos des Fahrzeuges als Referenz auf Publikationen"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                            </v-row>
                                <!--<v-radio-group
                                    v-model="formData.report_19_21_stvzo"
                                    label="Einholung von Gutachten nach §19 und §21 StVZO*"
                                    :error-messages="errors"
                                >
                                    <v-radio
                                        label="Nein"
                                        :value="false"
                                        color="blue-grey"
                                    ></v-radio>
                                    <v-radio
                                        label="Ja"
                                        :value="true"
                                        color="blue-grey"
                                    ></v-radio>
                                </v-radio-group>-->
                            <!--<ValidationProvider name="Fotos des Fahrzeuges als Referenz auf Publikationen" rules="required" v-slot="{ errors }">
                                <v-radio-group
                                    v-model="formData.publication_reference"
                                    label="Fotos des Fahrzeuges als Referenz auf Publikationen*"
                                    :error-messages="errors"
                                >
                                    <v-radio
                                        label="Nein"
                                        :value="false"
                                        color="blue-grey"
                                    ></v-radio>
                                    <v-radio
                                        label="Ja"
                                        :value="true"
                                        color="blue-grey"
                                    ></v-radio>
                                </v-radio-group>
                            </ValidationProvider>-->
                            <ValidationProvider
                                name="Beschreibung"
                                rules="required|max:255"
                                v-slot="{ errors }"
                                v-for="(item, index) in formData.description"
                                :key="index"
                            >
                                <v-text-field
                                    v-model="formData.description[index].description"
                                    :key="index"
                                    :value="item"
                                    :label="`auszuführende Arbeiten (Zeile ${index+1})`"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    prepend-icon="mdi-plus-box"
                                    append-icon="mdi-delete"
                                    @click:prepend="addDescription(errors)"
                                    @click:append="removeDescription(index)"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-checkbox
                                v-model="formData.price_package"
                                label="Paketpreis nach Vereinbarung"
                                color="blue-grey"
                            ></v-checkbox>
                            <ValidationProvider name="Preis" rules="required" v-slot="{ errors }" v-if="!formData.price_package">
                                <v-currency-field
                                    v-model="formData.price"
                                    :error-messages="errors"
                                    label="Preis"
                                    color="blue-grey"
                                    prefix="€"
                                    outlined
                                    dense
                                    class="ma-0 pa-0"
                                ></v-currency-field>
                            </ValidationProvider>
                            <ValidationProvider name="Fahrzeugannahme" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.date_signed"
                                    label="Fahrzeugannahme"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="date"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <ValidationProvider name="Fertigstellung" rules="required" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="formData.date_finished"
                                            label="Fahrzeugannahme"
                                            :error-messages="errors"
                                            color="blue-grey"
                                            type="date"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <ValidationProvider name="Uhrzeit" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="formData.time_finished"
                                            label="Uhrzeit"
                                            :error-messages="errors"
                                            color="blue-grey"
                                            type="time"
                                        ></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        v-model="formData.garage_leaflet"
                                        label="Werkstattbeiblatt anhängen"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-switch
                                        v-model="formData.wheels_leaflet"
                                        label="Rädererfassungsbogen anhängen"
                                        color="blue-grey"
                                        dense
                                    ></v-switch>
                                </v-col>
                            </v-row>
                            <!--<ValidationProvider name="Werkstattbeiblatt anhängen" rules="required" v-slot="{ errors }">
                                <v-radio-group
                                    v-model="formData.garage_leaflet"
                                    label="Werkstattbeiblatt anhängen"
                                    :error-messages="errors"
                                >
                                    <v-radio
                                        label="Nein"
                                        :value="false"
                                        color="blue-grey"
                                    ></v-radio>
                                    <v-radio
                                        label="Ja"
                                        :value="true"
                                        color="blue-grey"
                                    ></v-radio>
                                </v-radio-group>
                            </ValidationProvider>
                            <ValidationProvider name="Rädererfassungsbogen anhängen" rules="required" v-slot="{ errors }">
                                <v-radio-group
                                    v-model="formData.wheels_leaflet"
                                    label="Rädererfassungsbogen anhängen"
                                    :error-messages="errors"
                                >
                                    <v-radio
                                        label="Nein"
                                        :value="false"
                                        color="blue-grey"
                                    ></v-radio>
                                    <v-radio
                                        label="Ja"
                                        :value="true"
                                        color="blue-grey"
                                    ></v-radio>
                                </v-radio-group>
                            </ValidationProvider>-->
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleDocumentAssignmentCreate',
        data () {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true },
                    { text: 'Kunden-Fahrzeug', disabled: false, to: {name: 'crm.vehicle.details', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true }
                ],
                customerId: this.$route.params.customer,
                vehicleId: this.$route.params.vehicle,
                customerData: [],
                addressData: [],
                formData: {
                    number: null,
                    address_id: null,
                    phone: false,
                    fax: false,
                    mobile: false,
                    email: false,
                    mileage: null,
                    valuables: null,
                    report_19_21_stvzo: false,
                    publication_reference: false,
                    description: [],
                    price_package: true,
                    price: null,
                    date_signed: new Date().toISOString().slice(0,10),
                    date_finished: new Date().toISOString().slice(0,10),
                    time_finished: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().slice(11,16),
                    garage_leaflet: false,
                    wheels_leaflet: false,
                }
            }
        },
        mounted () {
            this.fetchCustomerData()
            this.addDescription()
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.vehicle.documentassignment.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Auftragsbestätigung bearbeiten', disabled: false, to: {name: 'crm.vehicle.documentassignment.edit', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Auftragsbestätigung', disabled: false, to: {name: 'crm.vehicle.documentassignment.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            addDescription (errors) {
                if (!(typeof errors === "undefined") && errors.length > 0)
                    return

                let checkEmptyDescription = this.formData.description
                    .filter(line => (line.description === null || line.description === ""))

                if (checkEmptyDescription.length >= 1 && this.formData.description.length > 0)
                    return

                this.formData.description.push({
                    description: null
                })
            },
            removeDescription (index) {
                if (this.formData.description.length > 1)
                    this.formData.description.splice(index, 1)
            },
            async submitDocumentAssignmentData() {
                this.isLoading = true

                if (this.isEdit) {
                    try {
                        console.log('NYI')
                    }
                    catch (err) {
                        console.log(err)

                        this.isLoading = false
                    }
                }
                else {
                    await window.axios.post(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/documentassignment/create`, this.formData)
                        .then((response) => {
                            this.$router.push({
                                name: 'crm.vehicle.documentassignment.details',
                                params: {
                                    customer: this.customerId,
                                    vehicle: this.vehicleId,
                                    documentassignment: response.data.documentAssignmentId
                                }
                            })
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            async fetchCustomerData () {
                this.isBusy = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/documentassignment/create`)
                    .then((response) => {
                        this.customerData = response.data.customer
                        this.addressData = response.data.addresses

                        this.formData.number = response.data.number
                        this.formData.address_id = response.data.address_default
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
