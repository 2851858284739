<template>
    <v-app id="erp">
        <v-content>
            <v-container
                class="fill-height"
                fluid
            >
                <v-row
                    align="center"
                    justify="center"
                >
                    <v-col
                        cols="12"
                        sm="8"
                        md="4"
                    >
                        <v-img
                            src="/img/logo_sportwagenwerke.png"
                            max-height="67"
                            class="mb-5"
                            contain
                        ></v-img>
                        <v-card class="elevation-12">
                            <v-toolbar
                                color="red darken-4"
                                dark
                                flat
                            >
                            <v-toolbar-title>Login</v-toolbar-title>
                            </v-toolbar>
                            <v-card-text>
                                <ValidationObserver v-slot="{ invalid }">
                                    <form @submit.prevent="submitData">
                                        <ValidationProvider name="E-Mail-Adresse" rules="required|email" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="loginData.email"
                                                label="E-Mail-Adresse"
                                                :error-messages="errors"
                                                color="blue-grey"
                                            ></v-text-field>
                                        </ValidationProvider>
                                        <ValidationProvider name="Kennwort" rules="required|min:8" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="loginData.password"
                                            label="Kennwort"
                                            :error-messages="errors"
                                            color="blue-grey"
                                            type="password"
                                        ></v-text-field>
                                        </ValidationProvider>
                                        <v-col class="text-right">
                                            <v-btn depressed
                                                   color="secondary"
                                                   :disabled="invalid"
                                                   :loading="isLoading"
                                                   type="submit"
                                            >
                                                Login
                                            </v-btn>
                                        </v-col>
                                    </form>
                                </ValidationObserver>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-snackbar v-model="error">
                Fehler beim Login
                <v-btn
                    color="red"
                    text
                    @click="error = false"
                >
                    Schließen
                </v-btn>
            </v-snackbar>
        </v-content>
    </v-app>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'Login',
        data() {
            return {
                isLoading: false,
                error: false,
                loginData: {
                    email: null,//'andreas@leistungerleben.de',
                    password: null//'Abc,.-123'
                }
            }
        },
        methods: {
            ...mapActions('auth', [
                'login'
            ]),
            async submitData () {
                this.isLoading = true

                this.login(this.loginData).then((res) => {
                    this.$router.push('/erp')
                }).catch(() => {
                    this.error = true
                }).finally(() => {
                    this.isLoading = false
                })
            }
        },
        mounted() {
            axios.get(process.env.MIX_SANCTUM_CSRF_COOKIE_URL)
        }
    }
</script>

<style scoped>
    #erp {
        background-image: url('/img/background.jpg');
        background-size: cover;
        font-family: "Segoe UI", Calibri, Candara, Arial, sans-serif !important;
    }
</style>
