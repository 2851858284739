<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Ideenbox</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <v-list-item :to="{ name: 'user.ideaboard.edit', params: { ideaboard: ideaBoardData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Bearbeiten</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="card"
                v-if="isLoadingItemBoardData"
                transition="scale-transition"
            ></v-skeleton-loader>
            <template v-else>
                <p class="headline">{{ ideaBoardData.title }}</p>
                <p class="font-weight-light ma-0">Kategorie: {{ ideaBoardData.category.description }}</p>
                <p class="font-weight-light mt-0">Status: {{ ideaBoardData.status.description }}</p>
                <p class="subtitle-1">Beschreibung</p>
                <pre class="body-2">{{ ideaBoardData.text }}</pre>
            </template>
            <v-skeleton-loader
                type="card"
                v-if="isLoadingItemBoardCommentData"
                transition="scale-transition"
            ></v-skeleton-loader>
            <template v-else>
                <v-expansion-panels
                    accordion
                    v-model="ideaBoardCommentDataOpenedPanels"
                    multiple
                >
                    <v-expansion-panel
                        v-for="item in ideaBoardCommentData"
                        :key="item.id"
                    >
                        <v-expansion-panel-header>
                            {{ item.user.full_name }} hat einen Kommentar hinzugefügt - {{ item.created_at }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ item.text }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn depressed
                       color="secondary"
                       @click="openComment"
                       :disabled="isCommentEnabled"
                       class="mt-2"
                >
                    <v-icon left small>mdi-comment-outline</v-icon> Kommentar
                </v-btn>
                <ValidationObserver ref="form" v-slot="{ invalid }" v-if="isCommentEnabled">
                    <form @submit.prevent="submitData">
                        <ValidationProvider name="Kommentar" rules="required|min:10|max:1000" v-slot="{ errors }">
                            <v-textarea
                                v-model="formData.text"
                                :error-messages="errors"
                                label="Kommentar"
                                color="blue-grey"
                                counter="1000"
                                class="mt-2"
                                outlined
                            ></v-textarea>
                        </ValidationProvider>
                        <div class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                Hinzufügen
                            </v-btn>
                            <v-btn depressed
                                   color="secondary"
                                   @click="closeComment"
                            >
                                Abbrechen
                            </v-btn>
                        </div>
                    </form>
                </ValidationObserver>
            </template>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserIdeaBoardDetails',
        data () {
            return {
                isLoading: false,
                isLoadingItemBoardData: false,
                isLoadingItemBoardCommentData: false,
                isCommentEnabled: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Ideenbox', disabled: false, to: { name: 'user.ideaboard.index' }, exact: true },
                    { text: 'Idee-Details', disabled: false, to: {name: 'user.ideaboard.details', params: {ideaboard: this.$route.params.ideaboard}}, exact: true }
                ],
                ideaBoardData: [],
                ideaBoardCommentData: [],
                ideaBoardCommentDataOpenedPanels: [],
                formData: {
                    idea_board_id: this.$route.params.ideaboard,
                    text: null
                },
                //categoryData: [],
                //statusData: [],
                //selectedStatus: '',
                //selectedCategory: '',
                //selectedListItemCategory: 0
            }
        },
        mounted () {
            this.fetchData()
            this.fetchCommentData()
            //this.fetchCategoryData()
            //this.fetchStatusData()
        },
        methods: {
            openComment () {
                this.isCommentEnabled = true
            },
            closeComment () {
                this.isCommentEnabled = false
            },
            /*async fetchCategoryData () {
                await window.axios.get('/user/ideaboard/categories')
                    .then((response) => {
                        this.categoryData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            async fetchStatusData () {
                this.isLoadingStatusData = true

                await window.axios.get('/user/ideaboard/status')
                    .then((response) => {
                        this.statusData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoadingStatusData = false
                    })
            },*/
            async fetchData () {
                this.isLoadingItemBoardData = true

                let ideaBoardId = this.$route.params.ideaboard

                await window.axios.get(`/user/ideaboard/${ideaBoardId}/details`)
                    .then((response) => {
                        this.ideaBoardData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoadingItemBoardData = false
                    })
            },
            async fetchCommentData () {
                this.isLoadingItemBoardCommentData = true

                let ideaBoardId = this.$route.params.ideaboard

                await window.axios.get(`/user/ideaboard/${ideaBoardId}/comment/details`)
                    .then((response) => {
                        this.ideaBoardCommentData = response.data
                        response.data.forEach((element, index) => this.ideaBoardCommentDataOpenedPanels.push(index))
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoadingItemBoardCommentData = false
                    })
            },
            async submitData () {
                this.isLoading = true

                let ideaBoardId = this.$route.params.ideaboard

                await window.axios.post(`/user/ideaboard/${ideaBoardId}/comment/create`, this.formData)
                    .then((response) => {
                        if (response.data.success) {
                            this.fetchCommentData()
                            Vue.set(this.formData, 'text', null)
                            this.closeComment()
                        }
                    })
                    .catch((error) => {
                        this.$refs.form.setErrors(error.response.data.errors)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
