var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isError)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitVehicleData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neues Kunden-Fahrzeug")]:[_vm._v("Kunden-Fahrzeug Bearbeiten")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"6"}},[(_vm.$hasRole('admin|crm-admin'))?_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Fahrzeug\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2):_vm._e()],1)],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Marke","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"Marke","error-messages":errors,"items":_vm.brands,"item-text":"description","item-value":"id","loading":_vm.isLoadingVehicleBrandData,"color":"blue-grey","outlined":"","dense":""},on:{"change":_vm.changedBrandsValue},model:{value:(_vm.formData.brand),callback:function ($$v) {_vm.$set(_vm.formData, "brand", $$v)},expression:"formData.brand"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Modell","rules":"required|min:2|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"label":"Modell","error-messages":errors,"items":_vm.models,"item-text":"description","item-value":"id","load":_vm.isLoadingVehicleModelData,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.model),callback:function ($$v) {_vm.$set(_vm.formData, "model", $$v)},expression:"formData.model"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Kennzeichen","rules":"required|min:5|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Kennzeichen*","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.license_plate),callback:function ($$v) {_vm.$set(_vm.formData, "license_plate", $$v)},expression:"formData.license_plate"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"FIN","rules":"required|min:5|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"FIN*","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.vin),callback:function ($$v) {_vm.$set(_vm.formData, "vin", $$v)},expression:"formData.vin"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Erstzulassung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Erstzulassung*","error-messages":errors,"color":"blue-grey","type":"date","outlined":"","dense":""},model:{value:(_vm.formData.registration_date),callback:function ($$v) {_vm.$set(_vm.formData, "registration_date", $$v)},expression:"formData.registration_date"}})]}}],null,true)}),_vm._v(" "),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"HU/AU Monat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"HU/AU Monat*","error-messages":errors,"items":_vm.months,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.inspection_month),callback:function ($$v) {_vm.$set(_vm.formData, "inspection_month", $$v)},expression:"formData.inspection_month"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"HU/AU Jahr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"HU/AU Jahr*","error-messages":errors,"items":_vm.years,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.inspection_year),callback:function ($$v) {_vm.$set(_vm.formData, "inspection_year", $$v)},expression:"formData.inspection_year"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Motorkennbuchstabe","rules":"max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Motorkennbuchstabe","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.engine_code),callback:function ($$v) {_vm.$set(_vm.formData, "engine_code", $$v)},expression:"formData.engine_code"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Getriebkennbuchstabe","rules":"max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Getriebkennbuchstabe","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.gear_code),callback:function ($$v) {_vm.$set(_vm.formData, "gear_code", $$v)},expression:"formData.gear_code"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Herstellerschlüsselnummer (HSN)","rules":"max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Herstellerschlüsselnummer (HSN)","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.manufacturer_code),callback:function ($$v) {_vm.$set(_vm.formData, "manufacturer_code", $$v)},expression:"formData.manufacturer_code"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Typschlüsselnummer (TSN)","rules":"max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Typschlüsselnummer (TSN)","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.model_code),callback:function ($$v) {_vm.$set(_vm.formData, "model_code", $$v)},expression:"formData.model_code"}})]}}],null,true)})],1)],1)],1)]}}],null,false,4149987551)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }