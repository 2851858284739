<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <template v-if="!isBusy">
            <v-card>
                <v-card-title>
                    Zeiten
                    <template v-if="!isEdit">erfassen</template>
                    <template v-else>bearbeiten</template>
                </v-card-title>
                <v-card-text>
                    <ValidationObserver v-slot="{ invalid }">
                        <form @submit.prevent="submitTimeManagementData">
                            <ValidationProvider name="Art" rules="required" v-slot="{ errors }">
                                <v-select
                                    v-model="formData.type_id"
                                    label="Art*"
                                    :items="types"
                                    item-text="description"
                                    item-value="id"
                                    color="blue-grey"
                                    @change="onChangeType"
                                    autofocus
                                ></v-select>
                            </ValidationProvider>
                            <template v-if="isWorkingTime">
                                <v-row>
                                    <v-col md="4" cols="12">
                                        <v-menu
                                            v-model="menu_start_date"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider name="Datum" rules="required" v-slot="{ errors }">
                                                    <v-text-field
                                                        v-model="formData.start_date"
                                                        label="Datum*"
                                                        :error-messages="errors"
                                                        color="blue-grey"
                                                        readonly
                                                        type="date"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker
                                                v-model="formData.start_date"
                                                @input="menu_start_date = false"
                                                @change="onChangeStartDate(1)"
                                                :max="maximumStartDate"
                                                first-day-of-week="1"
                                                color="blue-grey"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col md="4" cols="12">
                                        <ValidationProvider name="Uhrzeit (Kommen)" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.start_time"
                                                label="Uhrzeit Kommen*"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                type="time"
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="4" cols="12">
                                        <ValidationProvider name="Uhrzeit (Gehen)" rules="required" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.end_time"
                                                label="Uhrzeit Gehen*"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                type="time"
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-else>
                                <v-row>
                                    <v-col md="6" cols="12">
                                        <v-menu
                                            v-model="menu_start_date"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider name="Datum von" rules="required" v-slot="{ errors }">
                                                    <v-text-field
                                                        v-model="formData.start_date"
                                                        label="Datum von*"
                                                        :error-messages="errors"
                                                        color="blue-grey"
                                                        readonly
                                                        type="date"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker
                                                v-model="formData.start_date"
                                                @input="menu_start_date = false"
                                                @change="onChangeStartDate"
                                                :max="maximumStartDate"
                                                first-day-of-week="1"
                                                color="blue-grey"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col md="6" cols="12">
                                        <v-menu
                                            v-model="menu_end_date"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider name="Datum bis" rules="required" v-slot="{ errors }">
                                                    <v-text-field
                                                        v-model="formData.end_date"
                                                        label="Datum bis*"
                                                        :error-messages="errors"
                                                        color="blue-grey"
                                                        readonly
                                                        type="date"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker
                                                v-model="formData.end_date"
                                                @input="menu_end_date = false"
                                                :min="minimumEndDate"
                                                first-day-of-week="1"
                                                color="blue-grey"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <template v-if="isVacationTime">
                                    <ValidationProvider rules="required|min_value:0" v-slot="{ errors }">
                                        <v-text-field
                                            v-model="remainingVacationDays"
                                            :error-messages="errors"
                                            color="blue-grey"
                                            v-show="false"
                                        ></v-text-field>
                                    </ValidationProvider>
                                    <p class="ma-0 pa-0">
                                        Urlaub dieses Jahr gesamt: <strong>{{ vacationDaysTotal }}</strong>
                                    </p>
                                    <p class="ma-0 pa-0">
                                        Urlaub dieses Jahr geplant: <strong>{{ vacationDaysUsed }}</strong>
                                    </p>
                                    <p class="ma-0 pa-0">
                                        Urlaub noch planbar:
                                        <strong>
                                            <span class="green--text" v-if="remainingVacationDays > 0">
                                                {{ remainingVacationDays }}
                                            </span>
                                            <span class="red--text" v-else>
                                                {{ remainingVacationDays }}
                                            </span>
                                        </strong>
                                    </p>
                                </template>
                            </template>
                            <v-col class="text-right">
                                <v-btn :disabled="invalid" :loading="isLoading" type="submit">
                                    <template v-if="!isEdit">Speichern</template>
                                    <template v-else>Aktualisieren</template>
                                </v-btn>
                            </v-col>
                        </form>
                    </ValidationObserver>
                </v-card-text>
            </v-card>
        </template>
    </v-content>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'UserTimeManagementCreateEdit',
        data () {
            return {
                isBusy: false,
                isLoading: false,
                types: [],
                menu_start_date: false,
                menu_end_date: false,
                vacationDaysTotal: Number,
                vacationDaysUsed: Number,
                formData: {
                    type_id: null,
                    start_date: moment().format('YYYY-MM-DD'),
                    start_time: moment().format('HH:mm'),
                    end_date: moment().format('YYYY-MM-DD'),
                    end_time: moment().add(8, 'hours').format('HH:mm')
                }
            }
        },
        mounted () {
            this.loadTimeManagementData()
            this.onChangeStartDate()
        },
        computed: {
            isEdit () {
                return this.$route.name === 'user.timemanagement.edit' ? true : false
            },
            isWorkingTime () {
                return this.formData.type_id === 1 ||
                       this.formData.type_id === 2 ?
                            true : false
            },
            isVacationTime () {
                return this.formData.type_id === 5 ? true : false
            },
            vacationDaysPlanned () {
                let startDate = moment(this.formData.start_date)
                let endDate = moment(this.formData.end_date)
                let dayCounter = 0

                while (startDate <= endDate) {
                    if (startDate.isoWeekday() !== 6 && startDate.isoWeekday() !== 7) {
                        dayCounter++
                    }

                    startDate.add(1, 'days')
                }

                return dayCounter
            },
            remainingVacationDays () {
                return this.vacationDaysTotal - this.vacationDaysUsed - this.vacationDaysPlanned
            },
            maximumStartDate () {
                return moment().format('YYYY-12-31')
            },
            minimumEndDate () {
                return this.formData.start_date
            }
        },
        methods: {
            onChangeType (typeId) {
                this.onChangeStartDate(typeId)
                //this.formData.start_date = moment().format('YYYY-MM-DD')
                //this.formData.end_date = moment().format('YYYY-MM-DD')
            },
            onChangeStartDate (typeId) {
                // bei Arbeitszeit
                // zukünftiges Startdatum auf aktuelles Datum setzen
                // Ende-Datum immer auf Start-Datum
                if (typeId === 1) {
                    this.formData.start_date = this.formData.start_date > moment().format('YYYY-MM-DD') ?
                        moment().format('YYYY-MM-DD') :
                        this.formData.start_date

                    this.formData.end_date = this.formData.start_date
                }

                // erst jetzt geht die Überprüfung für den Rest los
                let startDate = moment(this.formData.start_date)

                // Samstag/Sonntag auf Freitag
                if (startDate.isoWeekday() === 6 || startDate.isoWeekday() === 7) {
                    startDate = startDate.isoWeekday() === 6 ?
                        startDate.add(-1, 'days') :
                        startDate.isoWeekday() === 7 ?
                            startDate.add(-2, 'days') :
                            startDate

                    this.formData.start_date = startDate.format('YYYY-MM-DD')
                    this.formData.end_date = this.formData.start_date
                }

                // Ende-Datum ist größer als Start-Datum
                this.formData.end_date = this.formData.end_date < this.formData.start_date ?
                    this.formData.start_date :
                    this.formData.end_date
            },
            async loadTimeManagementData () {
                this.isBusy = true

                await window.axios.get('/user/timemanagement/create')
                    .then((response) => {
                        this.types = response.data.types
                        this.formData.type_id = response.data.type_default
                        this.vacationDaysTotal = response.data.vacationDaysTotal
                        this.vacationDaysUsed = response.data.vacationDaysUsed
                    })
                    .catch((e) => {
                        console.log(e)
                        console.log('NYI')
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            },
            async submitTimeManagementData () {
                this.isLoading = true

                await window.axios.post('/user/timemanagement', this.formData)
                    .then((response) => {
                        this.$router.push({ name: 'user.timemanagement.index' })
                    })
                    .catch((e) => {
                        console.log(e)
                        console.log('NYI')
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
