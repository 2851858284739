import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index.js'
//import NProgress from 'nprogress'

// Auth
import Login from './views/auth/Login.vue'
import Logout from './views/auth/Logout.vue'

// Layouts
import Layout from './views/layout/Layout.vue'
import LayoutLogin from './views/auth/Login.vue'

// Common
import DashboardIndex from './views/dashboard/Index.vue'

// User
import UserIndex from './views/user/Index.vue'
import UserDetails from './views/user/Details.vue'
import UserCreateEdit from './views/user/Create.vue'
import UserPasswordEdit from './views/user/password/Edit.vue'
import UserPasswordReset from './views/user/password/Reset.vue'

// User Time Management
import UserTimeManagementIndex from './views/user/timemanagement/Index.vue'
import UserTimeManagementCreateEdit from './views/user/timemanagement/Create.vue'
import UserTimeManagementStatisticsIndex from './views/user/timemanagement/statistics/Index.vue'

// User IdeaBoard
import UserIdeaBoardIndex from './views/user/ideaboard/Index.vue'
import UserIdeaBoardDetails from './views/user/ideaboard/Details.vue'
import UserIdeaBoardCreateEdit from './views/user/ideaboard/Create.vue'

// CRM
import CRMCustomerIndex from './views/crm/customer/Index.vue'
import CRMCustomerDetails from './views/crm/customer/Details.vue'
import CRMCustomerCreateEdit from './views/crm/customer/Create.vue'
import CRMCustomerAddressCreateEdit from './views/crm/customer/address/Create.vue'
import CRMCustomerVehicleCreateEdit from './views/crm/vehicle/Create.vue'
import CRMVehicleIndex from './views/crm/vehicle/Index.vue'
import CRMVehicleDetails from './views/crm/vehicle/Details.vue'
import CRMVehicleNoticeCreate from './views/crm/vehicle/notice/Create.vue'
import CRMVehicleDocumentCreate from './views/crm/vehicle/document/Create.vue'
import CRMVehicleGalleryCreate from './views/crm/vehicle/gallery/Create.vue'
import CRMVehicleDocumentAssignmentCreateEdit from './views/crm/vehicle/documentassignment/Create.vue'
import CRMVehicleDocumentAssignmentDetails from './views/crm/vehicle/documentassignment/Details.vue'
import CRMVehicleWheelStorageDetails from './views/crm/vehicle/wheelstorage/Details.vue'
import CRMVehicleWheelStorageCreate from './views/crm/vehicle/wheelstorage/Create.vue'

// FI
import FIItemIndex from './views/fi/item/Index.vue'
import FIItemCreateEdit from './views/fi/item/Create.vue'
import FIInvoiceIndex from './views/fi/invoice/Index.vue'
import FIInvoiceDetails from './views/fi/invoice/Details.vue'
import FIInvoiceCreateEdit from './views/fi/invoice/Create.vue'

// General Components
import NotFound from './views/layout/NotFound.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: LayoutLogin,
        meta: { redirectIfAuthenticated: true },
        children: [
            {
                path: '/',
                component: Login
            },
            {
                path: 'login',
                component: Login,
                name: 'login'
            },
        ]
    },
    {
        path: '/erp',
        meta: { requiresAuth: true },
        component: Layout,
        children: [
            {path: '/', name: 'dashboard', component: DashboardIndex},
            {path: 'logout', name: 'logout', component: Logout},

            // User
            {path: 'user', name: 'user.index', component: UserIndex},
            {path: 'user/:user/details', name: 'user.details', component: UserDetails},
            {path: 'user/create', name: 'user.create', component: UserCreateEdit},
            {path: 'user/:user/edit', name: 'user.edit', component: UserCreateEdit},
            {path: 'user/:user/password/edit', name: 'user.password.edit', component: UserPasswordEdit},
            {path: 'user/:user/password/reset', name: 'user.password.reset', component: UserPasswordReset},

            // User Time Management
            {path: 'user/timemanagement', name: 'user.timemanagement.index', component: UserTimeManagementIndex},
            {path: 'user/timemanagement/create', name: 'user.timemanagement.create', component: UserTimeManagementCreateEdit},
            {path: 'user/timemanagement/edit', name: 'user.timemanagement.edit', component: UserTimeManagementCreateEdit},
            {path: 'user/timemanagement-statistics', name: 'user.timemanagement-statistics.index', component: UserTimeManagementStatisticsIndex},

            // User IdeaBoard
            {path: 'user/ideaboard', name: 'user.ideaboard.index', component: UserIdeaBoardIndex},
            {path: 'user/ideaboard/:ideaboard/details', name: 'user.ideaboard.details', component: UserIdeaBoardDetails},
            {path: 'user/ideaboard/create', name: 'user.ideaboard.create', component: UserIdeaBoardCreateEdit},
            {path: 'user/ideaboard/:ideaboard/edit', name: 'user.ideaboard.edit', component: UserIdeaBoardCreateEdit},

            // CRM Customer
            {path: 'crm/customer', name: 'crm.customer.index', component: CRMCustomerIndex},
            {path: 'crm/customer/:customer/details', name: 'crm.customer.details', component: CRMCustomerDetails},
            {path: 'crm/customer/create', name: 'crm.customer.create', component: CRMCustomerCreateEdit},
            {path: 'crm/customer/:customer/edit', name: 'crm.customer.edit', component: CRMCustomerCreateEdit},

            // CRM Customer Address
            {path: 'crm/customer/:customer/address/create', name: 'crm.customer.address.create', component: CRMCustomerAddressCreateEdit},
            {path: 'crm/customer/:customer/address/:address/edit', name: 'crm.customer.address.edit', component: CRMCustomerAddressCreateEdit},

            // CRM Customer Vehicle
            {path: 'crm/vehicle', name: 'crm.vehicle.index', component: CRMVehicleIndex},
            {path: 'crm/customer/:customer/vehicle/:vehicle/details', name: 'crm.vehicle.details', component: CRMVehicleDetails},
            {path: 'crm/customer/:customer/vehicle/create', name: 'crm.vehicle.create', component: CRMCustomerVehicleCreateEdit},
            {path: 'crm/customer/:customer/vehicle/:vehicle/edit', name: 'crm.vehicle.edit', component: CRMCustomerVehicleCreateEdit},
            {path: 'crm/customer/:customer/vehicle/:vehicle/notice/create', name: 'crm.vehicle.notice.create', component: CRMVehicleNoticeCreate},
            {path: 'crm/customer/:customer/vehicle/:vehicle/document/create', name: 'crm.vehicle.document.create', component: CRMVehicleDocumentCreate},
            {path: 'crm/customer/:customer/vehicle/:vehicle/gallery/create', name: 'crm.vehicle.gallery.create', component: CRMVehicleGalleryCreate},
            {path: 'crm/customer/:customer/vehicle/:vehicle/documentassignment/:documentassignment/details', name: 'crm.vehicle.documentassignment.details', component: CRMVehicleDocumentAssignmentDetails},
            {path: 'crm/customer/:customer/vehicle/:vehicle/documentassignment/create', name: 'crm.vehicle.documentassignment.create', component: CRMVehicleDocumentAssignmentCreateEdit},
            {path: 'crm/customer/:customer/vehicle/:vehicle/documentassignment/:documentassignment/edit', name: 'crm.vehicle.documentassignment.edit', component: CRMVehicleDocumentAssignmentCreateEdit},
            {path: 'crm/customer/:customer/vehicle/:vehicle/wheelstorage/:wheelstorage/details', name: 'crm.vehicle.wheelstorage.details', component: CRMVehicleWheelStorageDetails},
            {path: 'crm/customer/:customer/vehicle/:vehicle/wheelstorage/create', name: 'crm.vehicle.wheelstorage.create', component: CRMVehicleWheelStorageCreate},

            // FI Item
            {path: 'fi/item', name: 'fi.item.index', component: FIItemIndex},
            {path: 'fi/item/create', name: 'fi.item.create', component: FIItemCreateEdit},
            {path: 'fi/item/:item/edit', name: 'fi.item.edit', component: FIItemCreateEdit},

            // FI Invoice
            {path: 'fi/invoice', name: 'fi.invoice.index', component: FIInvoiceIndex},
            {path: 'fi/invoice/:invoice/details', name: 'fi.invoice.details', component: FIInvoiceDetails},
            {path: 'fi/invoice/create', name: 'fi.invoice.create', component: FIInvoiceCreateEdit},
            {path: 'fi/invoice/:invoice/edit', name: 'fi.invoice.edit', component: FIInvoiceCreateEdit},
        ]
    },
    {
        path: '*',
        name: 404,
        component: NotFound
    }
]

const router = new VueRouter({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(m => m.meta.requiresAuth)) {
        if (!store.getters['auth/isAuthenticated']) {
            return next('/login')
        }
    }

    if (to.matched.some(m => m.meta.redirectIfAuthenticated) && store.getters['auth/isAuthenticated']) {
        return next('/erp')
    }

    return next()
});

/*
router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})

router.afterEach((to, from) => {
    NProgress.done()
})
*/

export default router
