var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('please-wait',{attrs:{"showMe":_vm.isBusy}}),_vm._v(" "),_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isBusy && !_vm.isError)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neue Fahrzeug-Rädereinlagerung")]:[_vm._v("Fahrzeug-Rädereinlagerung bearbeiten")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"3"}},[(_vm.$hasRole('admin|crm-admin'))?_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Rädereinlagerung\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2):_vm._e()],1)],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Art","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreTypes,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Art "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.tyre_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "tyre_type_id", $$v)},expression:"formData.tyre_type_id"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"km-Stand","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    km-Stand "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.mileage),callback:function ($$v) {_vm.$set(_vm.formData, "mileage", $$v)},expression:"formData.mileage"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Lagerort","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Lagerort","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.location),callback:function ($$v) {_vm.$set(_vm.formData, "location", $$v)},expression:"formData.location"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Einlagerung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Einlagerung*","error-messages":errors,"color":"blue-grey","type":"date","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Einlagerung "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.date_stored),callback:function ($$v) {_vm.$set(_vm.formData, "date_stored", $$v)},expression:"formData.date_stored"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Wechseltermin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Wechseltermin","error-messages":errors,"color":"blue-grey","type":"date","outlined":"","dense":""},model:{value:(_vm.formData.date_changed),callback:function ($$v) {_vm.$set(_vm.formData, "date_changed", $$v)},expression:"formData.date_changed"}})]}}],null,true)}),_vm._v(" "),_c('v-switch',{attrs:{"color":"blue-grey","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                RDKS "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.has_tpms),callback:function ($$v) {_vm.$set(_vm.formData, "has_tpms", $$v)},expression:"formData.has_tpms"}}),_vm._v(" "),_c('v-switch',{attrs:{"color":"blue-grey","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                Radschrauben "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.has_wheel_bolts),callback:function ($$v) {_vm.$set(_vm.formData, "has_wheel_bolts", $$v)},expression:"formData.has_wheel_bolts"}}),_vm._v(" "),(_vm.formData.has_wheel_bolts)?_c('ValidationProvider',{attrs:{"name":"Radschrauben (Beschreibung)","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Radschrauben (Beschreibung)","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.wheel_bolts_description),callback:function ($$v) {_vm.$set(_vm.formData, "wheel_bolts_description", $$v)},expression:"formData.wheel_bolts_description"}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Vorderachse")]),_vm._v(" "),_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Hersteller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreManufacturers,"item-text":"description","item-value":"id","color":"blue-grey","append-outer-icon":"mdi-plus","outlined":"","dense":"","return-object":""},on:{"click:append-outer":_vm.openTyreManufacturerModal},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                            Hersteller "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.frontTyreManufacturer),callback:function ($$v) {_vm.frontTyreManufacturer=$$v},expression:"frontTyreManufacturer"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Modell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.frontTyreModelsFiltered,"item-text":"description","item-value":"id","color":"blue-grey","append-outer-icon":"mdi-plus","outlined":"","dense":""},on:{"click:append-outer":function($event){return _vm.openTyreModelModal('front')}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                            Modell "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_model_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_model_id", $$v)},expression:"formData.front_tyre_model_id"}})]}}],null,true)}),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenbreite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Reifenbreite","error-messages":errors,"items":_vm.tyreWidths,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenbreite "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_width_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_width_id", $$v)},expression:"formData.front_tyre_width_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenhöhe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreHeights,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenhöhe "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_height_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_height_id", $$v)},expression:"formData.front_tyre_height_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenart","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreConstructionTypes,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenart "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_construction_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_construction_type_id", $$v)},expression:"formData.front_tyre_construction_type_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Geschwindigkeitsindex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreSpeedRatings,"item-text":"full_description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Geschwindigkeitsindex "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_speed_rating_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_speed_rating_id", $$v)},expression:"formData.front_tyre_speed_rating_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Lastindex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreLoadIndexes,"item-text":"full_description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Lastindex "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_tyre_load_index_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_tyre_load_index_id", $$v)},expression:"formData.front_tyre_load_index_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Felgenbreite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.rimWidths,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Felgenbreite "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_rim_width_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_rim_width_id", $$v)},expression:"formData.front_rim_width_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Felgengröße","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Felgengröße","error-messages":errors,"items":_vm.rimDiameters,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Felgengröße "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_rim_diameter_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_rim_diameter_id", $$v)},expression:"formData.front_rim_diameter_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Einpresstiefe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Einpresstiefe","error-messages":errors,"items":_vm.rimOffsets,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Einpresstiefe "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.front_rim_offset_id),callback:function ($$v) {_vm.$set(_vm.formData, "front_rim_offset_id", $$v)},expression:"formData.front_rim_offset_id"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifendruck"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifendruck","error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},model:{value:(_vm.formData.pressure_front),callback:function ($$v) {_vm.$set(_vm.formData, "pressure_front", $$v)},expression:"formData.pressure_front"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Anzugsdrehmoment","error-messages":_vm.errors,"items":_vm.torqueTypes,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.torque_front),callback:function ($$v) {_vm.$set(_vm.formData, "torque_front", $$v)},expression:"formData.torque_front"}})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Profiltiefe Vorne Links","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Profiltiefe Vorne Links "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.depth_front_left),callback:function ($$v) {_vm.$set(_vm.formData, "depth_front_left", $$v)},expression:"formData.depth_front_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Profiltiefe Vorne Rechts","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Profiltiefe Vorne Rechts "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.depth_front_right),callback:function ($$v) {_vm.$set(_vm.formData, "depth_front_right", $$v)},expression:"formData.depth_front_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"DOT Vorne Links","rules":"required|numeric|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","placeholder":"KWJJ","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    DOT Vorne Links "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.dot_front_left),callback:function ($$v) {_vm.$set(_vm.formData, "dot_front_left", $$v)},expression:"formData.dot_front_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"DOT Vorne Rechts","rules":"required|numeric|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","placeholder":"KWJJ","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    DOT Vorne Rechts "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.dot_front_right),callback:function ($$v) {_vm.$set(_vm.formData, "dot_front_right", $$v)},expression:"formData.dot_front_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifenschäden Vorne Links","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifenschäden Vorne Links","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_tyre_front_left),callback:function ($$v) {_vm.$set(_vm.formData, "damage_tyre_front_left", $$v)},expression:"formData.damage_tyre_front_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifenschäden Vorne Rechts","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifenschäden Vorne Rechts","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_tyre_front_right),callback:function ($$v) {_vm.$set(_vm.formData, "damage_tyre_front_right", $$v)},expression:"formData.damage_tyre_front_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Felgenschäden Vorne Links","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Felgenschäden Vorne Links","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_rim_front_left),callback:function ($$v) {_vm.$set(_vm.formData, "damage_rim_front_left", $$v)},expression:"formData.damage_rim_front_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Felgenschäden Vorne Rechts","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Felgenschäden Vorne Rechts","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_rim_front_right),callback:function ($$v) {_vm.$set(_vm.formData, "damage_rim_front_right", $$v)},expression:"formData.damage_rim_front_right"}})]}}],null,true)})],1)],1)],1)],1),_vm._v(" "),_c('br'),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-checkbox',{model:{value:(_vm.formData.tyre_rear_is_equal_front),callback:function ($$v) {_vm.$set(_vm.formData, "tyre_rear_is_equal_front", $$v)},expression:"formData.tyre_rear_is_equal_front"}}),_vm._v("\n                                Hinterachse\n                                "),(_vm.formData.tyre_rear_is_equal_front)?[_vm._v("(entspricht Vorderachse)")]:_vm._e()],2),_vm._v(" "),_c('v-card-text',[(!_vm.formData.tyre_rear_is_equal_front)?_c('ValidationProvider',{attrs:{"name":"Hersteller","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreManufacturers,"item-text":"description","item-value":"id","color":"blue-grey","append-outer-icon":"mdi-plus","outlined":"","dense":"","return-object":""},on:{"click:append-outer":_vm.openTyreManufacturerModal},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                            Hersteller "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.rearTyreManufacturer),callback:function ($$v) {_vm.rearTyreManufacturer=$$v},expression:"rearTyreManufacturer"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(!_vm.formData.tyre_rear_is_equal_front)?_c('ValidationProvider',{attrs:{"name":"Modell","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.rearTyreModelsFiltered,"item-text":"description","item-value":"id","color":"blue-grey","append-outer-icon":"mdi-plus","outlined":"","dense":""},on:{"click:append-outer":function($event){return _vm.openTyreModelModal('rear', _vm.formData.rear_tyre_manufacturer_id)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                            Modell "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_model_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_model_id", $$v)},expression:"formData.rear_tyre_model_id"}})]}}],null,true)}):_vm._e(),_vm._v(" "),(!_vm.formData.tyre_rear_is_equal_front)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenbreite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreWidths,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenbreite "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_width_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_width_id", $$v)},expression:"formData.rear_tyre_width_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenhöhe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreHeights,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenhöhe "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_height_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_height_id", $$v)},expression:"formData.rear_tyre_height_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Reifenart","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreConstructionTypes,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Reifenart "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_construction_type_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_construction_type_id", $$v)},expression:"formData.rear_tyre_construction_type_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Geschwindigkeitsindex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreSpeedRatings,"item-text":"full_description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Geschwindigkeitsindex "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_speed_rating_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_speed_rating_id", $$v)},expression:"formData.rear_tyre_speed_rating_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',[_c('ValidationProvider',{attrs:{"name":"Lastindex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.tyreLoadIndexes,"item-text":"full_description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Lastindex "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_tyre_load_index_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_tyre_load_index_id", $$v)},expression:"formData.rear_tyre_load_index_id"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),(!_vm.formData.tyre_rear_is_equal_front)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Felgenbreite","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.rimWidths,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Felgenbreite "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_rim_width_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_rim_width_id", $$v)},expression:"formData.rear_rim_width_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Felgengröße","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.rimDiameters,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Felgengröße "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_rim_diameter_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_rim_diameter_id", $$v)},expression:"formData.rear_rim_diameter_id"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"name":"Einpresstiefe","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.rimOffsets,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Einpresstiefe "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.rear_rim_offset_id),callback:function ($$v) {_vm.$set(_vm.formData, "rear_rim_offset_id", $$v)},expression:"formData.rear_rim_offset_id"}})]}}],null,true)})],1)],1):_vm._e(),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifendruck"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifendruck","error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},model:{value:(_vm.formData.pressure_rear),callback:function ($$v) {_vm.$set(_vm.formData, "pressure_rear", $$v)},expression:"formData.pressure_rear"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"label":"Anzugsdrehmoment","error-messages":_vm.errors,"items":_vm.torqueTypes,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.torque_rear),callback:function ($$v) {_vm.$set(_vm.formData, "torque_rear", $$v)},expression:"formData.torque_rear"}})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Profiltiefe Hinten Links","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Profiltiefe Hinten Links "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.depth_rear_left),callback:function ($$v) {_vm.$set(_vm.formData, "depth_rear_left", $$v)},expression:"formData.depth_rear_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Profiltiefe Hinten Rechts","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","step":".1","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    Profiltiefe Hinten Rechts "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.depth_rear_right),callback:function ($$v) {_vm.$set(_vm.formData, "depth_rear_right", $$v)},expression:"formData.depth_rear_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"DOT Hinten Links","rules":"required|numeric|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"DOT Hinten Links*","error-messages":errors,"color":"blue-grey","type":"number","placeholder":"KWJJ","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    DOT Hinten Links "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.dot_rear_left),callback:function ($$v) {_vm.$set(_vm.formData, "dot_rear_left", $$v)},expression:"formData.dot_rear_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"DOT Hinten Rechts","rules":"required|numeric|length:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","type":"number","placeholder":"KWJJ","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                                    DOT Hinten Rechts "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.dot_rear_right),callback:function ($$v) {_vm.$set(_vm.formData, "dot_rear_right", $$v)},expression:"formData.dot_rear_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifenschäden Hinten Links","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifenschäden Hinten Links","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_tyre_rear_left),callback:function ($$v) {_vm.$set(_vm.formData, "damage_tyre_rear_left", $$v)},expression:"formData.damage_tyre_rear_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Reifenschäden Hinten Rechts","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Reifenschäden Hinten Rechts","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_tyre_rear_right),callback:function ($$v) {_vm.$set(_vm.formData, "damage_tyre_rear_right", $$v)},expression:"formData.damage_tyre_rear_right"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Felgenschäden Hinten Links","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Felgenschäden Hinten Links","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_rim_rear_left),callback:function ($$v) {_vm.$set(_vm.formData, "damage_rim_rear_left", $$v)},expression:"formData.damage_rim_rear_left"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Felgenschäden Hinten Rechts","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Felgenschäden Hinten Rechts","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.damage_rim_rear_right),callback:function ($$v) {_vm.$set(_vm.formData, "damage_rim_rear_right", $$v)},expression:"formData.damage_rim_rear_right"}})]}}],null,true)})],1)],1)],1)],1)],1)],1)],1)]}}],null,false,169265849)})]:_vm._e(),_vm._v(" "),_c('tyre-manufacturer-create',{attrs:{"showModal":_vm.showTyreManufacturerModal},on:{"closeModal":_vm.closeTyreManufacturerModal,"updateManufacturer":_vm.updateTyreManufacturer}}),_vm._v(" "),_c('tyre-model-create',{attrs:{"showModal":_vm.showTyreModelModal,"manufacturerData":_vm.tyreManufacturerModalData},on:{"closeModal":_vm.closeTyreModelModal,"updateModel":_vm.updateTyreModel}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }