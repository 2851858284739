import VueRouter from 'vue-router'
import Vue from 'vue'
import Ls from '@/services/ls'
import { eventBus } from '@/event-bus'
//import NProgress from 'nprogress'
//import 'nprogress/nprogress.css'

window._ = require('lodash')
window.Ls = Ls
window.Vue = require('vue')

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

// axios config
window.axios.defaults.baseURL = process.env.MIX_API_BASE_URL
window.axios.defaults.withCredentials = true

// vue-api-query
import { Model } from 'vue-api-query'

// inject global axios instance as http client to Model
Model.$http = axios

/**
 * Interceptors
 */

window.axios.interceptors.request.use(request => {
    // Do something before request is sent
    const AUTH_TOKEN = Ls.get('auth.token')

    if (AUTH_TOKEN) {
        request.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`
    }

    return request
}, error => {
    // Do something with request error
    return Promise.reject(error)
})

window.axios.interceptors.response.use(response => {
    return response
}, error => {
    //getting error from backend and display it
    eventBus.$emit('Error', {
        message: error.response.data.message
    })

    return Promise.reject(error)
})

// before a request is made start the nprogress
/*
global.axios.interceptors.request.use(config => {
    NProgress.start()
    return config
})

// before a response is returned stop nprogress
global.axios.interceptors.response.use(response => {
    NProgress.done()
    return response
})
*/

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

Vue.use(VueRouter)
