<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitUserData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                Kennwort ändern
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon>
                                Kennwort Zurücksetzen
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Kennwort" rules="required|min:8|max:50" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.password"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="password"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Kennwort <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserPasswordEdit',
        data() {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Benutzerübersicht', disabled: false, to: { name: 'user.index' }, exact: true },
                    { text: 'Benutzer-Details', disabled: false, to: { name: 'user.details', params: {user: this.$route.params.user }}, exact: true },
                    { text: 'Kennwort zurücksetzen', disabled: false, to: {name: 'user.password.reset', params: {user: this.$route.params.user}}, exact: true },
                ],
                formData: {
                    password: null
                }
            }
        },
        methods: {
            async submitUserData() {
                this.isLoading = true

                const userId = this.$route.params.user

                await window.axios.put(`/user/${userId}/password/reset`, this.formData)
                    .then((response) => {
                        this.$router.push({
                            name: 'user.details',
                            params: { user: userId }
                        })
                    })
                    .catch((error) => {
                        this.$refs.form.setErrors(error.response.data.errors)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
