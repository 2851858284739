<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Rechnungen</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           @click="toggleFilter"
                    >
                        Filter <v-icon right small>{{ filterIcon }}</v-icon>
                    </v-btn>
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin|fi-admin')"
                           :to="{ name: 'fi.invoice.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neue Rechnung
                    </v-btn>
                </v-col>
            </v-row>
            <v-card
                v-show="showFilters"
                elevation="0"
            >
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="invoiceNumberFilterValue"
                            append-icon="mdi-feature-search"
                            label="Rechnung-Nr."
                            color="blue-grey"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                            v-model="customerFilterValue"
                            append-icon="mdi-account-search"
                            label="Kunde"
                            color="blue-grey"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="statusFilterValue"
                            label="Status"
                            :items="statusData"
                            item-text="description"
                            item-value="id"
                            color="blue-grey"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-select
                            v-model="paidStatusFilterValue"
                            label="Zahlstatus"
                            :items="paidStatusData"
                            item-text="description"
                            item-value="id"
                            color="blue-grey"
                            outlined
                            dense
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card>
            <v-skeleton-loader
                type="table"
                transition="scale-transition"
                :loading="isLoadingInvoiceData"
            >
                <v-data-table
                    :headers="headers"
                    :items="invoiceData"
                    :items-per-page="10"
                    elevation="0"
                >
                    <template v-slot:body="props">
                        <tbody>
                        <tr v-for="item in props.items">
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                {{ item.invoice_number }}
                            </router-link>
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                {{ item.formattedInvoiceDate }}
                            </router-link>
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                {{ item.formattedDueDate }}
                            </router-link>
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                {{ item.customer.formattedCustomerName }}
                            </router-link>
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                <v-chip :color="getStatusColor(item.status_id)" outlined>{{ item.status.description }}</v-chip>
                            </router-link>
                            <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                <v-chip :color="getStatusColor(item.paid_status_id)" outlined>{{ item.paid_status.description }}</v-chip>
                            </router-link>
                            <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                {{ $utils.formatMoney(item.total) }}
                            </router-link>
                            <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'fi.invoice.details', params: { invoice: item.id } }">
                                <v-icon>mdi-chevron-right</v-icon>
                            </router-link>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'FIInvoiceIndex',
        data () {
            return {
                isLoadingInvoiceData: false,
                isError: false,
                showFilters: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Rechnungen', disabled: false, to: { name: 'fi.invoice.index' }, exact: true }
                ],
                headers: [
                    { text: '#', value: 'invoice_number', filter: this.invoiceNumberFilter },
                    { text: 'Datum', value: 'invoice_date' },
                    { text: 'Fälligkeit', value: 'due_date' },
                    { text: 'Kunde', value: 'customer.formattedCustomerName', filter: this.customerFilter },
                    { text: 'Status', value: 'status_id', filter: this.statusFilter },
                    { text: 'Zahlstatus', value: 'paid_status_id' },
                    { text: 'Betrag', value: 'total', align: 'right' },
                    { }
                ],
                invoiceData: [],
                statusData: [],
                paidStatusData: [],
                invoiceNumberFilterValue: '',
                customerFilterValue: '',
                statusFilterValue: null,
                paidStatusFilterValue: null
            }
        },
        mounted () {
            this.fetchData()
        },
        computed: {
            filterIcon () {
                return (this.showFilters) ? 'mdi-close' : 'mdi-filter'
            }
        },
        methods: {
            async fetchData () {
                this.isLoadingInvoiceData = true

                await window.axios.get('/fi/invoice')
                    .then((response) => {
                        this.invoiceData = response.data.invoiceData
                        this.statusData = response.data.statusData
                        this.paidStatusData = response.data.paidStatusData

                        // add all to select
                        this.statusData.unshift({id: null, description: 'Alle'})
                        this.paidStatusData.unshift({id: null, description: 'Alle'})
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingInvoiceData = false
                    })
            },
            getStatusColor (status) {
                if (status === 1) return 'grey'
                else if (status === 2) return 'blue lighten-2'
                else if (status === 4) return 'light-green'
                else return 'red'
            },
            getPaidStatusColor (status) {
                if (status === 1) return 'grey'
                else if (status === 2) return 'yellow lighten-2'
                else return 'light-green'
            },
            invoiceNumberFilter (value) {
                if (!this.invoiceNumberFilterValue) {
                    return true
                }

                return value.toLowerCase().includes(this.invoiceNumberFilterValue.toLowerCase())
            },
            customerFilter (value) {
                if (!this.customerFilterValue) {
                    return true
                }

                return value.toLowerCase().includes(this.customerFilterValue.toLowerCase())
            },
            statusFilter (value) {
                if (!this.statusFilterValue) {
                    return true
                }

                return value === this.statusFilterValue
            },
            paidStatusFilter (value) {
                if (!this.paidStatusFilterValue) {
                    return true
                }

                return value === this.paidStatusFilterValue
            },
            toggleFilter () {
                this.showFilters = !this.showFilters
            }
        }
    }
</script>

<style scoped>

</style>
