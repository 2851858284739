<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Kunden-Details</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right" v-if="$hasRole('admin|crm-admin')">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="secondary" icon v-on="on" :to="{ name: 'crm.customer.address.create', params: { customer: customerId }}">
                                <v-icon>mdi-home</v-icon>
                            </v-btn>
                        </template>
                        <span>Adresse Hinzufügen</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn color="secondary" icon v-on="on" :to="{ name: 'crm.vehicle.create', params: { customer: customerId }}">
                                <v-icon>mdi-car</v-icon>
                            </v-btn>
                        </template>
                        <span>Fahrzeug Hinzufügen</span>
                    </v-tooltip>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <v-list-item :to="{ name: 'crm.customer.edit', params: { customer: customerData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Bearbeiten</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'crm.customer.address.create', params: { customer: customerData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-home</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Adresse Hinzufügen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'crm.vehicle.create', params: { customer: customerData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-car</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Fahrzeug Hinzufügen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="card"
                transition="scale-transition"
                :loading="isLoadingCustomerData"
            >
                <v-card outlined>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="headline">
                                <template v-if="!isCompany">
                                    {{ customerData.salutation }} {{ customerData.title }} {{ customerData.first_name }} {{ customerData.last_name }}
                                </template>
                                <template v-else>
                                    {{ customerData.company_name }}
                                </template>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="isCompany && customerData.last_name">
                                Ansprechpartner {{ customerData.salutation }} {{ customerData.title }} {{ customerData.first_name }} {{ customerData.last_name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>{{ customerData.type }}</v-list-item-subtitle>
                            <v-list-item-subtitle>Kundennummer {{ customerData.number }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list dense>
                        <v-subheader>Details</v-subheader>
                        <v-list-item v-if="customerData.date_of_birth">
                            <v-list-item-icon>
                                <v-icon color="red">mdi-calendar-today</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customerData.date_of_birth }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="customerData.phone">
                            <v-list-item-icon>
                                <v-icon color="red">mdi-phone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customerData.phone }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="customerData.fax">
                            <v-list-item-icon>
                                <v-icon color="red">mdi-fax</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customerData.fax }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="customerData.mobile">
                            <v-list-item-icon>
                                <v-icon color="red">mdi-cellphone</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customerData.mobile }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="customerData.email">
                            <v-list-item-icon>
                                <v-icon color="red">mdi-email</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{ customerData.email }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">Stra&szlig;e</th>
                                <th class="text-left">PLZ/Ort</th>
                                <th class="text-left">Land</th>
                                <th class="text-right" v-if="$hasRole('admin|crm-admin')">Aktion</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in addressData">
                                <td>{{ item.street }} {{ item.nr }}</td>
                                <td>{{ item.zip }} {{ item.city }}</td>
                                <td>{{ item.country.description }}</td>
                                <td class="text-right" v-if="$hasRole('admin|crm-admin')">
                                    <v-btn
                                        icon
                                        text
                                        exact
                                        :to="{ name: 'crm.customer.address.edit', params: { customer: customerId, address: item.id } }"
                                    >
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th class="text-left">Marke/Modell</th>
                                <th class="text-left">Kennzeichen</th>
                                <th class="text-left">FIN</th>
                                <th class="text-right">HU/AU</th>
                                <th class="text-right">EZ</th>
                                <th class="text-right">Aktion</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in vehicleData">
                                    <td>{{ item.model.brand.description }} {{ item.model.description }}</td>
                                    <td>{{ item.license_plate }}</td>
                                    <td>{{ item.vin }}</td>
                                    <td class="text-right">{{ item.inspection_month }}/{{ item.inspection_year }}</td>
                                    <td class="text-right">{{ item.registration_date }}</td>
                                    <td class="text-right">
                                        <v-btn icon
                                               text
                                               exact
                                               :to="{ name: 'crm.vehicle.details', params: { customer: customerId, vehicle: item.id } }"
                                        >
                                            <v-icon>mdi-folder-open</v-icon>
                                        </v-btn>
                                        <v-btn icon
                                               text
                                               exact
                                               :to="{ name: 'crm.vehicle.edit', params: { customer: customerId, vehicle: item.id } }"
                                               v-if="$hasRole('admin|crm-admin')"
                                        >
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn icon
                                               text
                                               @click="destroyVehicle(index, customerId, item.id)"
                                               v-if="!item.deleted_at && $hasRole('admin|crm-admin')"
                                        >
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMCustomerDetails',
        data () {
            return {
                isLoadingCustomerData: false,
                isLoadingAddressData: false,
                isLoadingVehicleData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: { name: 'crm.customer.details', params: { customer: this.$route.params.customer } }, exact: true }
                ],
                customerId: this.$route.params.customer,
                customerData: Array,
                addressData: Array,
                vehicleData: Array,
                customerMenu: [
                    {
                        title: 'Bearbeiten',
                        link: 'crm.customer.edit'
                    }
                ]
            }
        },
        mounted () {
            this.fetchCustomerData()
            this.fetchAddressData()
            this.fetchVehicleData()
        },
        computed: {
            isCompany () {
                return this.customerData.type_id === 1 ? false : true
            }
        },
        methods: {
            destroyVehicle (index, customerId, vehicleId) {
                if (confirm('Möchten Sie das Fahrzeug wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}`)
                        .then(() => {
                            this.vehicleData.splice(index, 1)
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
            async fetchCustomerData () {
                this.isLoadingCustomerData = true

                await window.axios.get(`/crm/customer/${this.customerId}/details`)
                    .then((response) => {
                        this.customerData = response.data.customer
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCustomerData = false
                    })
            },
            async fetchAddressData () {
                this.isLoadingAddressData = true

                await window.axios.get(`/crm/customer/${this.customerId}/address/get`)
                    .then((response) => {
                        this.addressData = response.data.address
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingAddressData = false
                    })
            },
            async fetchVehicleData () {
                this.isLoadingVehicleData = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/get`)
                    .then((response) => {
                        this.vehicleData = response.data.vehicle
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingVehicleData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
