var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('please-wait',{attrs:{"showMe":_vm.isBusy}}),_vm._v(" "),(!_vm.isBusy)?[_c('v-card',[_c('v-card-title',[_vm._v("\n                Zeiten\n                "),(!_vm.isEdit)?[_vm._v("erfassen")]:[_vm._v("bearbeiten")]],2),_vm._v(" "),_c('v-card-text',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitTimeManagementData($event)}}},[_c('ValidationProvider',{attrs:{"name":"Art","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Art*","items":_vm.types,"item-text":"description","item-value":"id","color":"blue-grey","autofocus":""},on:{"change":_vm.onChangeType},model:{value:(_vm.formData.type_id),callback:function ($$v) {_vm.$set(_vm.formData, "type_id", $$v)},expression:"formData.type_id"}})]}}],null,true)}),_vm._v(" "),(_vm.isWorkingTime)?[_c('v-row',[_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"Datum","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"label":"Datum*","error-messages":errors,"color":"blue-grey","readonly":"","type":"date"},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu_start_date),callback:function ($$v) {_vm.menu_start_date=$$v},expression:"menu_start_date"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"max":_vm.maximumStartDate,"first-day-of-week":"1","color":"blue-grey"},on:{"input":function($event){_vm.menu_start_date = false},"change":function($event){return _vm.onChangeStartDate(1)}},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Uhrzeit (Kommen)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Uhrzeit Kommen*","error-messages":errors,"color":"blue-grey","type":"time"},model:{value:(_vm.formData.start_time),callback:function ($$v) {_vm.$set(_vm.formData, "start_time", $$v)},expression:"formData.start_time"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"md":"4","cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Uhrzeit (Gehen)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Uhrzeit Gehen*","error-messages":errors,"color":"blue-grey","type":"time"},model:{value:(_vm.formData.end_time),callback:function ($$v) {_vm.$set(_vm.formData, "end_time", $$v)},expression:"formData.end_time"}})]}}],null,true)})],1)],1)]:[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"Datum von","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"label":"Datum von*","error-messages":errors,"color":"blue-grey","readonly":"","type":"date"},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu_start_date),callback:function ($$v) {_vm.menu_start_date=$$v},expression:"menu_start_date"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"max":_vm.maximumStartDate,"first-day-of-week":"1","color":"blue-grey"},on:{"input":function($event){_vm.menu_start_date = false},"change":_vm.onChangeStartDate},model:{value:(_vm.formData.start_date),callback:function ($$v) {_vm.$set(_vm.formData, "start_date", $$v)},expression:"formData.start_date"}})],1)],1),_vm._v(" "),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('ValidationProvider',{attrs:{"name":"Datum bis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"label":"Datum bis*","error-messages":errors,"color":"blue-grey","readonly":"","type":"date"},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu_end_date),callback:function ($$v) {_vm.menu_end_date=$$v},expression:"menu_end_date"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"min":_vm.minimumEndDate,"first-day-of-week":"1","color":"blue-grey"},on:{"input":function($event){_vm.menu_end_date = false}},model:{value:(_vm.formData.end_date),callback:function ($$v) {_vm.$set(_vm.formData, "end_date", $$v)},expression:"formData.end_date"}})],1)],1)],1),_vm._v(" "),(_vm.isVacationTime)?[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"error-messages":errors,"color":"blue-grey"},model:{value:(_vm.remainingVacationDays),callback:function ($$v) {_vm.remainingVacationDays=$$v},expression:"remainingVacationDays"}})]}}],null,true)}),_vm._v(" "),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v("\n                                    Urlaub dieses Jahr gesamt: "),_c('strong',[_vm._v(_vm._s(_vm.vacationDaysTotal))])]),_vm._v(" "),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v("\n                                    Urlaub dieses Jahr geplant: "),_c('strong',[_vm._v(_vm._s(_vm.vacationDaysUsed))])]),_vm._v(" "),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v("\n                                    Urlaub noch planbar:\n                                    "),_c('strong',[(_vm.remainingVacationDays > 0)?_c('span',{staticClass:"green--text"},[_vm._v("\n                                            "+_vm._s(_vm.remainingVacationDays)+"\n                                        ")]):_c('span',{staticClass:"red--text"},[_vm._v("\n                                            "+_vm._s(_vm.remainingVacationDays)+"\n                                        ")])])])]:_vm._e()],_vm._v(" "),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2)],1)],2)]}}],null,false,2824730680)})],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }