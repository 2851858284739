<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitAddressData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Kunden-Adresse</template>
                                <template v-else>Kunden-Adresse Bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Adresse
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-skeleton-loader
                        type="card"
                        transition="scale-transition"
                        :loading="isLoadingAddressData"
                    >
                        <v-card outlined>
                            <v-card-text>
                                <v-row dense>
                                    <v-col md="9" cols="12">
                                        <ValidationProvider name="Straße" rules="required|min:5|max:80" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.street"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                autofocus
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Straße <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="3" cols="12">
                                        <ValidationProvider name="Hausnummer" rules="required|max:10" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.nr"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Hausnummer <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col md="6" cols="12">
                                        <ValidationProvider name="PLZ" rules="required|min:4|max:10" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.zip"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    PLZ <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="6" cols="12">
                                        <ValidationProvider name="Stadt" rules="required|min:3|max:50" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.city"
                                                label="Stadt*"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Stadt <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <ValidationProvider name="Land" rules="required" v-slot="{ errors }">
                                    <v-select
                                        v-model="formData.country_id"
                                        :error-messages="errors"
                                        :items="countryData"
                                        item-text="description"
                                        item-value="id"
                                        :loading="isLoadingCountryData"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    >
                                        <template #label>
                                            Land <span class="red--text"><strong>* </strong></span>
                                        </template>
                                    </v-select>
                                </ValidationProvider>
                            </v-card-text>
                        </v-card>
                    </v-skeleton-loader>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    //import { mapActions } from 'vuex'

    export default {
        name: 'CRMCustomerAddressCreateEdit',
        data() {
            return {
                isLoadingAddressData: false,
                isLoadingCountryData: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true}
                ],
                countryData: [],
                formData: {
                    customer_id: this.$route.params.customer,
                    street: null,
                    nr: null,
                    zip: null,
                    city: null,
                    country_id: null
                }
            }
        },
        mounted () {
            this.fetchCountryData()

            if (this.isEdit) {
                this.fetchAddressData()
            }
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.customer.address.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Kunden-Adresse bearbeiten', disabled: false, to: {name: 'crm.customer.address.edit', params: {customer: this.$route.params.customer, address: this.$route.params.address}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Kunden-Adresse', disabled: false, to: {name: 'crm.customer.address.create'}, exact: true}
                    )

                    return false
                }
            },
        },
        methods: {
            async fetchCountryData () {
                this.isLoadingCountryData = true

                await window.axios.get('/system/countries')
                    .then((response) => {
                        this.countryData = response.data.countries
                        this.formData.country_id = response.data.default.id
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCountryData = false
                    })
            },
            async fetchAddressData () {
                this.isLoadingAddressData = true

                let customerId = this.$route.params.customer
                let addressId = this.$route.params.address

                await window.axios.get(`crm/customer/${customerId}/address/${addressId}/edit`)
                    .then((response) => {
                        this.formData = response.data.address
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingAddressData = false
                    })
            },
            async submitAddressData() {
                this.isLoading = true

                let customerId = this.$route.params.customer

                if (this.isEdit) {
                    let addressId = this.$route.params.address

                    await window.axios.put(`/crm/customer/${customerId}/address/${addressId}/update`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'crm.customer.details',
                                    params: {customer: customerId}
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
                else {
                    await window.axios.post(`/crm/customer/${customerId}/address/create`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'crm.customer.details',
                                    params: {customer: customerId}
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            }
        }
    }
</script>

<style scoped>

</style>
