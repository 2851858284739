<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <v-row v-if="isUser">
                <v-col cols="12" md="4">
                    <v-card height="100%">
                        <v-card-title>Schnellfunktionen</v-card-title>
                        <v-card-text class="text--primary">
                            <div class="my-2" v-if="workingTimesToday == null">
                                <v-btn
                                    @click="startBookTime($store.state.user.currentUser['id'], 1)"
                                    :loading="isLoading"
                                >
                                    Arbeitsbeginn
                                </v-btn>
                            </div>
                            <div class="my-2" v-if="workingTimesToday !== null && workingTimesToday.end == null">
                                <v-btn
                                    @click="endBookTime($store.state.user.currentUser['id'], workingTimesToday.id)"
                                    :loading="isLoading"
                                >
                                    Arbeitsende
                                </v-btn>
                            </div>
                            <div class="my-2"
                                 v-if="openBreaksToday == null &&
                                        workingTimesToday !== null &&
                                        workingTimesToday.end == null"
                            >
                                <v-btn
                                    @click="startBookTime($store.state.user.currentUser['id'], 2)"
                                    :loading="isLoading"
                                >
                                    Pausenbeginn
                                </v-btn>
                            </div>
                            <div class="my-2" v-if="openBreaksToday !== null && openBreaksToday.end == null">
                                <v-btn
                                    @click="endBookTime($store.state.user.currentUser['id'], openBreaksToday.id)"
                                    :loading="isLoading"
                                >
                                    Pausenende
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%">
                        <v-card-title>Statistik</v-card-title>
                        <v-card-subtitle class="pb-0">Jahr</v-card-subtitle>
                        <v-card-text class="text--primary">
                            <p class="ma-0 pa-0">
                                Urlaub dieses Jahr gesamt: <strong>{{ statisticsItems.vacationDaysTotal }}</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Urlaub dieses Jahr geplant: <strong>{{ statisticsItems.vacationDaysUsed }}</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Urlaub noch planbar: <strong>{{ statisticsItems.vacationDaysAvailable }}</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Krankheitstage dieses Jahr: <strong>{{ statisticsItems.sickDaysTotal }}</strong>
                            </p>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pt-0 pb-0">Monat</v-card-subtitle>
                        <v-card-text class="text--primary">
                            <p class="ma-0 pa-0">
                                Soll-Arbeitzeit: <strong>{{ statisticsItems.workingHoursTarget }}h</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Ist-Arbeitszeit: <strong>{{ statisticsItems.workingHoursTotal }}h</strong>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%">
                        <v-card-title>heutige Historie</v-card-title>
                        <v-card-text class="text--primary">
                            <p class="ma-0 pa-0" v-if="workingTimesToday && workingTimesToday.start">
                                Arbeitsbeginn: {{ workingTimesToday.start }}
                            </p>
                            <p class="ma-0 pa-0" v-else>
                                noch kein Arbeitsbeginn gebucht
                            </p>
                            <p class="ma-0 pa-0 pb-2" v-if="workingTimesToday && workingTimesToday.end">
                                Arbeitsende: {{ workingTimesToday.end }}
                            </p>
                            <p class="ma-0 pa-0 pb-2" v-else>
                                noch kein Arbeitsende gebucht
                            </p>
                            <p class="ma-0 pa-0" v-for="item in closedBreaksToday">
                                Pause von {{ item.start }} bis {{ item.end }}
                            </p>
                            <p class="ma-0 pa-0 pt-2" v-if="openBreaksToday">
                                aktuelle Pause von {{ openBreaksToday.start }}
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <br>
            <v-card>
                <v-card-title>
                    Zeiterfassungsübersicht
                    <v-spacer></v-spacer>
                    <v-btn :to="{ name: 'user.timemanagement-statistics.index' }" v-if="isUser" class="mr-4">
                        Statistik
                    </v-btn>
                    <v-btn :to="{ name: 'user.timemanagement.create' }" v-if="isUser">
                        Zeiten erfassen
                    </v-btn>
                </v-card-title>
                <v-sheet height="64">
                    <v-toolbar flat color="white">
                        <v-menu bottom right v-if="$hasRole('admin|time-management-admin')">
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    outlined
                                    class="mr-4"
                                    color="grey darken-2"
                                    v-on="on"
                                >
                                    <span>{{ labelUser }}</span>
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item-group v-model="usersSelected" color="grey darken-2">
                                    <v-list-item
                                        v-for="user in users"
                                        :key="user.id"
                                        @click="fetchTimeManagementData(user.id)"
                                    >
                                        <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu>
                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                            Heute
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="prev">
                            <v-icon small>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn fab text small color="grey darken-2" @click="next">
                            <v-icon small>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-toolbar-title>{{ title }}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-menu bottom right>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    outlined
                                    color="grey darken-2"
                                    v-on="on"
                                >
                                    <span>{{ typeToLabel[type] }}</span>
                                    <v-icon right>mdi-menu-down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="type = 'day'">
                                    <v-list-item-title>Tag</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'week'">
                                    <v-list-item-title>Woche</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="type = 'month'">
                                    <v-list-item-title>Monat</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-toolbar>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar
                        ref="calendar"
                        v-model="focus"
                        color="primary"
                        :events="calendarItems"
                        :event-color="getEventColor"
                        :now="today"
                        :type="type"
                        @click:more="viewDay"
                        @click:date="viewDay"
                        @change="updateRange"
                        :short-months="shortMonths"
                        :short-weekdays="shortWeekdays"
                        :weekdays="weekday"
                        event-overlap-mode="stack"
                    ></v-calendar>
                </v-sheet>
            </v-card>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserTimeManagementIndex',
        data: () => ({
            isBusy: false,
            isError: false,
            isLoading: false,
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Monat',
                week: 'Woche',
                day: 'Tag'
            },
            start: null,
            end: null,
            today: null,
            users: [],
            usersSelected: 0,
            calendarItems: [],
            workingTimesToday: [],
            closedBreaksToday: [],
            openBreaksToday: [],
            statisticsItems: [],
            shortMonths: false,
            shortWeekdays: false,
            weekday: [1, 2, 3, 4, 5, 6, 0]
        }),
        computed: {
            isUser () {
                return this.$hasRole('time-management-user')
            },
            labelUser () {
                return this.users[this.usersSelected].full_name
            },
            title () {
                const { start, end } = this
                if (!start || !end) {
                    return ''
                }

                const startMonth = this.monthFormatter(start)
                const endMonth = this.monthFormatter(end)
                const suffixMonth = startMonth === endMonth ? '' : endMonth

                const startYear = start.year
                const endYear = end.year
                const suffixYear = startYear === endYear ? '' : endYear

                const startDay = start.day + '.'
                const endDay = end.day + '.'

                switch (this.type) {
                    case 'month':
                        return `${startMonth} ${startYear}`
                    case 'week':
                        return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`
                    case 'day':
                        return `${startDay} ${startMonth} ${startYear}`
                }
                return ''
            },
            monthFormatter () {
                return this.$refs.calendar.getFormatter({
                    timeZone: 'UTC', month: 'long',
                })
            },
        },
        created () {
            this.fetchData()
        },
        /*
        mounted () {
            this.$refs.calendar.checkChange()
        },
        */
        methods: {
            async fetchData () {
                this.isBusy = true

                let responseUsers = await window.axios.get('/user/timemanagement')

                if (responseUsers.status === 200) {
                    this.users = responseUsers.data.users

                    await this.fetchTimeManagementData(this.users[0].id)
                        .then((response) => {
                            this.isBusy = false
                        })
                        .catch(() => {
                            this.isBusy = false
                            this.isError = true
                        })
                }
                else {
                    this.isBusy = false
                    this.isError = true
                }
            },
            fetchTimeManagementData (userId) {
                return window.axios.get(`/user/timemanagement/${userId}/details`)
                    .then((response) => {
                        this.calendarItems = response.data.calendarItems
                        this.workingTimesToday = response.data.workingTimesToday
                        this.closedBreaksToday = response.data.closedBreaksToday
                        this.openBreaksToday = response.data.openBreaksToday
                        this.statisticsItems = response.data.statisticsItems
                    })
            },
            async startBookTime (userId, typeId) {
                this.isLoading = true

                await window.axios.post('/user/timemanagement/startBookTime', { type_id: typeId })
                    .then((response) => {
                        if (response.data.success)
                            this.fetchTimeManagementData(userId)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            async endBookTime (userId, bookTimeId) {
                this.isLoading = true

                await window.axios.put('/user/timemanagement/endBookTime', { id: bookTimeId })
                    .then((response) => {
                        if (response.data.success)
                            this.fetchTimeManagementData(userId)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            viewDay ({ date }) {
                this.focus = date
                this.type = 'day'
            },
            getEventColor (event) {
                return event.color
            },
            setToday () {
                this.focus = this.today
            },
            prev () {
                this.$refs.calendar.prev()
            },
            next () {
                this.$refs.calendar.next()
            },
            /*
            showEvent ({ nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event
                    this.selectedElement = nativeEvent.target
                    setTimeout(() => this.selectedOpen = true, 10)
                }

                if (this.selectedOpen) {
                    this.selectedOpen = false
                    setTimeout(open, 10)
                } else {
                    open()
                }

                nativeEvent.stopPropagation()
            },
            */
            updateRange ({ start, end }) {
                /*const events = []

                const min = new Date(`${start.date}T00:00:00`)
                const max = new Date(`${end.date}T23:59:59`)
                const days = (max.getTime() - min.getTime()) / 86400000
                const eventCount = this.rnd(days, days + 20)

                for (let i = 0; i < eventCount; i++) {
                    const allDay = this.rnd(0, 3) === 0
                    const firstTimestamp = this.rnd(min.getTime(), max.getTime())
                    const first = new Date(firstTimestamp - (firstTimestamp % 900000))
                    const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
                    const second = new Date(first.getTime() + secondTimestamp)

                    events.push({
                        name: this.names[this.rnd(0, this.names.length - 1)],
                        start: this.formatDate(first, !allDay),
                        end: this.formatDate(second, !allDay),
                        color: this.colors[this.rnd(0, this.colors.length - 1)],
                    })
                }*/

                this.start = start
                this.end = end
                //this.events = events
            }/*,
            nth (d) {
                return d > 3 && d < 21
                    ? 'th'
                    : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            },
            formatDate (a, withTime) {
                return withTime
                    ? `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()} ${a.getHours()}:${a.getMinutes()}`
                    : `${a.getFullYear()}-${a.getMonth() + 1}-${a.getDate()}`
            }*/
        }
    }
</script>

<style scoped>

</style>
