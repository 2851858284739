<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Dashboard</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
            </v-row>
            <v-card outlined>
                <v-card-title>
                    Anstehende Geburtstage
                </v-card-title>
                <v-skeleton-loader
                    type="table"
                    transition="scale-transition"
                    :loading="isLoadingBirthdayData"
                >
                    <v-data-table
                        :headers="birthdayHeaders"
                        :items="birthdayData"
                        class="elevation-1"
                        disable-sort
                        disable-filtering
                        hide-default-footer
                    >
                        <template v-slot:body="props">
                            <tbody>
                            <tr v-for="item in props.items">
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.number }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.full_name }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.date_diff }} ({{ item.date_of_birth }})
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </router-link>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-skeleton-loader>
            </v-card>
            <br>
            <v-card outlined>
                <v-card-title>
                    Anstehende Hauptuntersuchungen
                </v-card-title>
                <v-skeleton-loader
                    type="table"
                    transition="scale-transition"
                    :loading="isLoadingInspectionData"
                >
                    <v-data-table
                        :headers="inspectionHeaders"
                        :items="inspectionData"
                        class="elevation-1"
                        disable-sort
                        disable-filtering
                        hide-default-footer
                    >
                        <template v-slot:body="props">
                            <tbody>
                            <tr v-for="item in props.items">
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.customer_id } }">
                                    {{ item.vehicle }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.customer_id } }">
                                    {{ item.license_plate }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.customer_id } }">
                                    {{ item.vin }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.customer_id } }">
                                    {{ item.inspection }}
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.customer_id } }">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </router-link>
                            </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                </v-skeleton-loader>
            </v-card>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'Index',
        data () {
            return {
                isLoadingBirthdayData: false,
                isLoadingInspectionData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true }
                ],
                birthdayData: [],
                inspectionData: [],
                birthdayHeaders: [
                    { text: 'Kunden-Nr.', value: 'number' },
                    { text: 'Name', value: 'full_name' },
                    { text: 'Geburtstag in Tagen', value: 'date_diff' },
                    { }
                ],
                inspectionHeaders: [
                    { text: 'Fahrzeug', value: 'vehicle' },
                    { text: 'Kennzeichen', value: 'license_plate' },
                    { text: 'FIN', value: 'vin' },
                    { text: 'HU/AU', value: 'inspection' },
                    { }
                ]
            }
        },
        mounted () {
            this.fetchBirthdayData()
            this.fetchInspectionData()
        },
        methods: {
            async fetchBirthdayData () {
                this.isLoadingBirthdayData = true

                await window.axios.get('/dashboard/birthdays')
                    .then((response) => {
                        this.birthdayData = window._.orderBy(response.data.customers, 'date_diff', 'asc')
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingBirthdayData = false
                    })
            },
            async fetchInspectionData () {
                this.isLoadingInspectionData = true

                await window.axios.get('/dashboard/inspections')
                    .then((response) => {
                        this.inspectionData = window._.orderBy(response.data.vehicles, 'sort', 'asc')
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingInspectionData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
