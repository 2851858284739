import Vue from 'vue'
import { ValidationObserver, ValidationProvider, extend, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/de.json'
import * as moment from 'moment'

// https://logaretm.github.io/vee-validate/guide/interaction-and-ux.html#interaction-modes
//setInteractionMode('lazy')

extend('decimal', {
    validate: (value, { decimals = '*', separator = ',' } = {}) => {
        if (value === null || value === undefined || value === '') {
            return {
                valid: false
            };
        }
        if (Number(decimals) === 0) {
            return {
                valid: /^-?\d*$/.test(value),
            };
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
        const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);
        return {
            valid: regex.test(value),
            data: {
                serverMessage: 'Only decimal values are available'
            }
        };
    },
    message: `{serverMessage}`
})

extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});

const dateFormat = 'DD.MM.YYYY'

extend('before', {
    params: ['limit', 'included'],
    validate: (value, { limit, included }) => {
        limit = moment(limit, dateFormat);
        value = moment(value, dateFormat);
        return included
            ? value.isSameOrBefore(limit)
            : value.isBefore(limit);
    },
    message: (fieldName, placeholders) => {
        let limit = moment(placeholders.limit).format(dateFormat)
        return `The ${fieldName} field must come before ${limit}`
    }
})

// import all rules
Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    })
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
