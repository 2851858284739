<template>
    <v-row justify="center">
        <v-dialog v-model="showModal" persistent max-width="600px">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitData">
                    <v-card>
                        <v-card-title>
                            <span class="headline">Reifenhersteller hinzufügen</span>
                            <v-spacer></v-spacer>
                            <v-btn icon
                                   @click="closeModal"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <ValidationProvider vid="description" name="Bezeichnung" rules="required|max:255" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.description"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    counter="255"
                                    outlined
                                    dense
                                    autofocus
                                >
                                    <template #label>
                                        Bezeichnung <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed
                                   color="secondary"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </ValidationObserver>
        </v-dialog>
    </v-row>
</template>

<script>
    export default {
        name: 'CRMVehicleTyreManufacturerCreate',
        props: {
            showModal: {
                type: Boolean,
                default: false
            }
        },
        data () {
            return {
                isLoading: false,
                formData: {
                    description: null
                }
            }
        },
        methods: {
            closeModal () {
                // reset input
                Vue.set(this.formData, 'description', null)
                this.$refs.form.reset()

                this.$emit('closeModal', this.showModal)
            },
            async submitData () {
                this.isLoading = true

                await window.axios.post('/vehicle/tyre/manufacturer/create', this.formData)
                    .then((response) => {
                        this.$emit('updateManufacturer', {
                            id: response.data.manufacturerId,
                            description: this.formData.description
                        })

                        // reset input
                        Vue.set(this.formData, 'description', null)
                        this.$refs.form.reset()
                    })
                    .catch((error) => {
                        this.$refs.form.setErrors(error.response.data.errors)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
