<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Rechnung</template>
                                <template v-else>Rechnung Nr. {{ formData.invoice_number }} Bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|fi-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Rechnung
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-skeleton-loader
                        type="card"
                        transition="scale-transition"
                        :loading="isLoadingInvoiceData"
                    >
                        <v-card outlined>
                            <v-card-text class="pb-0 mb-0">
                                <ValidationProvider vid="invoice_number" name="Rechnungsnummer" rules="required" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.invoice_number"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                        autofocus
                                    >
                                        <template #label>
                                            Rechnungsnummer <span class="red--text"><strong>* </strong></span>
                                        </template>
                                    </v-text-field>
                                </ValidationProvider>
                                <v-row dense>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="formData.invoice_date"
                                            color="blue-grey"
                                            type="date"
                                            outlined
                                            dense
                                        >
                                            <template #label>
                                                Datum <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <v-text-field
                                            v-model="formData.due_date"
                                            color="blue-grey"
                                            type="date"
                                            outlined
                                            dense
                                        >
                                            <template #label>
                                                Fälligkeit <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <ValidationProvider name="Leistungsdatum von" vid="date_start" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.date_start"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                type="date"
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Leistungsdatum von <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <ValidationProvider name="Leistungsdatum bis" vid="date_end" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.date_end"
                                                :error-messages="errors"
                                                label="Leistungsdatum bis"
                                                color="blue-grey"
                                                type="date"
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" md="6">
                                        <ValidationProvider name="Zahlungsweise" rules="required" v-slot="{ errors }">
                                            <v-select
                                                v-model="formData.payment_type_id"
                                                :error-messages="errors"
                                                :items="payment_types"
                                                item-text="description"
                                                item-value="id"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Zahlungsweise <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-switch
                                            v-model="formData.price_package"
                                            label="Paketpreis"
                                            color="blue-grey"
                                            dense
                                            inset
                                            class="ma-1 pa-1"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-switch
                                            v-model="formData.compact_view"
                                            label="Kompaktansicht"
                                            color="blue-grey"
                                            dense
                                            inset
                                            class="ma-1 pa-1"
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="12" md="2">
                                        <v-switch
                                            v-model="formData.note_paper"
                                            label="Briefpapier"
                                            color="blue-grey"
                                            dense
                                            inset
                                            class="ma-1 pa-1"
                                        ></v-switch>
                                    </v-col>
                                </v-row>
                                <v-row dense>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider name="Kunde" rules="required" v-slot="{ errors }">
                                            <v-autocomplete
                                                v-model="selectedCustomer"
                                                :error-messages="errors"
                                                :items="customerItems"
                                                item-text="formattedCustomerName"
                                                item-value="id"
                                                :search-input.sync="searchCustomer"
                                                cache-items
                                                hide-no-data
                                                hide-details
                                                hide-selected
                                                placeholder="Tippe, um die Suche zu beginnen"
                                                color="blue-grey"
                                                :loading="isLoadingCustomerData"
                                                clearable
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Kunde <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <ValidationProvider name="Adresse" rules="required" v-slot="{ errors }">
                                            <v-select
                                                v-model="formData.address_id"
                                                :error-messages="errors"
                                                :items="addresses"
                                                item-text="formattedAddress"
                                                item-value="id"
                                                color="blue-grey"
                                                :disabled="addressIsDisabled"
                                                :loading="isLoadingCustomerAddressData"
                                                clearable
                                                outlined
                                                dense
                                            >
                                                <template #label>
                                                    Adresse <span class="red--text"><strong>* </strong></span>
                                                </template>
                                            </v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select
                                            v-model="formData.vehicle_id"
                                            label="Fahrzeug"
                                            :items="vehicles"
                                            item-text="formattedVehicleName"
                                            item-value="id"
                                            color="blue-grey"
                                            :disabled="addressIsDisabled"
                                            :loading="isLoadingCustomerVehicleData"
                                            clearable
                                            outlined
                                            dense
                                        >
                                        </v-select>
                                    </v-col>
                                </v-row>
                                <!--
                                <ValidationProvider name="Hinweise" rules="max:1000" v-slot="{ errors }">
                                    <v-textarea
                                        v-model="formData.notes"
                                        :error-messages="errors"
                                        label="Hinweise"
                                        color="blue-grey"
                                        counter="1000"
                                        outlined
                                        dense
                                    ></v-textarea>
                                </ValidationProvider>
                                -->
                            </v-card-text>
                            <v-card-actions class="pt-0 mt-0">
                                <v-spacer></v-spacer>
                                <v-btn depressed
                                       color="secondary"
                                       @click="addItem"
                                       :disabled="!isAddItemAllowed"
                                >
                                    <v-icon left small>mdi-plus</v-icon> Artikel hinzufügen
                                </v-btn>
                            </v-card-actions>
                            <v-card-text>
                                <invoice-item
                                    v-for="(item, index) in invoiceItems"
                                    :key="item.id"
                                    :index="index"
                                    :item-data="item"
                                    :item-data-length="invoiceItems.length"
                                    @remove="removeItem"
                                />
                                <v-row dense>
                                    <v-col cols="12" offset-md="7" md="5">
                                        <v-row dense>
                                            <v-col cols="4" class="text-uppercase">
                                                <p>Nettobetrag</p>
                                                <p>
                                                    inkl. MwSt.
                                                    <v-select
                                                        v-model="tax"
                                                        :items="taxes"
                                                        item-text="percent"
                                                        item-value="id"
                                                        color="blue-grey"
                                                        outlined
                                                        dense
                                                        return-object
                                                    >
                                                    </v-select>
                                                </p>
                                                <v-divider></v-divider>
                                                <p>Gesamtbetrag</p>
                                            </v-col>
                                            <v-col cols="8" class="text-right text-uppercase">
                                                <p>{{ $utils.formatMoney(sub_total) }}</p>
                                                <p>{{ $utils.formatMoney(totalTax) }}</p>
                                                <v-divider></v-divider>
                                                <p>{{ $utils.formatMoney(total) }}</p>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-skeleton-loader>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    import moment from 'moment'
    import InvoiceItem from './Item'

    export default {
        name: 'FIInvoiceCreateEdit',
        components: {
            InvoiceItem
        },
        data () {
            return {
                isLoading: false,
                isLoadingCustomerData: false,
                isLoadingInvoiceData: false,
                isLoadingCustomerAddressData: false,
                isLoadingCustomerVehicleData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Rechnungen', disabled: false, to: { name: 'fi.invoice.index' }, exact: true },
                ],
                formData: {
                    customer_id: null,
                    address_id: null,
                    vehicle_id: null,
                    invoice_date: null,
                    due_date: null,
                    invoice_number: null,
                    status_id: null,
                    paid_status_id: null,
                    payment_type_id: null,
                    date_start: null,
                    date_end: null,
                    notes: null,
                    sub_total: null,
                    total: null,
                    tax: null,
                    tax_id: null,
                    price_package: true,
                    compact_view: false,
                    note_paper: false,
                    template_id: null,
                    items: [],
                },
                invoiceItems: [],
                payment_types: [],
                taxes: [],
                customers: [],
                addresses: [],
                vehicles: [],
                addressIsDisabled: true,
                taxInPercent: null,
                // customer autocomplete
                customerItems: [],
                selectedCustomer: null,
                searchCustomer: null
            }
        },
        mounted () {
            this.fetchInvoiceData()
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'fi.invoice.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Rechnung bearbeiten', disabled: false, to: {name: 'fi.invoice.edit', params: {invoice: this.$route.params.invoice}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Rechnung', disabled: false, to: {name: 'fi.invoice.create'}, exact: true}
                    )

                    return false
                }
            },
            isAddItemAllowed () {
                let checkEmptyDescription = this.invoiceItems
                    .filter(line => (line.item_id === null))

                if (checkEmptyDescription.length >= 1 && this.invoiceItems.length > 0) {
                    return false
                }

                return true
            },
            tax: {
                get: function () {
                    return this.formData.tax_id
                    // TODO
                    //return 19//this.taxes.find(x => x.id === this.formData.tax_id).percent
                },
                set: function (value) {
                    //console.log(newValue)
                    this.formData.tax_id = value.id
                    //this.taxInPercent = this.taxes.find(x => x.id === value).percent
                    this.taxInPercent = value.percent
                }
            },
            sub_total () {
                return this.invoiceItems.reduce(function (a, b) {
                    return parseInt(a + b['total'])
                }, 0)
            },
            total () {
                return parseInt(this.sub_total + this.totalTax)
            },
            totalTax () {
                return parseInt((this.sub_total / 100) * this.taxInPercent)
            }
        },
        watch: {
            searchCustomer (value) {
                value && value !== this.selectedCustomer && this.queryCustomers(value)
            },
            selectedCustomer (value) {
                if (!value) {
                    this.formData.customer_id = null

                    this.addresses = null
                    this.addressIsDisabled = true
                }
                else {
                    this.formData.customer_id = value

                    this.fetchCustomerAddressData(value)
                    this.fetchVehicleData(value)
                    this.addressIsDisabled = false
                }
            }
        },
        methods: {
            queryCustomers (v) {
                this.isLoadingCustomerData = true

                window.axios.get(`/customers?filter[name]=${v}`)
                    .then((response) => {
                        this.customerItems = response.data
                    })
                    .finally(() => {
                        this.isLoadingCustomerData = false
                    })
            },
            addItem () {
                if (this.isAddItemAllowed === true || this.invoiceItems.length === 0) {
                    this.invoiceItems.push({
                        id: null,
                        description: null,
                        item_id: null,
                        name: null,
                        quantity: null,
                        price: null,
                        total: null
                    })
                }
            },
            removeItem (index) {
                if (this.invoiceItems.length > 1)
                    this.invoiceItems.splice(index, 1)
            },
            async submitData () {
                // TODO: dynamisch machen
                this.formData.status_id = 1
                this.formData.paid_status_id = 1
                this.formData.template_id = 1
                // TODO: Ende

                this.isLoading = true

                Vue.set(this.formData, 'sub_total', this.sub_total)
                Vue.set(this.formData, 'tax', this.totalTax)
                Vue.set(this.formData, 'total', this.total)
                Vue.set(this.formData, 'items', this.invoiceItems)

                if (this.isEdit) {
                    let invoiceId = this.$route.params.invoice

                    await window.axios.put(`/fi/invoice/${invoiceId}/update`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'fi.invoice.details',
                                    params: {
                                        invoice: invoiceId
                                    }
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
                else {
                    await window.axios.post('/fi/invoice/create', this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'fi.invoice.details',
                                    params: {
                                        invoice: response.data.invoiceId
                                    }
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            async fetchInvoiceData () {
                this.isLoadingInvoiceData = true

                if (this.isEdit) {
                    let invoiceId = this.$route.params.invoice

                    await window.axios.get(`/fi/invoice/${invoiceId}/edit`)
                        .then((response) => {
                            this.formData = response.data.invoice
                            this.invoiceItems = response.data.invoice_items
                            this.payment_types = response.data.payment_types
                            this.taxes = response.data.taxes

                            // customer autocomplete
                            Vue.set(this, 'customerItems', response.data.customer)
                            Vue.set(this, 'selectedCustomer', response.data.invoice.customer_id)
                            Vue.set(this, 'taxInPercent', response.data.taxInPercent)
                        })
                        .catch(() => {
                            this.isError = true
                        })
                        .finally(() => {
                            this.isLoadingInvoiceData = false
                        })
                }
                else {
                    await window.axios.get('/fi/invoice/create')
                        .then((response) => {
                            Vue.set(this.formData, 'invoice_number', response.data.nextInvoiceNumber)
                            this.payment_types = response.data.payment_types
                            this.customers = response.data.customers
                            this.taxes = response.data.taxes
                        })
                        .catch(() => {
                            this.isError = true
                        })
                        .finally(() => {
                            this.isLoadingInvoiceData = false
                        })

                    // set initial data
                    Vue.set(this.formData, 'invoice_date', moment().format('YYYY-MM-DD'))
                    Vue.set(this.formData, 'due_date', moment().add(14, 'days').format('YYYY-MM-DD'))
                    Vue.set(this.formData, 'date_start', moment().format('YYYY-MM-DD'))
                    Vue.set(this.formData, 'date_end', moment().format('YYYY-MM-DD'))
                    Vue.set(this.formData, 'payment_type_id', 2)
                    Vue.set(this.formData, 'tax_id', 3)
                    Vue.set(this, 'taxInPercent', 19)

                    // add first article item
                    this.addItem()
                }
            },
            async fetchCustomerAddressData (customerId) {
                this.isLoadingCustomerAddressData = true

                await window.axios.get(`/fi/invoice/create/address?c=${customerId}`)
                    .then((response) => {
                        this.addresses = response.data.address

                        if (this.addresses.length === 1)
                            Vue.set(this.formData, 'address_id', this.addresses[0].id)
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCustomerAddressData = false
                    })
            },
            async fetchVehicleData (customerId) {
                this.isLoadingCustomerVehicleData = true

                await window.axios.get(`/fi/invoice/create/vehicle?c=${customerId}`)
                    .then((response) => {
                        this.vehicles = response.data.vehicle

                        if (this.vehicles.length === 1 && this.isEdit === false)
                            Vue.set(this.formData, 'vehicle_id', this.vehicles[0].id)
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCustomerVehicleData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
