<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">
                        Benutzer-Details
                    </div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <v-list-item :to="{ name: 'user.edit', params: { user: userData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Bearbeiten</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item :to="{ name: 'user.password.edit', params: { user: userData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Kennwort ändern</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="$isAdmin" :to="{ name: 'user.password.reset', params: { user: userData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Kennwort zurücksetzen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-card outlined>
                <v-card-text>
                    <v-text-field
                        v-model="userData.username"
                        label="Benutzername"
                        color="blue-grey"
                        disabled
                        outlined
                        dense
                    ></v-text-field>
                    <v-text-field
                        v-model="userData.first_name"
                        label="Vorname"
                        color="blue-grey"
                        disabled
                        outlined
                        dense
                    ></v-text-field>
                    <v-text-field
                        v-model="userData.last_name"
                        label="Nachname"
                        color="blue-grey"
                        disabled
                        outlined
                        dense
                    ></v-text-field>
                    <v-text-field
                        v-model="userData.email"
                        label="E-Mail-Adresse"
                        color="blue-grey"
                        disabled
                        outlined
                        dense
                    ></v-text-field>
                </v-card-text>
            </v-card>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserDetails',
        data() {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Benutzerübersicht', disabled: false, to: { name: 'user.index' }, exact: true },
                    { text: 'Benutzer-Details', disabled: false, to: { name: 'user.details', params: {user: this.$route.params.user }}, exact: true },
                ],
                userData: []
            }
        },
        mounted () {
            this.loadUserData()

            if (!this.$isAdmin()) {
                this.breadcrumbItems.splice(1, 1)
            }
        },
        methods: {
            async loadUserData () {
                this.isBusy = true

                const userId = this.$route.params.user

                await window.axios.get(`/user/${userId}/details`)
                    .then((response) => {
                        this.userData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
