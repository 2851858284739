<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Kundenübersicht</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin|crm-admin')"
                           :to="{ name: 'crm.customer.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neuer Kunde
                    </v-btn>
                </v-col>
            </v-row>
            <v-text-field
                v-model="search"
                append-icon="mdi-account-search"
                label="Suche"
                color="blue-grey"
                outlined
                dense
            ></v-text-field>
            <v-skeleton-loader
                type="table"
                transition="scale-transition"
                :loading="isLoadingCustomerData"
            >
                <v-data-table
                    :headers="headers"
                    :items="customers"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                >
                    <template v-slot:body="props">
                        <tbody>
                            <tr v-for="item in props.items">
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.number }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.full_name }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    <span v-html="item.vehicle_license_plate"></span>
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    <span v-html="item.vehicle_vin"></span>
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.company_name }}
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </router-link>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'CRMCustomerIndex',
        data () {
            return {
                isLoadingCustomerData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true }
                ],
                search: '',
                headers: [
                    { text: 'Kunden-Nr.', value: 'number' },
                    { text: 'Name', value: 'full_name' },
                    { text: 'Kennzeichen', value: 'vehicle_license_plate' },
                    { text: 'FIN', value: 'vehicle_vin' },
                    { text: 'Firmenname', value: 'company_name' },
                    { }
                ]
            }
        },
        computed: {
            ...mapGetters('customer', [
                'customers'
            ])
        },
        methods: {
            ...mapActions('customer', [
                'fetchCustomers'
            ]),
            async fetchData () {
                this.isLoadingCustomerData = true
                let response = await this.fetchCustomers()
                this.isLoadingCustomerData = false

                return {
                    data: response.data
                }
            }
        },
        created () {
            this.fetchData()
        }
    }
</script>

<style scoped>

</style>
