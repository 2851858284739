<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitVehicleData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neues Kunden-Fahrzeug</template>
                                <template v-else>Kunden-Fahrzeug Bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Fahrzeug
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Marke" rules="required|min:2|max:50" v-slot="{ errors }">
                                <v-combobox
                                    v-model="formData.brand"
                                    label="Marke"
                                    :error-messages="errors"
                                    :items="brands"
                                    item-text="description"
                                    item-value="id"
                                    @change="changedBrandsValue"
                                    :loading="isLoadingVehicleBrandData"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-combobox>
                            </ValidationProvider>
                            <ValidationProvider name="Modell" rules="required|min:2|max:50" v-slot="{ errors }">
                                <v-combobox
                                    v-model="formData.model"
                                    label="Modell"
                                    :error-messages="errors"
                                    :items="models"
                                    item-text="description"
                                    item-value="id"
                                    :load="isLoadingVehicleModelData"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-combobox>
                            </ValidationProvider>
                            <ValidationProvider name="Kennzeichen" rules="required|min:5|max:20" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.license_plate"
                                    label="Kennzeichen*"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="FIN" rules="required|min:5|max:20" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.vin"
                                    label="FIN*"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Erstzulassung" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.registration_date"
                                    label="Erstzulassung*"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="date"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <v-row align="center">
                                <v-col cols="6">
                                    <ValidationProvider name="HU/AU Monat" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="formData.inspection_month"
                                            label="HU/AU Monat*"
                                            :error-messages="errors"
                                            :items="months"
                                            color="blue-grey"
                                            outlined
                                            dense
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col cols="6">
                                    <ValidationProvider name="HU/AU Jahr" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="formData.inspection_year"
                                            label="HU/AU Jahr*"
                                            :error-messages="errors"
                                            :items="years"
                                            color="blue-grey"
                                            outlined
                                            dense
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <ValidationProvider name="Motorkennbuchstabe" rules="max:10" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.engine_code"
                                    label="Motorkennbuchstabe"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Getriebkennbuchstabe" rules="max:10" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.gear_code"
                                    label="Getriebkennbuchstabe"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Herstellerschlüsselnummer (HSN)" rules="max:4" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.manufacturer_code"
                                    label="Herstellerschlüsselnummer (HSN)"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Typschlüsselnummer (TSN)" rules="max:8" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.model_code"
                                    label="Typschlüsselnummer (TSN)"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMCustomerVehicleCreateEdit',
        data () {
            return {
                isLoadingVehicleBrandData: false,
                isLoadingVehicleModelData: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: 'Kunden-Details', disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true }
                ],
                brands: [],
                models: [],
                months: [ '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12' ],
                years: [],
                formData: {
                    brand: [],
                    brand_id: null,
                    brand_description: null,
                    model: [],
                    model_id: null,
                    model_description: null,
                    license_plate: null,
                    vin: null,
                    registration_date: null,
                    inspection_month: null,
                    inspection_year: null,
                    engine_code: null,
                    gear_code: null,
                    manufacturer_code: null,
                    model_code: null
                }
            }
        },
        mounted () {
            // TODO: in eine computed function umwandeln
            const today = new Date()
            var i

            for (i = 0; i <= 100; i++) {
                this.years.push(today.getFullYear() + 4 - i)
            }

            this.fetchVehicleBrands()

            if (this.isEdit) {
                this.loadVehicle()
            }
            else {
                this.formData.inspection_month = ('0' + (today.getMonth() + 1)).slice(-2)
                this.formData.inspection_year = today.getFullYear()
            }
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.vehicle.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Kunden-Fahrzeug bearbeiten', disabled: false, to: {name: 'crm.vehicle.edit', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neues Kunden-Fahrzeug', disabled: false, to: {name: 'crm.vehicle.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            async loadVehicle () {
                this.isBusy = true

                const customerId = this.$route.params.customer
                const vehicleId = this.$route.params.vehicle

                await window.axios.get(`/crm/customer/${customerId}/vehicle/${vehicleId}`)
                    .then((response) => {
                        this.formData.id = vehicleId
                        this.formData.brand = response.data.vehicle.brand
                        this.formData.model = response.data.vehicle.model
                        this.formData.license_plate = response.data.vehicle.license_plate
                        this.formData.vin = response.data.vehicle.vin
                        this.formData.registration_date = response.data.vehicle.registration_date
                        this.formData.inspection_month = response.data.vehicle.inspection_month
                        this.formData.inspection_year = response.data.vehicle.inspection_year
                        this.formData.engine_code = response.data.vehicle.engine_code
                        this.formData.gear_code = response.data.vehicle.gear_code
                        this.formData.manufacturer_code = response.data.vehicle.manufacturer_code
                        this.formData.model_code = response.data.vehicle.model_code

                        this.fetchVehicleModels (this.formData.brand.id)
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            },
            async submitVehicleData () {
                this.isLoading = true

                const customerId = this.$route.params.customer

                if (typeof this.formData.brand === 'object')
                    this.formData.brand_id = this.formData.brand.id
                else {
                    this.formData.brand_id = null
                    this.formData.brand_description = this.formData.brand
                }

                if (typeof this.formData.model === 'object')
                    this.formData.model_id = this.formData.model.id
                else {
                    this.formData.model_id = null
                    this.formData.model_description = this.formData.model
                }

                if (this.isEdit) {
                    const vehicleId = this.$route.params.vehicle

                    await window.axios.put(`/crm/customer/${customerId}/vehicle/${vehicleId}/update`, this.formData)
                        .then((response) => {
                            this.$router.push({
                                name: 'crm.vehicle.details',
                                params: { customer: customerId, vehicle: vehicleId }
                            })
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
                else {
                    await window.axios.post(`/crm/customer/${customerId}/vehicle/create`, this.formData)
                        .then((response) => {
                            this.$router.push({ name: 'crm.customer.details', params: { customer: customerId } })
                        })
                        .catch((e) => {
                            console.log(e)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            changedBrandsValue (brand) {
                if (typeof brand.id === 'undefined') {
                    this.models = []
                }
                else
                    this.fetchVehicleModels(brand.id)
            },
            async fetchVehicleBrands () {
                this.isLoadingVehicleBrandData = true

                await window.axios.get('/vehicle/brands')
                    .then((response) => {
                        this.brands = response.data.brands
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.isLoadingVehicleBrandData = false
                    })
            },
            async fetchVehicleModels (brandId) {
                this.isLoadingVehicleModelData = true

                await window.axios.get(`/vehicle/models/${brandId}`)
                    .then((response) => {
                        this.models = response.data.models
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.isLoadingVehicleModelData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
