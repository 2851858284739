<script>
    export default {
        name: 'RolesMixin',
        methods: {
            $isAdmin() {
                return this.$store.state.user.currentUser.roles['admin'] === true ? true : false
            },
            $hasRole(roleName) {
                if (roleName.toString().indexOf('|') === -1)
                    return this.$store.state.user.currentUser.roles[roleName] === undefined ?
                        false :
                        this.$store.state.user.currentUser.roles[roleName]
                else {
                    let result = false
                    const roles = roleName.split('|')

                    /*roleName.split('|').forEach((value) => {
                        //console.log(value)
                        //console.log(this.$store.state.user.currentUser.roles[value] === true)
                        result = this.$store.state.user.currentUser.roles[value] === true
                    })*/

                    for (let i = 0; i < roles.length; i++) {
                        if (this.$store.state.user.currentUser.roles[roles[i]] === true) {
                            result = true
                            break
                        }
                    }

                    return result
                }
            }
        }
    }
</script>
