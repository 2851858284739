<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitGalleryData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neues Bild</template>
                                <template v-else>Bild Bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Bild
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <div class="Uppy-DragDrop"></div>
                            <div class="Uppy-StatusBar"></div>
                            <ValidationProvider name="Dateien" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="isFileFilled"
                                    :error-messages="errors"
                                    class="d-none"
                                ></v-text-field>
                            </ValidationProvider>
                            <template v-for="(item, index) in previewUploadFiles">
                                <h2 v-if="index === 0">Hochzuladende Dateien</h2>
                                <v-row
                                    dense
                                    class="ma-0 pa-0"
                                >
                                    <v-col cols="5" md="8">
                                        <v-text-field
                                            v-model="item.name"
                                            label="Dateiname"
                                            color="blue-grey"
                                            disabled
                                            outlined
                                            dense
                                            class="ma-0 pa-0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="5" md="3">
                                        <v-text-field
                                            v-model="item.sizeInMB"
                                            label="Dateigröße in MB"
                                            color="blue-grey"
                                            disabled
                                            outlined
                                            dense
                                            class="ma-0 pa-0"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="2" md="1" class="text-md-center">
                                        <v-btn depressed
                                               color="secondary"
                                               @click="removeFile(index, item.id)"
                                               icon
                                        >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    import Uppy from '@uppy/core'
    import XHRUpload from '@uppy/xhr-upload'
    import DragDrop from '@uppy/drag-drop'
    import StatusBar from '@uppy/status-bar'
    import German from '@uppy/locales/lib/de_DE'

    export default {
        name: 'CRMVehicleGalleryCreate',
        data() {
            return {
                isError: false,
                isLoading: false,
                isLoadingTypeData: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: 'Kunden-Details', disabled: false, to: { name: 'crm.customer.details', params: { customer: this.$route.params.customer } }, exact: true },
                    { text: 'Fahrzeug-Details', disabled: false, to: { name: 'crm.vehicle.details', params: { customer: this.$route.params.customer, vehicle: this.$route.params.vehicle } }, exact: true }
                ],
                maxFileSizeInBytes: 200000000, // 200 MB
                previewUploadFiles: []
            }
        },
        mounted () {
            this.instantiateUppy()
        },
        computed: {
            isFileFilled () {
                return this.previewUploadFiles.length > 0 ? true : null
            },
            isEdit () {
                if (this.$route.name === 'crm.vehicle.gallery.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Bild bearbeiten', disabled: false, to: {name: 'crm.vehicle.gallery.edit', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neues Bild', disabled: false, to: {name: 'crm.vehicle.gallery.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            instantiateUppy() {
                let customerId = this.$route.params.customer
                let vehicleId = this.$route.params.vehicle

                this.uppy = Uppy({
                    debug: true,
                    autoProceed: false,
                    locale: German,
                    restrictions: {
                        maxFileSize: this.maxFileSizeInBytes,
                        minNumberOfFiles: 1,
                        maxNumberOfFiles: 10,
                        allowedFileTypes: ['image/*']
                    }
                })
                .use(DragDrop, {
                    target: '.Uppy-DragDrop',
                    width: '100%',
                    height: '100%'
                })
                .use(StatusBar, {
                    target: '.Uppy-StatusBar',
                    hideUploadButton: true,
                    hideAfterFinish: false
                })
                /*.use(ThumbnailGenerator, {
                    thumbnailWidth: 200,
                    // thumbnailHeight: 200 // optional, use either width or height,
                    waitForThumbnailsBeforeUpload: false
                })*/
                .use(XHRUpload, {
                    limit: 10,
                    endpoint: `/api/crm/customer/${customerId}/vehicle/${vehicleId}/gallery/upload`,
                    formData: true,
                    fieldName: 'file',
                    headers: {
			'Authorization': `Bearer ${Ls.get('auth.token')}`,
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    }
                })

                /*this.uppy.on('thumbnail:generated', (file, preview) => {
                    this.previewUploadFiles.push({
                        src: preview,
                        width: 100
                    })
                })*/

                this.uppy.on('file-added', (file) => {
                    const sizeInMB = file.size / 1024 / 1024

                    this.previewUploadFiles.push({
                        id: file.id,
                        name: file.name,
                        sizeInMB: sizeInMB.toFixed(2)
                    })
                })
            },
            removeFile(index, fileId) {
                this.previewUploadFiles.splice(index, 1)
                this.uppy.removeFile(fileId)
            },
            resetUploader() {
                this.uppy.reset()
            },
            submitGalleryData() {
                this.isLoading = true

                this.uppy.upload()

                let customerId = this.$route.params.customer
                let vehicleId = this.$route.params.vehicle

                const postData = {
                    customer_id: customerId,
                    vehicle_id: vehicleId,
                    files: []
                }

                this.uppy.on('complete', (event) => {
                    event.successful.forEach(function(entry) {
                        postData.files.push({
                            path: entry.response.body.path,
                            filename: entry.name,
                            size: entry.size,
                            mime: entry.type
                        })
                    })

                    if(postData.files) {
                        window.axios.post(`/crm/customer/${customerId}/vehicle/${vehicleId}/gallery/store`, postData)
                            .then((response) => {
                                if (response.data.success) {
                                    this.$router.push({
                                        name: 'crm.vehicle.details',
                                        params: {
                                            customer: customerId,
                                            vehicle: vehicleId
                                        }
                                    })
                                }
                            })
                            .catch((error) => {
                                this.resetUploader()
                                console.log(error)
                            })
                            .finally(() => {
                                this.isLoading = false
                            })
                        ;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    @import '~@uppy/core/dist/style.min.css';
    @import '~@uppy/drag-drop/dist/style.min.css';
    @import '~@uppy/status-bar/dist/style.min.css';
</style>
