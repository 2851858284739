var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('please-wait',{attrs:{"showMe":_vm.isBusy}}),_vm._v(" "),_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isBusy && !_vm.isError)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neuer Artikel")]:[_vm._v("Artikel")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"6"}},[(_vm.$hasRole('admin|fi-admin'))?_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Artikel\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2):_vm._e()],1)],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hint":"Artikelname, wie er auf der Rechnung erscheint","color":"blue-grey","counter":"100","outlined":"","dense":"","autofocus":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Name "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Einheit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.units,"item-text":"name","item-value":"id","color":"blue-grey","hint":"Erscheint, je nach Rechnungsvorlage, auf der Rechnung","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Einheit "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.unit_id),callback:function ($$v) {_vm.$set(_vm.formData, "unit_id", $$v)},expression:"formData.unit_id"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Preis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{attrs:{"error-messages":errors,"label":"Preis *","color":"blue-grey","prefix":"€","outlined":"","dense":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Beschreibung","rules":"min:10|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Beschreibung","hint":"optionale Beschreibung, erscheint, je nach Rechnungsvorlage, auf der Rechnung","color":"blue-grey","counter":"255","outlined":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})]}}],null,true)})],1)],1)],1)]}}],null,false,2282887548)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }