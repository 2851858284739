<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Fahrzeug-Details</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right" v-if="$hasRole('admin|crm-admin')">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <v-list-item
                                v-for="(navitem, i) in vehicleMenu"
                                :key="i"
                                :to="{ name: navitem.link, params: { customer: customerId, vehicle: vehicleId } }"
                                exact
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ navitem.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ navitem.title }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="table"
                transition="scale-transition"
                :loading="isLoadingVehicleData"
            >
                <v-tabs grow show-arrows>
                    <v-tab>Allgemein</v-tab>
                    <v-tab-item>
                        <v-card outlined>
                            <v-row>
                                <v-col cols="6">
                                    <v-list dense>
                                        <v-list-item-group>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-car</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ vehicleData.vehicle }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <span>Fahrzeug</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-counter</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ vehicleData.vin }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <span>FIN</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-reload</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ vehicleData.inspection }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <span>HU/AU</span>
                                            </v-tooltip>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>
                                <v-col cols="6">
                                    <v-list dense>
                                        <v-list-item-group>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-account-card-details</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ vehicleData.license_plate }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <span>Kennzeichen</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-list-item v-on="on">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-calendar-range</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ vehicleData.registration_date }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                                <span>Erstzulassung</span>
                                            </v-tooltip>
                                        </v-list-item-group>
                                    </v-list>
                                </v-col>
                            </v-row>
                            <v-list dense>
                                <v-list-item two-line v-if="vehicleData.engine_code">
                                    <v-list-item-content>
                                        <v-list-item-title>Motorkennbuchstabe</v-list-item-title>
                                        <v-list-item-subtitle>{{ vehicleData.engine_code }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="vehicleData.gear_code">
                                    <v-list-item-content>
                                        <v-list-item-title>Getriebekennbuchstabe</v-list-item-title>
                                        <v-list-item-subtitle>{{ vehicleData.gear_code }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="vehicleData.manufacturer_code">
                                    <v-list-item-content>
                                        <v-list-item-title>Herstellerschlüsselnummer (HSN)</v-list-item-title>
                                        <v-list-item-subtitle>{{ vehicleData.manufacturer_code }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item two-line v-if="vehicleData.model_code">
                                    <v-list-item-content>
                                        <v-list-item-title>Typschlüsselnummer (TSN)</v-list-item-title>
                                        <v-list-item-subtitle>{{ vehicleData.model_code }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-tab-item>
                    <v-tab v-if="documentData.length > 0">Dokumente</v-tab>
                    <v-tab-item v-if="documentData.length > 0">
                        <v-card outlined>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">Datum</th>
                                        <th class="text-left">Bezeichnung</th>
                                        <th class="text-left">Kategorie</th>
                                        <th class="text-left">Dokument</th>
                                        <th class="text-left">Ersteller</th>
                                        <th class="text-right">Aktion</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in documentData">
                                        <td>{{ item.created_at }}</td>
                                        <td>{{ item.description }}</td>
                                        <td>{{ item.category }}</td>
                                        <td>{{ item.filename }}</td>
                                        <td>{{ item.created_by }}</td>
                                        <td class="text-right">
                                            <v-btn icon
                                                   text
                                                   @click="showDocument(item.uuid)"
                                                   v-if="!item.deleted_at"
                                            >
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn icon
                                                   text
                                                   @click="downloadDocument(item.uuid, item.filename)"
                                                   v-if="!item.deleted_at"
                                            >
                                                <v-icon>mdi-download</v-icon>
                                            </v-btn>
                                            <v-btn icon
                                                   text
                                                   @click="destroyDocument(index, customerId, vehicleId, item.id)"
                                                   v-if="!item.deleted_at && $hasRole('admin|crm-admin')"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <template v-else-if="item.deleted_at">Gelöscht</template>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab v-if="documentAssignmentData.length > 0">Auftragsbestätigungen</v-tab>
                    <v-tab-item v-if="documentAssignmentData.length > 0">
                        <v-card outlined>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">Datum</th>
                                        <th class="text-right">km-Stand</th>
                                        <th class="text-left">Fertigstellung</th>
                                        <th class="text-left">Ersteller</th>
                                        <th class="text-right">Aktion</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in documentAssignmentData">
                                        <td>{{ item.created_at }}</td>
                                        <td class="text-right">{{ item.mileage }}</td>
                                        <td>{{ item.date_finished }}</td>
                                        <td>{{ item.created_by }}</td>
                                        <td class="text-right">
                                            <v-btn
                                                color="secondary"
                                                icon
                                                :to="{ name: 'crm.vehicle.documentassignment.details', params: {
                                                customer: customerId,
                                                vehicle: vehicleId,
                                                documentassignment: item.id
                                            }}"
                                                v-if="!item.deleted_at"
                                            >
                                                <v-icon>mdi-eye</v-icon>
                                            </v-btn>
                                            <v-btn icon
                                                   text
                                                   @click="destroyDocumentAssignment(index, customerId, vehicleId, item.id)"
                                                   v-if="!item.deleted_at && $hasRole('admin|crm-admin')"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                            <template v-else-if="item.deleted_at">Gelöscht</template>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab v-if="noticeData.length > 0">Notizen</v-tab>
                    <v-tab-item v-if="noticeData.length > 0">
                        <v-card outlined>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">Datum</th>
                                        <th class="text-left">Bezeichnung</th>
                                        <th class="text-left">Kategorie</th>
                                        <th class="text-left">Ersteller</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in noticeData">
                                        <td>{{ item.created_at }}</td>
                                        <td>{{ item.text }}</td>
                                        <td>{{ item.category }}</td>
                                        <td>{{ item.created_by }}</td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab v-if="wheelStorageData.length > 0">Räder</v-tab>
                    <v-tab-item v-if="wheelStorageData.length > 0">
                        <v-card outlined>
                            <v-simple-table>
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">Einlagerung</th>
                                        <th class="text-left">Typ</th>
                                        <th class="text-left">Reifen VA</th>
                                        <th class="text-left">Reifen HA</th>
                                        <th class="text-left">Ersteller</th>
                                        <th class="text-right">Aktion</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in wheelStorageData">
                                        <td>{{ item.date_stored }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>{{ item.wheels_front }}</td>
                                        <td>{{ item.wheels_rear }}</td>
                                        <td>{{ item.created_by }}</td>
                                        <td class="text-right">
                                            <v-btn icon
                                                   text
                                                   xact
                                                   :to="{ name: 'crm.vehicle.wheelstorage.details', params: { customer: customerId, vehicle: vehicleId, wheelstorage: item.id } }">
                                                <v-icon>mdi-folder-open</v-icon>
                                            </v-btn>
                                            <v-btn icon
                                                   text
                                                   @click="destroyWheelStorage(index, customerId, vehicleId, item.id)"
                                                   v-if="!item.deleted_at && $hasRole('admin|crm-admin')"
                                            >
                                                <v-icon>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab v-if="galleryData.length > 0">Galerie</v-tab>
                    <v-tab-item v-if="galleryData.length > 0">
                        <v-dialog v-model="showGalleryDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
                            <template v-slot:activator="{ on }">
                                <v-row>
                                    <v-col cols="12" md="4" v-for="(item, index) in galleryData" :key="index">
                                        <v-img :src="item.thumbnail" v-on="on" style="cursor:pointer;" height="200"></v-img>
                                        <div class="title mb-1">{{ item.filename }}</div>
                                        <div class="subheading">{{ item.created_by }} am {{ item.created_at }}</div>
                                    </v-col>
                                </v-row>
                            </template>
                            <v-card dark>
                                <v-toolbar dark>
                                    <v-toolbar-title>Galerie</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon dark @click="showGalleryDialog = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-carousel height="auto">
                                    <v-carousel-item
                                        v-for="(item, index) in galleryData"
                                        :key="index"
                                        :src="item.image"
                                    ></v-carousel-item>
                                </v-carousel>
                            </v-card>
                        </v-dialog>
                    </v-tab-item>
                </v-tabs>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleDetails',
        data () {
            return {
                isLoadingVehicleData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: 'Kunden-Details', disabled: false, to: { name: 'crm.customer.details', params: { customer: this.$route.params.customer } }, exact: true },
                    { text: 'Fahrzeug-Details', disabled: false, to: { name: 'crm.vehicle.details', params: { customer: this.$route.params.customer, vehicle: this.$route.params.vehicle } }, exact: true }
                ],
                customerId: this.$route.params.customer,
                vehicleId: this.$route.params.vehicle,
                vehicleData: [],
                wheelStorageData: [],
                documentData: [],
                documentAssignmentData: [],
                noticeData: [],
                galleryData: [],
                vehicleMenu: [
                    { title: 'Bearbeiten', link: 'crm.vehicle.edit', icon: 'mdi-pencil' },
                    { title: 'Notiz hinzufügen', link: 'crm.vehicle.notice.create', icon: 'mdi-newspaper' },
                    { title: 'Bild hinzufügen', link: 'crm.vehicle.gallery.create', icon: 'mdi-file-image' },
                    { title: 'Dokument hinzufügen', link: 'crm.vehicle.document.create', icon: 'mdi-file-document' },
                    { title: 'Auftragsbestätigung hinzufügen', link: 'crm.vehicle.documentassignment.create', icon: 'mdi-file-document-edit' },
                    { title: 'Räder einlagern', link: 'crm.vehicle.wheelstorage.create', icon: 'mdi-car' }
                ],
                showGalleryDialog: false
            }
        },
        mounted () {
            this.fetchVehicleData()
        },
        methods: {
            destroyWheelStorage (index, customerId, vehicleId, wheelStorageId) {
                if (confirm('Möchten Sie die eingelagerten Räder wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}/wheelstorage/${wheelStorageId}`)
                        .then(() => {
                            this.wheelStorageData.splice(index, 1)
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
            destroyDocument (index, customerId, vehicleId, documentId) {
                if (confirm('Möchten Sie das Dokument wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}/document/${documentId}`)
                        .then(() => {
                            Vue.set(this.documentData[index], 'deleted_at', true)
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
            destroyDocumentAssignment (index, customerId, vehicleId, documentAssignmentId) {
                if (confirm('Möchten Sie die Auftragsbestätigung wirklich löschen?')) {
                    let response = window.axios.delete(`/crm/customer/${customerId}/vehicle/${vehicleId}/documentassignment/${documentAssignmentId}`)
                        .then(() => {
                            Vue.set(this.documentAssignmentData[index], 'deleted_at', true)
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
            showDocument (uuid) {
                // TODO: überarbeiten
                const url = window.location.origin + `/document/show/${uuid}`
                window.open(url, '_blank')
            },
            downloadDocument (uuid, fileName) {
                window.axios.get(`/document/${uuid}`, { params: { responseType: 'blob' } })
                    .then((response) => {
                        /*let blob = new Blob([response.data])
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = fileName

                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)*/

                        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                        const fileLink = document.createElement('a')
                        fileLink.href = fileURL
                        fileLink.setAttribute('download', fileName)
                        document.body.appendChild(fileLink)
                        fileLink.click()
                        fileLink.remove()
                    })
                    .catch(() => {
                        this.isError = true
                    })
            },
            async fetchVehicleData () {
                this.isLoadingVehicleData = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/details`)
                    .then((response) => {
                        this.vehicleData = response.data.vehicle
                        this.wheelStorageData = response.data.wheel_storage
                        this.documentData = response.data.documents
                        this.documentAssignmentData = response.data.documentAssignment
                        this.noticeData = response.data.notices
                        this.galleryData = response.data.galleryData
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingVehicleData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
