<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitCustomerData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neuer Kunde</template>
                                <template v-else>Kunde Bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Kunde
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-skeleton-loader
                        type="card"
                        transition="scale-transition"
                        :loading="isLoadingCustomerData"
                    >
                        <v-card outlined>
                            <v-card-text>
                                <v-text-field
                                    v-model="formData.number"
                                    label="Kundennummer"
                                    disabled
                                    v-if="isEdit"
                                    outlined
                                    dense
                                ></v-text-field>
                                <v-select
                                    v-model="formData.type_id"
                                    label="Art"
                                    :items="types"
                                    item-text="description"
                                    item-value="id"
                                    color="blue-grey"
                                    :loading="isLoadingTypeData"
                                    autofocus
                                    outlined
                                    dense
                                ></v-select>
                                <ValidationProvider
                                    name="Firmenname"
                                    :rules="`${isCompany ? 'required|' : ''}min:3|max:100`"
                                    v-slot="{ errors }"
                                    v-if="isCompany"
                                >
                                    <v-text-field
                                        v-model="formData.company_name"
                                        label="Firmenname"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                                <v-switch
                                    v-model="addContactPerson"
                                    label="Ansprechpartner hinzufügen"
                                    v-if="isCompany"
                                    color="blue-grey"
                                    dense
                                ></v-switch>
                                <v-row v-if="showContactPerson">
                                    <v-col md="3" cols="12">
                                        <ValidationProvider name="Titel" :rules="`${isCompany ? '' : 'required'}`" v-slot="{ errors }">
                                            <v-select
                                                v-model="formData.salutation_id"
                                                label="Anrede"
                                                :items="salutations"
                                                item-text="description"
                                                item-value="id"
                                                :error-messages="errors"
                                                :loading="isLoadingSalutationData"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="3" cols="12">
                                        <ValidationProvider name="Titel" rules="max:20" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.title"
                                                label="Titel"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="3" cols="12">
                                        <ValidationProvider name="Vorname" :rules="`${isCompany ? '' : 'required|'}min:3|max:70`" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.first_name"
                                                :label="`Vorname${isCompany ? '' : '*'}`"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col md="3" cols="12">
                                        <ValidationProvider name="Nachname" :rules="`${isCompany ? '' : 'required|'}min:3|max:70`" v-slot="{ errors }">
                                            <v-text-field
                                                v-model="formData.last_name"
                                                :label="`Nachname${isCompany ? '' : '*'}`"
                                                :error-messages="errors"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <ValidationProvider name="Geburtsdatum" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.date_of_birth"
                                        label="Geburtsdatum"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        type="date"
                                        v-if="!isCompany"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider name="Telefon" rules="max:30" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.phone"
                                        label="Telefon"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider name="Telefax" rules="max:30" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.fax"
                                        label="Telefax"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider name="Mobil" rules="max:30" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.mobile"
                                        label="Mobil"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider name="E-Mail-Adresse" rules="email" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="formData.email"
                                        label="E-Mail-Adresse"
                                        :error-messages="errors"
                                        color="blue-grey"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-card-text>
                        </v-card>
                    </v-skeleton-loader>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'CRMCustomerCreateEdit',
        data () {
            return {
                isLoadingTypeData: false,
                isLoadingSalutationData: false,
                isLoadingCustomerData: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                ],
                showContactPerson: false,
                types: [],
                salutations: [],
                formData: {
                    number: null,
                    type_id: null,
                    salutation_id: null,
                    title: null,
                    first_name: null,
                    last_name: null,
                    date_of_birth: null,
                    company_name: null,
                    phone: null,
                    fax: null,
                    mobile: null,
                    email: null
                }
            }
        },
        mounted () {
            this.fetchTypeData()
            this.fetchCustomerSalutationData()

            if (this.isEdit) {
                this.fetchCustomerData()
            }
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.customer.edit') {
                    this.breadcrumbItems.push(
                        {text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true},
                        {text: 'Kunde bearbeiten', disabled: false, to: {name: 'crm.customer.edit', params: {customer: this.$route.params.customer}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neuer Kunde', disabled: false, to: {name: 'crm.customer.create'}, exact: true}
                    )

                    return false
                }
            },
            isCompany () {
                return this.formData.type_id !== 1
            },
            addContactPerson: {
                get () {
                    return !!(this.isCompany === true && this.formData.last_name)
                },
                set (value) {
                    if (value === false) {
                        this.formData.salutation_id = null
                        this.formData.title = null
                        this.formData.first_name = null
                        this.formData.last_name = null
                    }
                    this.showContactPerson = value
                    return value
                }
            }
        },
        methods: {
            async submitCustomerData() {
                this.isLoading = true

                if (this.isEdit) {
                    let customerId = this.$route.params.customer

                    // date of birth not necessary
                    if (this.isCompany === true) {
                        this.formData.date_of_birth = null
                    }

                    // clear contact person
                    /*if (this.addContactPerson === false) {
                        this.formData.salutation_id = null
                        this.formData.title = null
                        this.formData.first_name = null
                        this.formData.last_name = null
                    }*/

                    try {
                        let response = await window.axios.put(`/crm/customer/${customerId}/update`, this.formData)

                        if (response.data.success) {
                            this.$router.push({ name: 'crm.customer.details', customer: customerId })
                            this.isLoading = false
                            return true
                        }
                    }
                    catch (err) {
                        console.log(err)

                        this.isLoading = false
                    }
                }
                else {
                    try {
                        let response = await window.axios.post('/crm/customer/create', this.formData)

                        if (response.data.success) {
                            this.$router.push({ name: 'crm.customer.details', params: { customer: response.data.customerId } })
                            this.isLoading = false
                            return true
                        }
                    }
                    catch (err) {
                        console.log(err)

                        this.isLoading = false
                    }
                }
            },
            async fetchTypeData () {
                this.isLoadingTypeData = true

                await window.axios.get('/crm/customer/types')
                    .then((response) => {
                        this.types = response.data.types

                        if (!this.isEdit) {
                            this.formData.type_id = response.data.default.id
                            this.showContactPerson = true
                        }
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingTypeData = false
                    })
            },
            async fetchCustomerSalutationData () {
                this.isLoadingSalutationData = true

                await window.axios.get('/crm/customer/salutations')
                    .then((response) => {
                        this.salutations = response.data.salutations
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingSalutationData = false
                    })
            },
            async fetchCustomerData () {
                this.isLoadingCustomerData = true

                let customerId = this.$route.params.customer

                await window.axios.get(`/crm/customer/${customerId}/edit`)
                    .then((response) => {
                        this.formData = response.data.customer

                        this.showContactPerson = !this.isCompany ? true :
                            this.isCompany && this.addContactPerson ? true : false
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingCustomerData = false
                    })

                /*this.isBusy = true
                let response = await window.axios.get(`/crm/customer/${customerId}/edit`)

                this.formData.id = this.$route.params.customer
                this.formData.number = response.data.customer.number
                this.formData.type_id = response.data.customer.type_id
                this.formData.salutation_id = response.data.customer.salutation_id
                this.formData.title = response.data.customer.title
                this.formData.first_name = response.data.customer.first_name
                this.formData.last_name = response.data.customer.last_name
                this.formData.date_of_birth = response.data.customer.date_of_birth ? moment(response.data.customer.date_of_birth).format('YYYY-MM-DD') : null
                this.formData.company_name = response.data.customer.company_name
                this.formData.phone = response.data.customer.phone
                this.formData.fax = response.data.customer.fax
                this.formData.mobile = response.data.customer.mobile
                this.formData.email = response.data.customer.email

                this.showContactPerson = !this.isCompany ? true :
                        this.isCompany && this.addContactPerson ? true : false

                this.isBusy = false*/
            }
        }
    }
</script>

<style scoped>

</style>
