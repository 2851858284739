<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Rechnung {{ invoiceData.invoice_number }}</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin|fi-admin')"
                    >
                        Zahlung erfassen
                    </v-btn>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn depressed
                                   color="secondary"
                                   v-on="on">
                                <v-icon>mdi-dots-horizontal</v-icon>
                            </v-btn>
                        </template>
                        <v-list elevation="0">
                            <v-list-item :to="{ name: 'fi.invoice.edit', params: { invoice: invoiceData.id } }" exact>
                                <v-list-item-icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Bearbeiten</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item @click="destroyInvoice(invoiceData.id)">
                                <v-list-item-icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Löschen</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="card"
                transition="scale-transition"
                :loading="isLoadingInvoiceData"
            >
                <embed :src="invoiceUrl" type="application/pdf" width="100%" height="500px" />
            </v-skeleton-loader>
            <!--<v-row>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>{{ customerData.formattedCustomerName }}</v-card-title>
                        <v-card-subtitle>Kundennummer {{ customerData.number }}</v-card-subtitle>
                        <v-card-text>
                            <div class="text--primary">
                                <p class="pa-0 ma-0">{{ addressData.street }} {{ addressData.nr }}</p>
                                <p class="pa-0 ma-0">{{ addressData.zip }} {{ addressData.city }}</p>
                                <p class="pa-0 ma-0">{{ addressData.country.description }}</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>Fahrzeug</v-card-title>
                        <v-card-subtitle>&nbsp;</v-card-subtitle>
                        <v-card-text>
                            <div class="text--primary">
                                <p class="pa-0 ma-0">{{ vehicleData.model.brand.description }} {{ vehicleData.model.description }}</p>
                                <p class="pa-0 ma-0">{{ vehicleData.license_plate }}</p>
                                <p class="pa-0 ma-0">{{ vehicleData.vin }}</p>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" md="4">
                    <v-card outlined>
                        <v-card-title>Daten</v-card-title>
                        <v-card-subtitle>Rechnungsnummer {{ invoiceData.invoice_number }}</v-card-subtitle>
                        <v-card-text>
                            <div class="text--primary">
                                <p class="pa-0 ma-0">Datum {{ invoiceData.invoice_date }}</p>
                                <p class="pa-0 ma-0">Fälligkeit {{ invoiceData.due_date }}</p>
                                <p class="pa-0 ma-0">
                                    Leistungsdatum {{ invoiceData.date_start }}
                                    <template v-if="invoiceData.date_end">
                                        bis {{ invoiceData.date_end }}
                                    </template>
                                </p>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            -->
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'FIInvoiceCreateEdit',
        data () {
            return {
                isLoadingInvoiceData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Rechnungen', disabled: false, to: { name: 'fi.invoice.index' }, exact: true },
                    { text: 'Rechnung anzeigen', disabled: false, to: { name: 'fi.invoice.details', params: { invoice: this.$route.params.invoice } }, exact: true }
                ],
                invoiceData: [],
                invoiceUrl: null
                //customerData: Array,
                //addressData: Array,
                //vehicleData: Array
            }
        },
        mounted () {
            this.fetchInvoiceData()
        },
        methods: {
            async fetchInvoiceData () {
                this.isLoadingInvoiceData = true

                let invoiceId = this.$route.params.invoice

                await window.axios.get(`/fi/invoice/${invoiceId}/details`)
                    .then((response) => {
                        this.invoiceData = response.data.invoiceData
                        this.invoiceUrl = response.data.invoiceUrl
                        //this.customerData = response.data.customer
                        //this.addressData = response.data.address
                        //this.vehicleData = response.data.vehicle
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingInvoiceData = false
                    })
            },
            destroyInvoice (invoiceId) {
                if (confirm('Möchten Sie die Rechnung wirklich löschen?')) {
                    let response = window.axios.delete(`/fi/invoice/${invoiceId}`)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'fi.invoice.index'
                                })
                            }
                        })
                        .catch(() => {
                            this.isError = true
                        })
                }
            },
        }
    }
</script>

<style scoped>

</style>
