<template>
    <!--<v-card outlined v-if="showMe">
        <v-card-title>Es ist ein Fehler aufgetreten</v-card-title>
        <v-card-subtitle>Fehlertext</v-card-subtitle>
        <v-card-text class="text--primary">
            {{ message }}
        </v-card-text>
    </v-card>-->
    <v-snackbar
        v-model="showMe"
    >
        {{ message }}
        <!--<v-btn
            dark
            text
            @click="resetError"
        >
            Close
        </v-btn>-->
    </v-snackbar>
</template>

<script>
    import { eventBus } from '@/event-bus'

    export default {
        name: 'Error',
        props: {
            showMe: Boolean
        },
        data: () => ({
            message: null
        }),
        created () {
            eventBus.$on('Error', (data) => {
                this.message = data.message
            })
        }/*,
        methods: {
            resetError () {
                this.$emit('resetError', false)
            }
        }*/
    }
</script>

<style scoped>

</style>
