<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neuer Artikel</template>
                                <template v-else>Artikel</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|fi-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Artikel
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Name" rules="required|min:3|max:100" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.name"
                                    :error-messages="errors"
                                    hint="Artikelname, wie er auf der Rechnung erscheint"
                                    color="blue-grey"
                                    counter="100"
                                    outlined
                                    dense
                                    autofocus
                                >
                                    <template #label>
                                        Name <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Einheit" rules="required" v-slot="{ errors }">
                                <v-select
                                    v-model="formData.unit_id"
                                    :error-messages="errors"
                                    :items="units"
                                    item-text="name"
                                    item-value="id"
                                    color="blue-grey"
                                    hint="Erscheint, je nach Rechnungsvorlage, auf der Rechnung"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Einheit <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-select>
                            </ValidationProvider>
                            <ValidationProvider name="Preis" rules="required" v-slot="{ errors }">
                                <v-currency-field
                                    v-model="price"
                                    :error-messages="errors"
                                    label="Preis *"
                                    color="blue-grey"
                                    prefix="€"
                                    outlined
                                    dense
                                ></v-currency-field>
                            </ValidationProvider>
                            <ValidationProvider name="Beschreibung" rules="min:10|max:255" v-slot="{ errors }">
                                <v-textarea
                                    v-model="formData.description"
                                    :error-messages="errors"
                                    label="Beschreibung"
                                    hint="optionale Beschreibung, erscheint, je nach Rechnungsvorlage, auf der Rechnung"
                                    color="blue-grey"
                                    counter="255"
                                    outlined
                                ></v-textarea>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'FIItemCreateEdit',
        data () {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Artikel', disabled: false, to: { name: 'fi.item.index' }, exact: true },
                ],
                formData: {
                    unit_id: null,
                    name: null,
                    description: null,
                    price: ''
                },
                units: []
            }
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'fi.item.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Artikel bearbeiten', disabled: false, to: {name: 'fi.item.edit', params: {item: this.$route.params.item}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neuer Artikel', disabled: false, to: {name: 'fi.item.create'}, exact: true}
                    )

                    return false
                }
            },
            price: {
                get: function () {
                    return this.formData.price / 100
                },
                set: function (newValue) {
                    this.formData.price = newValue * 100
                }
            },
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            async fetchData () {
                this.isBusy = true

                if (this.isEdit) {
                    let itemId = this.$route.params.item

                    await window.axios.get(`/fi/item/${itemId}`)
                        .then((response) => {
                            this.formData = response.data.item
                        })
                        .catch(() => {
                            this.isError = true
                            this.isBusy = false
                        })
                }

                await window.axios.get('/fi/item/create')
                    .then((response) => {
                        this.units = response.data.units
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            },
            async submitData() {
                this.isLoading = true

                if (this.isEdit) {
                    let itemId = this.$route.params.item

                    await window.axios.put(`/fi/item/${itemId}`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'fi.item.index'
                                })
                            }
                        })
                        .catch(() => {
                            this.isError = true
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                } else {
                    await window.axios.post('/fi/item/create', this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'fi.item.index'
                                })
                            }
                        })
                        .catch(() => {
                            this.isError = true
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
        }
    }
</script>

<style scoped>

</style>
