<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <v-menu bottom right v-if="$hasRole('admin|time-management-admin')">
                <template v-slot:activator="{ on }">
                    <v-btn
                        outlined
                        class="mr-4"
                        color="grey darken-2"
                        v-on="on"
                    >
                        <span>{{ labelUser }}</span>
                        <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item-group v-model="usersSelected" color="grey darken-2">
                        <v-list-item
                            v-for="user in users"
                            :key="user.id"
                            @click="fetchTimeManagementData(user.id)"
                        >
                            <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
            <p class="ma-0 pa-0">
                Urlaub dieses Jahr gesamt: <strong>{{ vacationDaysTotal }}</strong>
            </p>
            <p class="ma-0 pa-0">
                Urlaub noch planbar: <strong>{{ vacationDaysAvailable }}</strong>
            </p>
            <v-row>
                <v-col cols="12"
                       md="3"
                       v-for="n in 12"
                       :key="n"
                >
                    <v-card height="100%">
                        <v-card-title>{{ monthName(n) }}</v-card-title>
                        <v-card-text class="text--primary">
                            <p class="ma-0 pa-0">
                                Urlaub geplant: <strong>{{ vacationDaysUsed[n-1] }}</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Krankheitstage: <strong>{{ sickDaysTotal[n-1] }}</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Soll-Arbeitzeit: <strong>{{ workingHoursTarget[n-1] }}h</strong>
                            </p>
                            <p class="ma-0 pa-0">
                                Ist-Arbeitszeit: <strong>{{ workingHoursTotal[n-1] }}h</strong>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-content>
</template>

<script>
    import moment from 'moment'

    export default {
        name: 'UserTimeManagementStatisticsIndex',
        data: () => ({
            isBusy: false,
            isError: false,
            isLoading: false,
            users: [],
            usersSelected: 0,
            vacationDaysTotal: [],
            vacationDaysUsed: [],
            vacationDaysAvailable: [],
            sickDaysTotal: [],
            workingHoursTarget: [],
            workingHoursTotal: []
        }),
        computed: {
            isUser () {
                return this.$hasRole('time-management-user')
            },
            labelUser () {
                return this.users[this.usersSelected].full_name
            }
        },
        created () {
            this.fetchData()
        },
        methods: {
            async fetchData () {
                this.isBusy = true

                let responseUsers = await window.axios.get('/user/timemanagement')

                if (responseUsers.status === 200) {
                    this.users = responseUsers.data.users

                    await this.fetchTimeManagementData(this.users[0].id)
                        .then((response) => {
                            this.isBusy = false
                        })
                        .catch(() => {
                            this.isBusy = false
                            this.isError = true
                        })
                }
                else {
                    this.isBusy = false
                    this.isError = true
                }
            },
            fetchTimeManagementData (userId) {
                return window.axios.get(`/user/timemanagement-statistics/${userId}`)
                    .then((response) => {
                        this.vacationDaysTotal = response.data.vacationDaysTotal
                        this.vacationDaysUsed = response.data.vacationDaysUsed
                        this.vacationDaysAvailable = response.data.vacationDaysAvailable
                        this.sickDaysTotal = response.data.sickDaysTotal
                        this.workingHoursTarget = response.data.workingHoursTarget
                        this.workingHoursTotal = response.data.workingHoursTotal
                    })
            },
            monthName(month) {
                return moment([1970, month-1, 1]).locale('de').format('MMMM')
            }
        }
    }
</script>

<style scoped>

</style>
