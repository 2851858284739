import * as types from './mutation-types'

export default {
    [types.BOOTSTRAP_ADDRESS] (state, address) {
        state.address = address
    },


    [types.ADD_ADDRESS] (state, data) {
        state.address.push(data.address)
    },

    [types.UPDATE_ADDRESS] (state, data) {
        state.address = data.address
    },

    [types.DELETE_ADDRESS] (state, id) {
        let index = state.address.findIndex(address => address.id === id)
        state.address.splice(index, 1)
    },

}
