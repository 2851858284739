<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <ValidationObserver v-slot="{ invalid }">
                <form @submit.prevent="submitNoticeData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Notiz</template>
                                <template v-else>Notiz bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Notiz
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <v-select
                                v-model="formData.type_id"
                                label="Art"
                                :items="typeData"
                                item-text="description"
                                item-value="id"
                                :loading="isLoadingTypeData"
                                color="blue-grey"
                                outlined
                                dense
                            ></v-select>
                            <ValidationProvider name="Notiz" rules="required|max:1000" v-slot="{ errors }">
                                <v-textarea
                                    v-model="formData.text"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    counter="1000"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Notiz <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-textarea>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleNoticeCreate',
        data() {
            return {
                isError: false,
                isLoading: false,
                isLoadingTypeData: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true },
                    { text: 'Kunden-Fahrzeug', disabled: false, to: {name: 'crm.vehicle.details', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true }
                ],
                formData: {
                    type_id: null,
                    text: null,
                },
                typeData: []
            }
        },
        mounted () {
            this.fetchNoticeTypes()
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.vehicle.notice.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Notiz bearbeiten', disabled: false, to: {name: 'crm.vehicle.notice.edit', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Notiz', disabled: false, to: {name: 'crm.vehicle.notice.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            async submitNoticeData() {
                this.isLoading = true

                const customerId = this.$route.params.customer
                const vehicleId = this.$route.params.vehicle

                if (this.isEdit) {
                    console.log('NYI')
                }
                else {
                    await window.axios.post(`/crm/customer/${customerId}/vehicle/${vehicleId}/notice/create`, this.formData)
                        .then((response) => {
                            if (response.data.success) {
                                this.$router.push({
                                    name: 'crm.vehicle.details',
                                    params: {
                                        customer: customerId,
                                        vehicle: vehicleId
                                    }
                                })
                            }
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            async fetchNoticeTypes () {
                this.isLoadingTypeData = true

                await window.axios.get('/crm/vehicle/notice/types')
                    .then((response) => {
                        this.typeData = response.data.types

                        this.formData.type_id = response.data.default.id
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingTypeData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
