<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Ideenbox</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           :to="{ name: 'user.ideaboard.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neue Idee
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2">
                    <v-list>
                        <v-subheader>Kategorien</v-subheader>
                        <v-list-item-group v-model="selectedListItemCategory">
                            <v-list-item
                                v-for="item in categoryData"
                                :key="item.id"
                            >
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.description"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-col>
                <v-col cols="12" md="10">
                    <v-select
                        v-model="selectedStatus"
                        label="Status"
                        :items="statusData"
                        item-text="description"
                        item-value="id"
                        @change="fetchData()"
                        :loading="isLoadingStatusData"
                        color="blue-grey"
                        outlined
                        dense
                    >
                    </v-select>
                    <v-skeleton-loader
                        type="card"
                        transition="scale-transition"
                        v-if="isLoadingItemBoardData"
                    ></v-skeleton-loader>
                    <template v-if="isEmptyIdeaBoardData === 0">
                        Keine Daten vorhanden.
                    </template>
                    <v-card
                        v-else
                        v-for="item in ideaBoardData"
                        :key="item.id"
                        class="mb-2"
                        outlined
                        link
                        :to="{name: 'user.ideaboard.details', params: { ideaboard: item.id }}"
                    >
                        <v-card-title>{{ item.title }}</v-card-title>
                        <v-card-text>
                            {{ item.text }}
                        </v-card-text>
                        <v-card-actions>
                            <v-list-item class="grow">
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.user.full_name }} vom {{ item.created_at }}</v-list-item-title>
                                </v-list-item-content>
                                <v-row
                                    align="center"
                                    justify="end"
                                >
                                    <v-icon class="mr-1">mdi-comment</v-icon>
                                    <span class="subheading mr-2">{{ item.comment_count }}</span>
                                </v-row>
                            </v-list-item>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserIdeaBoardIndex',
        data () {
            return {
                isLoadingStatusData: false,
                isLoadingItemBoardData: false,
                isError: false,
                isEmptyIdeaBoardData: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Ideenbox', disabled: false, to: { name: 'user.ideaboard.index' }, exact: true }
                ],
                ideaBoardData: [],
                categoryData: [],
                statusData: [],
                selectedStatus: '',
                selectedCategory: '',
                selectedListItemCategory: 0
            }
        },
        mounted () {
            this.fetchData()
            this.fetchCategoryData()
            this.fetchStatusData()
        },
        watch: {
            selectedListItemCategory: function () {
                if (this.selectedListItemCategory === undefined) {
                    Vue.set(this, 'selectedCategory', '')
                }
                else {
                    Vue.set(this, 'selectedCategory', this.categoryData[this.selectedListItemCategory].id)
                }

                this.fetchData()
            }
        },
        methods: {
            async fetchCategoryData () {
                await window.axios.get('/user/ideaboard/categories')
                    .then((response) => {
                        this.categoryData = response.data
                        //this.categoryData = _.orderBy(this.categoryData, 'description')
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            async fetchStatusData () {
                this.isLoadingStatusData = true

                await window.axios.get('/user/ideaboard/status')
                    .then((response) => {
                        this.statusData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoadingStatusData = false
                    })
            },
            async fetchData () {
                this.ideaBoardData = []
                this.isLoadingItemBoardData = true

                let categoryId = this.selectedCategory
                let statusId = this.selectedStatus

                await window.axios.get(`/user/ideaboard?filter[status_id]=${statusId}&filter[category_id]=${categoryId}`)
                    .then((response) => {
                        this.isEmptyIdeaBoardData = response.data.length
                        this.ideaBoardData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isLoadingItemBoardData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
