<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Fahrzeugübersicht</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin|crm-admin')"
                           :to="{ name: 'crm.customer.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neuer Kunde
                    </v-btn>
                </v-col>
            </v-row>
            <v-text-field
                v-model="search"
                append-icon="mdi-feature-search"
                label="Suche"
                color="blue-grey"
                outlined
                dense
            ></v-text-field>
            <v-skeleton-loader
                type="table"
                transition="scale-transition"
                :loading="isLoadingVehicleData"
            >
                <v-data-table
                    :headers="headers"
                    :items="vehicleData"
                    :items-per-page="10"
                    :search="search"
                    class="elevation-1"
                    disable-sort
                >
                    <template v-slot:body="props">
                        <tbody>
                            <tr v-for="item in props.items">
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.vehicle }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.license_plate }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.vin }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.registration_date }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.inspection }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.engine_code }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.gear_code }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.manufacturer_code }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    {{ item.model_code }}
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'crm.customer.details', params: { customer: item.id } }">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </router-link>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'CRMVehicleIndex',
        data () {
            return {
                isLoadingVehicleData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Fahrzeugübersicht', disabled: false, to: { name: 'crm.vehicle.index' }, exact: true }
                ],
                search: '',
                vehicleData: [],
                headers: [
                    { text: 'Fahrzeug', value: 'vehicle' },
                    { text: 'Kennzeichen', value: 'license_plate' },
                    { text: 'FIN', value: 'vin' },
                    { text: 'Erstzulassung', value: 'registration_date' },
                    { text: 'HU/AU', value: 'inspection' },
                    { text: 'MKB', value: 'engine_code' },
                    { text: 'GKB', value: 'gear_code' },
                    { text: 'HSN', value: 'manufacturer_code' },
                    { text: 'TSN', value: 'model_code' },
                    { }
                ]
            }
        },
        mounted () {
            this.fetchVehicleData()
        },
        methods: {
            async fetchVehicleData () {
                this.isLoadingVehicleData = true

                await window.axios.get('/crm/vehicle')
                    .then((response) => {
                        this.vehicleData = response.data
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingVehicleData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
