var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isError)?[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neue Idee")]:[_vm._v("Idee")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Idee\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2)],1)],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Kategorie","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.categoryData,"item-text":"description","item-value":"id","loading":_vm.isLoadingCategoryData,"color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Kategorie "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Titel","rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"hint":"kurze Zusammenfassung der Idee","color":"blue-grey","counter":"100","loading":_vm.isLoadingIdeaBoardData,"outlined":"","dense":"","autofocus":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Titel "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Beschreibung","rules":"required|min:10|max:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Beschreibung","color":"blue-grey","counter":"1000","loading":_vm.isLoadingIdeaBoardData,"outlined":""},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})]}}],null,true)})],1)],1)],1)]}}],null,false,3913199645)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }