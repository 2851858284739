import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import colors from 'vuetify/lib/util/colors'
import de from 'vuetify/src/locale/de.ts'
import en from 'vuetify/src/locale/en.ts'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.red.darken1,
                secondary: colors.grey.darken2,
                accent: colors.shades.black,
                error: colors.red.accent4,
            },
        },
    },
    icons: {
        iconfont: 'mdi'
    },
    lang: {
        locales: { de, en },
        current: 'de'
    }
})
