var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-content',[_c('error',{attrs:{"showMe":_vm.isError}}),_vm._v(" "),(!_vm.isError)?[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitDocumentAssignmentData($event)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"headline"},[(!_vm.isEdit)?[_vm._v("Neue Auftragsbestätigung")]:[_vm._v("Auftragsbestätigung bearbeiten")]],2),_vm._v(" "),_c('v-breadcrumbs',{staticClass:"mt-2 pa-0",attrs:{"items":_vm.breadcrumbItems}})],1),_vm._v(" "),_c('v-col',{staticClass:"text-md-right",attrs:{"cols":"12","md":"6"}},[(_vm.$hasRole('admin|crm-admin'))?_c('v-btn',{attrs:{"depressed":"","color":"secondary","disabled":invalid,"loading":_vm.isLoading,"type":"submit"}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Auftragsbestätigung\n                            "),(!_vm.isEdit)?[_vm._v("Speichern")]:[_vm._v("Aktualisieren")]],2):_vm._e()],1)],1),_vm._v(" "),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('ValidationProvider',{attrs:{"name":"Auftragsnummer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Auftragsnummer","error-messages":errors,"color":"blue-grey","disabled":"","outlined":"","dense":""},model:{value:(_vm.formData.number),callback:function ($$v) {_vm.$set(_vm.formData, "number", $$v)},expression:"formData.number"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.addressData,"item-text":"description","item-value":"id","color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    Adresse "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.address_id),callback:function ($$v) {_vm.$set(_vm.formData, "address_id", $$v)},expression:"formData.address_id"}})]}}],null,true)}),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[(_vm.customerData.phone)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":("Telefon: " + (_vm.customerData.phone)),"color":"blue-grey","dense":""},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})],1):_vm._e(),_vm._v(" "),(_vm.customerData.fax)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":("Telefax: " + (_vm.customerData.fax)),"color":"blue-grey","dense":""},model:{value:(_vm.formData.fax),callback:function ($$v) {_vm.$set(_vm.formData, "fax", $$v)},expression:"formData.fax"}})],1):_vm._e(),_vm._v(" "),(_vm.customerData.mobile)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":("Mobil: " + (_vm.customerData.mobile)),"color":"blue-grey","dense":""},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", $$v)},expression:"formData.mobile"}})],1):_vm._e(),_vm._v(" "),(_vm.customerData.email)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":("E-Mail: " + (_vm.customerData.email)),"color":"blue-grey","dense":""},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})],1):_vm._e()],1),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"km-Stand","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"blue-grey","outlined":"","dense":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n                                    km-Stand "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("* ")])])]},proxy:true}],null,true),model:{value:(_vm.formData.mileage),callback:function ($$v) {_vm.$set(_vm.formData, "mileage", $$v)},expression:"formData.mileage"}})]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Wertsachen im Fahrzeug","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Wertsachen im Fahrzeug","error-messages":errors,"color":"blue-grey","outlined":"","dense":""},model:{value:(_vm.formData.valuables),callback:function ($$v) {_vm.$set(_vm.formData, "valuables", $$v)},expression:"formData.valuables"}})]}}],null,true)}),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Einholung von Gutachten nach §19 und §21 StVZO","color":"blue-grey","dense":""},model:{value:(_vm.formData.report_19_21_stvzo),callback:function ($$v) {_vm.$set(_vm.formData, "report_19_21_stvzo", $$v)},expression:"formData.report_19_21_stvzo"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Fotos des Fahrzeuges als Referenz auf Publikationen","color":"blue-grey","dense":""},model:{value:(_vm.formData.publication_reference),callback:function ($$v) {_vm.$set(_vm.formData, "publication_reference", $$v)},expression:"formData.publication_reference"}})],1)],1),_vm._v(" "),_vm._l((_vm.formData.description),function(item,index){return _c('ValidationProvider',{key:index,attrs:{"name":"Beschreibung","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{key:index,attrs:{"value":item,"label":("auszuführende Arbeiten (Zeile " + (index+1) + ")"),"error-messages":errors,"color":"blue-grey","prepend-icon":"mdi-plus-box","append-icon":"mdi-delete"},on:{"click:prepend":function($event){return _vm.addDescription(errors)},"click:append":function($event){return _vm.removeDescription(index)}},model:{value:(_vm.formData.description[index].description),callback:function ($$v) {_vm.$set(_vm.formData.description[index], "description", $$v)},expression:"formData.description[index].description"}})]}}],null,true)})}),_vm._v(" "),_c('v-checkbox',{attrs:{"label":"Paketpreis nach Vereinbarung","color":"blue-grey"},model:{value:(_vm.formData.price_package),callback:function ($$v) {_vm.$set(_vm.formData, "price_package", $$v)},expression:"formData.price_package"}}),_vm._v(" "),(!_vm.formData.price_package)?_c('ValidationProvider',{attrs:{"name":"Preis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-currency-field',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"label":"Preis","color":"blue-grey","prefix":"€","outlined":"","dense":""},model:{value:(_vm.formData.price),callback:function ($$v) {_vm.$set(_vm.formData, "price", $$v)},expression:"formData.price"}})]}}],null,true)}):_vm._e(),_vm._v(" "),_c('ValidationProvider',{attrs:{"name":"Fahrzeugannahme","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fahrzeugannahme","error-messages":errors,"color":"blue-grey","type":"date"},model:{value:(_vm.formData.date_signed),callback:function ($$v) {_vm.$set(_vm.formData, "date_signed", $$v)},expression:"formData.date_signed"}})]}}],null,true)}),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Fertigstellung","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Fahrzeugannahme","error-messages":errors,"color":"blue-grey","type":"date"},model:{value:(_vm.formData.date_finished),callback:function ($$v) {_vm.$set(_vm.formData, "date_finished", $$v)},expression:"formData.date_finished"}})]}}],null,true)})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Uhrzeit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Uhrzeit","error-messages":errors,"color":"blue-grey","type":"time"},model:{value:(_vm.formData.time_finished),callback:function ($$v) {_vm.$set(_vm.formData, "time_finished", $$v)},expression:"formData.time_finished"}})]}}],null,true)})],1)],1),_vm._v(" "),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Werkstattbeiblatt anhängen","color":"blue-grey","dense":""},model:{value:(_vm.formData.garage_leaflet),callback:function ($$v) {_vm.$set(_vm.formData, "garage_leaflet", $$v)},expression:"formData.garage_leaflet"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-switch',{attrs:{"label":"Rädererfassungsbogen anhängen","color":"blue-grey","dense":""},model:{value:(_vm.formData.wheels_leaflet),callback:function ($$v) {_vm.$set(_vm.formData, "wheels_leaflet", $$v)},expression:"formData.wheels_leaflet"}})],1)],1)],2)],1)],1)]}}],null,false,2844916831)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }