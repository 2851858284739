import * as types from './mutation-types'

export const fetchAddress = ({ commit, dispatch }, { customerId, addressId }) => {
    return new Promise((resolve, reject) => {
        window.axios.get(`/crm/customer/${customerId}/address/${addressId}`).then((response) => {
            commit(types.BOOTSTRAP_ADDRESS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const addAddress = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.post(`/crm/customer/${data.customer_id}/address/create`, data).then((response) => {
            commit(types.ADD_ADDRESS, response.data)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const updateAddress = ({ commit, dispatch, state }, data) => {
    return new Promise((resolve, reject) => {
        window.axios.put(`/crm/customer/${data.customer_id}/address/${data.id}/update`, data).then((response) => {
            if(response.data.success){
                commit(types.UPDATE_ADDRESS, response.data)
            }
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

/*
export const deleteAddress = ({ commit, dispatch, state }, id) => {
    return new Promise((resolve, reject) => {
        window.axios.delete(`/crm/customers/${id}`).then((response) => {
            commit(types.DELETE_CUSTOMER, id)
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}
*/
