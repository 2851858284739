<template>
    <v-container>
        Logout
    </v-container>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'Logout',
        methods: {
            ...mapActions('auth', [
                'logout'
            ]),
        },
        mounted () {
            this.logout()
        }
    }
</script>

<style scoped>

</style>
