<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitUserData">
                    <v-row>
                        <v-col cols="12" md="6">
                            <div class="headline">
                                <template v-if="!isEdit">Neuer Benutzer</template>
                                <template v-else>Profil bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="6" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Profil
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <v-text-field
                                v-if="isEdit"
                                v-model="formData.username"
                                label="Benutzername"
                                color="blue-grey"
                                disabled
                                outlined
                                dense
                            ></v-text-field>
                            <ValidationProvider vid="email" name="E-Mail-Adresse" rules="required|email" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.email"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    counter="50"
                                    :disabled="isEdit"
                                    :autofocus="!isEdit"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        E-Mail-Adresse <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider vid="first_name" name="Vorname" rules="required|min:3|max:50" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.first_name"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    counter="50"
                                    :autofocus="isEdit"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Vorname <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider vid="last_name" name="Nachname" rules="required|min:2|max:50" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.last_name"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    counter="50"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Nachname <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider vid="password" name="Kennwort" rules="required|min:8|max:50" v-slot="{ errors }">
                                <v-text-field
                                    v-if="!isEdit"
                                    v-model="formData.password"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="password"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Kennwort <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UserCreateEdit',
        data() {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true }
                ],
                formData: {
                    first_name: null,
                    last_name: null,
                    email: null,
                    password: null
                }
            }
        },
        mounted () {
            if (this.isEdit) {
                this.loadUserData()
            }
        },
        computed: {
            isEdit () {
                // admin Route
                if (this.$isAdmin()) {
                    this.breadcrumbItems.push(
                        { text: 'Benutzerübersicht', disabled: false, to: { name: 'user.index' }, exact: true }
                    )
                }

                if (this.$route.name === 'user.edit') {
                    this.breadcrumbItems.push(
                        { text: 'Benutzer-Details', disabled: false, to: { name: 'user.details', params: {user: this.$route.params.user }}, exact: true },
                        {text: 'Profil bearbeiten', disabled: false, to: {name: 'user.edit', params: {user: this.$route.params.user}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neuer Benutzer', disabled: false, to: {name: 'user.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            async submitUserData() {
                this.isLoading = true

                if (this.isEdit) {
                    const userId = this.$route.params.user

                    await window.axios.put(`/user/${userId}/update`, this.formData)
                        .then((response) => {
                            this.$router.push({
                                name: 'user.details',
                                params: { user: userId }
                            })
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
                else {
                    await window.axios.post(`/user/create`, this.formData)
                        .then((response) => {
                            this.$router.push({
                                name: 'user.details',
                                params: { user: response.data.userId }
                            })
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            async loadUserData () {
                this.isBusy = true

                const userId = this.$route.params.user

                await window.axios.get(`/user/${userId}/edit`)
                    .then((response) => {
                        this.formData = response.data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
