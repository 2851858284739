<template>
    <v-content>
        <error :showMe="isError" />
        <template v-if="!isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Artikel</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin|fi-admin')"
                           :to="{ name: 'fi.item.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neuer Artikel
                    </v-btn>
                </v-col>
            </v-row>
            <v-skeleton-loader
                type="table"
                transition="scale-transition"
                :loading="isLoadingItemData"
            >
                <v-data-table
                    :headers="headers"
                    :items="itemData"
                    :items-per-page="10"
                    elevation="0"
                >
                    <template v-slot:body="props">
                        <tbody>
                            <tr v-for="item in props.items">
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.item.edit', params: { item: item.id } }">
                                    {{ item.name }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.item.edit', params: { item: item.id } }">
                                    {{ item.description }}
                                </router-link>
                                <router-link tag="td" style="cursor:pointer" :to="{ name: 'fi.item.edit', params: { item: item.id } }">
                                    {{ item.unit.name }}
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'fi.item.edit', params: { item: item.id } }">
                                    {{ $utils.formatMoney(item.price) }}
                                </router-link>
                                <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'fi.item.edit', params: { item: item.id } }">
                                    <v-icon>mdi-chevron-right</v-icon>
                                </router-link>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'FIItemIndex',
        data () {
            return {
                isLoadingItemData: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Artikel', disabled: false, to: { name: 'fi.item.index' }, exact: true }
                ],
                headers: [
                    { text: 'Name', value: 'name' },
                    { text: 'Beschreibung', value: 'description' },
                    { text: 'Einheit', value: 'unit_id' },
                    { text: 'Preis', value: 'price', align: 'right' },
                    { }
                ],
                itemData: []
            }
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            async fetchData () {
                this.isLoadingItemData = true

                await window.axios.get('/fi/item')
                    .then((response) => {
                        this.itemData = response.data
                    })
                    .catch(() => {
                        this.isError = true
                    })
                    .finally(() => {
                        this.isLoadingItemData = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
