<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <v-row>
                <v-col cols="12" md="6">
                    <div class="headline">Benutzerübersicht</div>
                    <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                </v-col>
                <v-col cols="12" md="6" class="text-md-right">
                    <v-btn depressed
                           color="secondary"
                           v-if="$hasRole('admin')"
                           :to="{ name: 'user.create' }"
                    >
                        <v-icon left small>mdi-plus</v-icon> Neuer Benutzer
                    </v-btn>
                </v-col>
            </v-row>
            <v-text-field
                v-model="search"
                append-icon="mdi-account-search"
                label="Suche"
                single-line
                hide-details
                color="blue-grey"
            ></v-text-field>
            <v-data-table
                :headers="headers"
                :items="userData"
                :items-per-page="10"
                :search="search"
                class="elevation-1"
                disable-sort
            >
                <template v-slot:body="props">
                    <tbody>
                    <tr v-for="item in props.items">
                        <router-link tag="td" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            {{ item.id }}
                        </router-link>
                        <router-link tag="td" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            {{ item.first_name }}
                        </router-link>
                        <router-link tag="td" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            {{ item.last_name }}
                        </router-link>
                        <router-link tag="td" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            {{ item.email }}
                        </router-link>
                        <router-link tag="td" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            {{ item.created_at }}
                        </router-link>
                        <router-link tag="td" class="text-right" style="cursor:pointer" :to="{ name: 'user.details', params: { user: item.id } }">
                            <v-icon>mdi-chevron-right</v-icon>
                        </router-link>
                    </tr>
                    </tbody>
                </template>
            </v-data-table>
        </template>
    </v-content>
</template>

<script>
    export default {
        name: 'UsersIndex',
        data () {
            return {
                isBusy: false,
                isError: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Benutzerübersicht', disabled: false, to: { name: 'user.index' }, exact: true }
                ],
                search: '',
                userData: [],
                headers: [
                    { text: 'Benutzer', value: 'id' },
                    { text: 'Vorname', value: 'first_name' },
                    { text: 'Nachname', value: 'last_name' },
                    { text: 'E-Mail-Adresse', value: 'email' },
                    { text: 'Erstellt', value: 'created_at' },
                    { }
                ]
            }
        },
        mounted () {
            this.fetchData()
        },
        methods: {
            async fetchData () {
                this.isBusy = true

                await window.axios.get('/user')
                    .then((response) => {
                        this.userData = response.data
                    })
                    .catch((e) => {
                        console.log(e)
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
