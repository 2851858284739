<template>
    <v-app id="erp" v-if="isAppLoaded">
        <v-navigation-drawer
            app
            v-model="drawer"
        >
            <v-list dense>
                <router-link :to="{ name: 'dashboard' }">
                    <v-list-item link>
                        <v-list-item-action>
                            <v-icon>mdi-home</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Dashboard</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <v-subheader>CRM</v-subheader>
                <router-link :to="{ name: 'crm.customer.index' }">
                    <v-list-item link>
                        <v-list-item-action>
                            <v-icon small>mdi-account-box-multiple</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Kundenübersicht</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <router-link :to="{ name: 'crm.vehicle.index' }">
                    <v-list-item link>
                        <v-list-item-action>
                            <v-icon small>mdi-car-side</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Fahrzeugübersicht</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
                <template v-if="$hasRole('admin|fi-admin|fi-user')">
                    <v-subheader>FI</v-subheader>
                    <router-link :to="{ name: 'fi.item.index' }">
                        <v-list-item link>
                            <v-list-item-action>
                                <v-icon small>mdi-file-document-box-multiple</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Artikel</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                    <router-link :to="{ name: 'fi.invoice.index' }">
                        <v-list-item link>
                            <v-list-item-action>
                                <v-icon small>mdi-file-document</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Rechnungen</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </template>
                <v-subheader>Benutzer</v-subheader>
                <template v-if="$hasRole('admin|time-management-admin|time-management-user')">
                    <router-link :to="{ name: 'user.timemanagement.index' }">
                        <v-list-item link>
                            <v-list-item-action>
                                <v-icon small>mdi-calendar-clock</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>Zeiterfassung</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </router-link>
                </template>
                <router-link :to="{ name: 'user.ideaboard.index' }">
                    <v-list-item link>
                        <v-list-item-action>
                            <v-icon small>mdi-lightbulb-on</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>Ideenbox</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </router-link>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar
            app
            color="red darken-4"
            dark
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
            <v-toolbar-title>Sportwagenwerk ERP</v-toolbar-title>
            <v-spacer></v-spacer>
            <!--<v-dropdown :show-arrow="false">
                <a
                    slot="activator"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="avatar"
                >
                    {{ userName }}
                </a>
                <v-dropdown-item>
                    <router-link class="dropdown-item" to="/admin/settings">
                        <font-awesome-icon icon="cogs" class="dropdown-item-icon"/> <span> ggg </span>
                    </router-link>
                </v-dropdown-item>
                <v-dropdown-item>
                    <a
                        href="#"
                        class="dropdown-item"
                        @click.prevent="logout"
                    >
                        <font-awesome-icon icon="sign-out-alt" class="dropdown-item-icon"/> <span> logout </span>
                    </a>
                </v-dropdown-item>
            </v-dropdown>-->
            <v-menu bottom left>
                <template v-slot:activator="{ on }">
                    <v-btn text v-on="on">
                        {{ userName }}
                        <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item
                        v-for="(item, i) in userMenu"
                        :key="i"
                        :to="{ name: item.link, params: { user: userId } }"
                        v-if="!item.role || (item.role && userRoles[item.role] === true)"
                        exact
                    >
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>

        <v-container fluid class="ml-md-2 pr-md-6">
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view />
            </transition>
        </v-container>

        <v-footer
            app
            color="red darken-4"
        >
            <v-spacer></v-spacer>
            <span class="white--text">&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
    </v-app>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex'

    export default {
        name: 'Layout',
        data () {
            return {
                drawer: null,
                userId: null,
                userName: '',
                userRoles: {},
                userMenu: [
                    {
                        title: 'Benutzer verwalten',
                        link: 'user.index',
                        role: 'admin'
                    },
                    {
                        title: 'Profil bearbeiten',
                        link: 'user.edit',
                        role: null
                    },
                    {
                        title: 'Kennwort ändern',
                        link: 'user.password.edit',
                        role: null
                    },
                    {
                        title: 'Logout',
                        link: 'logout',
                        role: null
                    },
                ],
            }
        },
        created () {
            this.bootstrap().then((res) => {
                this.userId = this.$store.state.user.currentUser.id
                this.userName = this.$store.state.user.currentUser.full_name
                this.userRoles = this.$store.state.user.currentUser.roles
            }).catch(() => {
                this.logout()
            })
        },
        methods: {
            ...mapActions('auth', [
                'logout'
            ]),
            ...mapActions(['bootstrap'])
        },
        computed: {
            ...mapGetters([
                'isAppLoaded'
            ])
        }
    }
</script>

<style scoped>
    #erp {
        font-family: "Segoe UI", Calibri, Candara, Arial, sans-serif !important;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s ease;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>
