<template>
    <v-content>
        <please-wait :showMe="isBusy" />
        <error :showMe="isError" />
        <template v-if="!isBusy && !isError">
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <form @submit.prevent="submitData">
                    <v-row>
                        <v-col cols="12" md="9">
                            <div class="headline">
                                <template v-if="!isEdit">Neue Fahrzeug-Rädereinlagerung</template>
                                <template v-else>Fahrzeug-Rädereinlagerung bearbeiten</template>
                            </div>
                            <v-breadcrumbs :items="breadcrumbItems" class="mt-2 pa-0"></v-breadcrumbs>
                        </v-col>
                        <v-col cols="12" md="3" class="text-md-right">
                            <v-btn depressed
                                   color="secondary"
                                   v-if="$hasRole('admin|crm-admin')"
                                   :disabled="invalid"
                                   :loading="isLoading"
                                   type="submit"
                            >
                                <v-icon left small>mdi-content-save</v-icon> Rädereinlagerung
                                <template v-if="!isEdit">Speichern</template>
                                <template v-else>Aktualisieren</template>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card outlined>
                        <v-card-text>
                            <ValidationProvider name="Art" rules="required" v-slot="{ errors }">
                                <v-select
                                    v-model="formData.tyre_type_id"
                                    :error-messages="errors"
                                    :items="tyreTypes"
                                    item-text="description"
                                    item-value="id"
                                    color="blue-grey"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Art <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-select>
                            </ValidationProvider>
                            <ValidationProvider name="km-Stand" rules="required|numeric" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.mileage"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="number"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        km-Stand <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Lagerort" rules="max:50" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.location"
                                    label="Lagerort"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Einlagerung" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.date_stored"
                                    label="Einlagerung*"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="date"
                                    outlined
                                    dense
                                >
                                    <template #label>
                                        Einlagerung <span class="red--text"><strong>* </strong></span>
                                    </template>
                                </v-text-field>
                            </ValidationProvider>
                            <ValidationProvider name="Wechseltermin" v-slot="{ errors }">
                                <v-text-field
                                    v-model="formData.date_changed"
                                    label="Wechseltermin"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    type="date"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <v-switch
                                v-model="formData.has_tpms"
                                color="blue-grey"
                                dense
                            >
                                <template #label>
                                    RDKS <span class="red--text"><strong>* </strong></span>
                                </template>
                            </v-switch>
                            <v-switch
                                v-model="formData.has_wheel_bolts"
                                color="blue-grey"
                                dense
                            >
                                <template #label>
                                    Radschrauben <span class="red--text"><strong>* </strong></span>
                                </template>
                            </v-switch>
                            <ValidationProvider name="Radschrauben (Beschreibung)" rules="max:255" v-slot="{ errors }" v-if="formData.has_wheel_bolts">
                                <v-text-field
                                    v-model="formData.wheel_bolts_description"
                                    label="Radschrauben (Beschreibung)"
                                    :error-messages="errors"
                                    color="blue-grey"
                                    outlined
                                    dense
                                ></v-text-field>
                            </ValidationProvider>
                            <v-card outlined>
                                <v-card-title>Vorderachse</v-card-title>
                                <v-card-text>
                                    <ValidationProvider name="Hersteller" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="frontTyreManufacturer"
                                            :error-messages="errors"
                                            :items="tyreManufacturers"
                                            item-text="description"
                                            item-value="id"
                                            color="blue-grey"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="openTyreManufacturerModal"
                                            outlined
                                            dense
                                            return-object
                                        >
                                            <template #label>
                                                Hersteller <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-select>
                                    </ValidationProvider>
                                    <ValidationProvider name="Modell" rules="required" v-slot="{ errors }">
                                        <v-select
                                            v-model="formData.front_tyre_model_id"
                                            :error-messages="errors"
                                            :items="frontTyreModelsFiltered"
                                            item-text="description"
                                            item-value="id"
                                            color="blue-grey"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="openTyreModelModal('front')"
                                            outlined
                                            dense
                                        >
                                            <template #label>
                                                Modell <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-select>
                                    </ValidationProvider>
                                    <v-row dense>
                                        <v-col>
                                            <ValidationProvider name="Reifenbreite" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_tyre_width_id"
                                                    label="Reifenbreite"
                                                    :error-messages="errors"
                                                    :items="tyreWidths"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenbreite <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Reifenhöhe" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_tyre_height_id"
                                                    :error-messages="errors"
                                                    :items="tyreHeights"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenhöhe <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Reifenart" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_tyre_construction_type_id"
                                                    :error-messages="errors"
                                                    :items="tyreConstructionTypes"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenart <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Geschwindigkeitsindex" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_tyre_speed_rating_id"
                                                    :error-messages="errors"
                                                    :items="tyreSpeedRatings"
                                                    item-text="full_description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Geschwindigkeitsindex <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Lastindex" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_tyre_load_index_id"
                                                    :error-messages="errors"
                                                    :items="tyreLoadIndexes"
                                                    item-text="full_description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Lastindex <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Felgenbreite" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_rim_width_id"
                                                    :error-messages="errors"
                                                    :items="rimWidths"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Felgenbreite <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Felgengröße" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_rim_diameter_id"
                                                    label="Felgengröße"
                                                    :error-messages="errors"
                                                    :items="rimDiameters"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Felgengröße <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Einpresstiefe" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.front_rim_offset_id"
                                                    label="Einpresstiefe"
                                                    :error-messages="errors"
                                                    :items="rimOffsets"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Einpresstiefe <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifendruck" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.pressure_front"
                                                    label="Reifendruck"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                v-model="formData.torque_front"
                                                label="Anzugsdrehmoment"
                                                :error-messages="errors"
                                                :items="torqueTypes"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-select>
                                            <!--<ValidationProvider name="Anzugsdrehmoment" rules="numeric" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.torque_front"
                                                    label="Anzugsdrehmoment"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>-->
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Profiltiefe Vorne Links" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.depth_front_left"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Profiltiefe Vorne Links <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Profiltiefe Vorne Rechts" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.depth_front_right"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Profiltiefe Vorne Rechts <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="DOT Vorne Links" rules="required|numeric|length:4" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.dot_front_left"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    placeholder="KWJJ"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        DOT Vorne Links <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="DOT Vorne Rechts" rules="required|numeric|length:4" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.dot_front_right"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    placeholder="KWJJ"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        DOT Vorne Rechts <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifenschäden Vorne Links" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_tyre_front_left"
                                                    label="Reifenschäden Vorne Links"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifenschäden Vorne Rechts" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_tyre_front_right"
                                                    label="Reifenschäden Vorne Rechts"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Felgenschäden Vorne Links" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_rim_front_left"
                                                    label="Felgenschäden Vorne Links"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Felgenschäden Vorne Rechts" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_rim_front_right"
                                                    label="Felgenschäden Vorne Rechts"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <br>
                            <v-card outlined>
                                <v-card-title>
                                    <v-checkbox v-model="formData.tyre_rear_is_equal_front"></v-checkbox>
                                    Hinterachse
                                    <template v-if="formData.tyre_rear_is_equal_front">(entspricht Vorderachse)</template>
                                </v-card-title>
                                <v-card-text>
                                    <ValidationProvider name="Hersteller" rules="required" v-slot="{ errors }" v-if="!formData.tyre_rear_is_equal_front">
                                        <v-select
                                            v-model="rearTyreManufacturer"
                                            :error-messages="errors"
                                            :items="tyreManufacturers"
                                            item-text="description"
                                            item-value="id"
                                            color="blue-grey"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="openTyreManufacturerModal"
                                            outlined
                                            dense
                                            return-object
                                        >
                                            <template #label>
                                                Hersteller <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-select>
                                    </ValidationProvider>
                                    <ValidationProvider name="Modell" rules="required" v-slot="{ errors }" v-if="!formData.tyre_rear_is_equal_front">
                                        <v-select
                                            v-model="formData.rear_tyre_model_id"
                                            :error-messages="errors"
                                            :items="rearTyreModelsFiltered"
                                            item-text="description"
                                            item-value="id"
                                            color="blue-grey"
                                            append-outer-icon="mdi-plus"
                                            @click:append-outer="openTyreModelModal('rear', formData.rear_tyre_manufacturer_id)"
                                            outlined
                                            dense
                                        >
                                            <template #label>
                                                Modell <span class="red--text"><strong>* </strong></span>
                                            </template>
                                        </v-select>
                                    </ValidationProvider>
                                    <v-row dense v-if="!formData.tyre_rear_is_equal_front">
                                        <v-col>
                                            <ValidationProvider name="Reifenbreite" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_tyre_width_id"
                                                    :error-messages="errors"
                                                    :items="tyreWidths"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenbreite <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Reifenhöhe" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_tyre_height_id"
                                                    :error-messages="errors"
                                                    :items="tyreHeights"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenhöhe <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Reifenart" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_tyre_construction_type_id"
                                                    :error-messages="errors"
                                                    :items="tyreConstructionTypes"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Reifenart <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Geschwindigkeitsindex" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_tyre_speed_rating_id"
                                                    :error-messages="errors"
                                                    :items="tyreSpeedRatings"
                                                    item-text="full_description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Geschwindigkeitsindex <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col>
                                            <ValidationProvider name="Lastindex" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_tyre_load_index_id"
                                                    :error-messages="errors"
                                                    :items="tyreLoadIndexes"
                                                    item-text="full_description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Lastindex <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense v-if="!formData.tyre_rear_is_equal_front">
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Felgenbreite" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_rim_width_id"
                                                    :error-messages="errors"
                                                    :items="rimWidths"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Felgenbreite <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Felgengröße" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_rim_diameter_id"
                                                    :error-messages="errors"
                                                    :items="rimDiameters"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Felgengröße <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <ValidationProvider name="Einpresstiefe" rules="required" v-slot="{ errors }">
                                                <v-select
                                                    v-model="formData.rear_rim_offset_id"
                                                    :error-messages="errors"
                                                    :items="rimOffsets"
                                                    item-text="description"
                                                    item-value="id"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Einpresstiefe <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifendruck" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.pressure_rear"
                                                    label="Reifendruck"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-select
                                                v-model="formData.torque_rear"
                                                label="Anzugsdrehmoment"
                                                :error-messages="errors"
                                                :items="torqueTypes"
                                                color="blue-grey"
                                                outlined
                                                dense
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Profiltiefe Hinten Links" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.depth_rear_left"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Profiltiefe Hinten Links <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Profiltiefe Hinten Rechts" rules="required" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.depth_rear_right"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    step=".1"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        Profiltiefe Hinten Rechts <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="DOT Hinten Links" rules="required|numeric|length:4" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.dot_rear_left"
                                                    label="DOT Hinten Links*"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    placeholder="KWJJ"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        DOT Hinten Links <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="DOT Hinten Rechts" rules="required|numeric|length:4" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.dot_rear_right"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    type="number"
                                                    placeholder="KWJJ"
                                                    outlined
                                                    dense
                                                >
                                                    <template #label>
                                                        DOT Hinten Rechts <span class="red--text"><strong>* </strong></span>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifenschäden Hinten Links" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_tyre_rear_left"
                                                    label="Reifenschäden Hinten Links"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Reifenschäden Hinten Rechts" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_tyre_rear_right"
                                                    label="Reifenschäden Hinten Rechts"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Felgenschäden Hinten Links" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_rim_rear_left"
                                                    label="Felgenschäden Hinten Links"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <ValidationProvider name="Felgenschäden Hinten Rechts" rules="max:100" v-slot="{ errors }">
                                                <v-text-field
                                                    v-model="formData.damage_rim_rear_right"
                                                    label="Felgenschäden Hinten Rechts"
                                                    :error-messages="errors"
                                                    color="blue-grey"
                                                    outlined
                                                    dense
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-card-text>
                    </v-card>
                </form>
            </ValidationObserver>
        </template>
        <tyre-manufacturer-create
            :showModal="showTyreManufacturerModal"
            @closeModal="closeTyreManufacturerModal"
            @updateManufacturer="updateTyreManufacturer"
        />
        <tyre-model-create
            :showModal="showTyreModelModal"
            :manufacturerData="tyreManufacturerModalData"
            @closeModal="closeTyreModelModal"
            @updateModel="updateTyreModel"
        />
    </v-content>
</template>

<script>
    import TyreManufacturerCreate from '@/views/crm/vehicle/tyre/manufacturer/Create'
    import TyreModelCreate from '@/views/crm/vehicle/tyre/model/Create'

    export default {
        name: 'CRMVehicleWheelstorageCreate',
        components: {
            TyreManufacturerCreate,
            TyreModelCreate
        },
        data() {
            return {
                isBusy: false,
                isError: false,
                isLoading: false,
                breadcrumbItems: [
                    { text: 'Dashboard', disabled: false, to: { name: 'dashboard' }, exact: true },
                    { text: 'Kundenübersicht', disabled: false, to: { name: 'crm.customer.index' }, exact: true },
                    { text: `Kunden-Details`, disabled: false, to: {name: 'crm.customer.details', params: {customer: this.$route.params.customer}}, exact: true},
                    { text: 'Kunden-Fahrzeug', disabled: false, to: {name: 'crm.vehicle.details', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true }
                ],
                showTyreManufacturerModal: false,
                showTyreModelModal: false,
                tyreManufacturerModalData: {},
                updateTyreModelType: null,
                customerId: this.$route.params.customer,
                vehicleId: this.$route.params.vehicle,
                tyreTypes: [],
                tyreManufacturers: [],
                tyreModels: [],
                frontTyreManufacturer: {}, // v-model for front tyre manufacturer
                frontTyreModelsFiltered: [],
                rearTyreManufacturer: {}, // v-model for rear tyre manufacturer
                rearTyreModelsFiltered: [],
                tyreWidths: [],
                tyreHeights: [],
                tyreConstructionTypes: [],
                tyreSpeedRatings: [],
                tyreLoadIndexes: [],
                rimDiameters: [],
                rimOffsets: [],
                rimWidths: [],
                torqueTypes: [90, 100, 120, 140, 180, 200],
                formData: {
                    tyre_rear_is_equal_front: true,
                    mileage: null,
                    tyre_type_id: null,
                    location: null,
                    date_stored: new Date().toISOString().slice(0,10),
                    date_changed: null,
                    has_tpms: false,
                    has_wheel_bolts: false,
                    wheel_bolts_description: null,
                    front_tyre_manufacturer_id: null,
                    front_tyre_model_id: null,
                    front_tyre_width_id: null,
                    front_tyre_height_id: null,
                    front_tyre_construction_type_id: null,
                    front_tyre_load_index_id: null,
                    front_tyre_speed_rating_id: null,
                    front_rim_diameter_id: null,
                    front_rim_width_id: null,
                    front_rim_offset_id: null,
                    rear_tyre_manufacturer_id: null,
                    rear_tyre_model_id: null,
                    rear_tyre_width_id: null,
                    rear_tyre_height_id: null,
                    rear_tyre_construction_type_id: null,
                    rear_tyre_load_index_id: null,
                    rear_tyre_speed_rating_id: null,
                    rear_rim_diameter_id: null,
                    rear_rim_width_id: null,
                    rear_rim_offset_id: null,
                    depth_front_left: null,
                    depth_front_right: null,
                    depth_rear_left: null,
                    depth_rear_right: null,
                    pressure_front: null,
                    pressure_rear: null,
                    torque_front: null,
                    torque_rear: null,
                    dot_front_left: null,
                    dot_front_right: null,
                    dot_rear_left: null,
                    dot_rear_right: null,
                    damage_tyre_front_left: null,
                    damage_tyre_front_right: null,
                    damage_tyre_rear_left: null,
                    damage_tyre_rear_right: null,
                    damage_rim_front_left: null,
                    damage_rim_front_right: null,
                    damage_rim_rear_left: null,
                    damage_rim_rear_right: null
                }
            }
        },
        mounted () {
            this.fetchData()

            // debug
            /*
            this.formData.mileage = 110000
            this.formData.tyre_type_id = 2
            this.formData.location = 'Ecke hinten links'
            this.formData.depth_front_left = '3,5'
            this.formData.depth_front_right = '3.5'
            this.formData.depth_rear_left = '6,0'
            this.formData.depth_rear_right = '6,0'
            this.formData.dot_front_left = 3615
            this.formData.dot_front_right = 4715
            this.formData.dot_rear_left = 4715
            this.formData.dot_rear_right = 4715
            */
        },
        watch: {
            'frontTyreManufacturer': function(value) {
                this.filterTyreModel('front')

                Vue.set(this.formData, 'front_tyre_manufacturer_id', value.id)
            },
            'rearTyreManufacturer': function(value) {
                this.filterTyreModel('rear')

                Vue.set(this.formData, 'rear_tyre_manufacturer_id', value.id)
            }
            /*'frontTyreManufacturer': function(value) {
                this.frontTyreModelsFiltered = this.tyreModels
                    .filter(item => item.manufacturer_id === value.id)
                    .map(({ id, description }) => ({ id: id, description: description }))

                Vue.set(this.formData, 'front_tyre_manufacturer_id', value.id)
            },
            'rearTyreManufacturer': function(value) {
                this.rearTyreModelsFiltered = this.tyreModels
                    .filter(item => item.manufacturer_id === value.id)
                    .map(({ id, description }) => ({ id: id, description: description }))

                Vue.set(this.formData, 'rear_tyre_manufacturer_id', value.id)
            }*/
        },
        computed: {
            isEdit () {
                if (this.$route.name === 'crm.vehicle.wheelstorage.edit') {
                    this.breadcrumbItems.push(
                        {text: 'Fahrzeug-Rädereinlagerung bearbeiten', disabled: false, to: {name: 'crm.vehicle.wheelstorage.edit', params: {customer: this.$route.params.customer, vehicle: this.$route.params.vehicle}}, exact: true}
                    )

                    return true
                }
                else {
                    this.breadcrumbItems.push(
                        {text: 'Neue Fahrzeug-Rädereinlagerung', disabled: false, to: {name: 'crm.vehicle.wheelstorage.create'}, exact: true}
                    )

                    return false
                }
            }
        },
        methods: {
            openTyreManufacturerModal () {
                this.showTyreManufacturerModal = true
            },
            closeTyreManufacturerModal () {
                this.showTyreManufacturerModal = false
            },
            updateTyreManufacturer (value) {
                this.closeTyreManufacturerModal()

                // add to array
                this.tyreManufacturers.push(value)

                // sort
                this.tyreManufacturers = this.tyreManufacturers.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase())
            },
            openTyreModelModal (type) {
                if (type === 'front') {
                    this.tyreManufacturerModalData = this.frontTyreManufacturer
                }
                else {
                    this.tyreManufacturerModalData = this.rearTyreManufacturer
                }

                this.updateTyreModelType = type
                this.showTyreModelModal = true
            },
            closeTyreModelModal () {
                this.showTyreModelModal = false
            },
            updateTyreModel (value) {
                this.closeTyreModelModal()

                // add to array
                this.tyreModels.push(value)

                // sort
                this.tyreModels = this.tyreModels.sort((a, b) => a.description.toLowerCase() > b.description.toLowerCase())

                // update tyremodels
                this.filterTyreModel()
            },
            filterTyreModel (type) {
                if (type === 'front' || type === undefined) {
                    this.frontTyreModelsFiltered = this.tyreModels
                        .filter(item => item.manufacturer_id === this.frontTyreManufacturer.id)
                        .map(({ id, description }) => ({ id: id, description: description }))
                }

                if (type === 'rear' || type === undefined) {
                    this.rearTyreModelsFiltered = this.tyreModels
                        .filter(item => item.manufacturer_id === this.rearTyreManufacturer.id)
                        .map(({id, description}) => ({id: id, description: description}))
                }
            },
            async submitData() {
                this.isLoading = true

                // replace commas
                this.formData.depth_front_left = this.formData.depth_front_left.replace(',', '.')
                this.formData.depth_front_right = this.formData.depth_front_right.replace(',', '.')
                this.formData.depth_rear_left = this.formData.depth_rear_left.replace(',', '.')
                this.formData.depth_rear_right = this.formData.depth_rear_right.replace(',', '.')
                if (this.formData.pressure_front !== null) {
                    this.formData.pressure_front = this.formData.pressure_front.replace(',', '.')
                }
                if (this.formData.pressure_rear !== null) {
                    this.formData.pressure_rear = this.formData.pressure_rear.replace(',', '.')
                }

                if (this.isEdit) {
                    try {
                        console.log('NYI')
                    }
                    catch (err) {
                        console.log(err)

                        this.isLoading = false
                    }
                }
                else {
                    await window.axios.post(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/wheelstorage/create`, this.formData)
                        .then((response) => {
                            this.$router.push({
                                name: 'crm.vehicle.wheelstorage.details',
                                params: { customer: this.customerId, vehicle: this.vehicleId, wheelstorage: response.data.wheel_storage_id }
                            })
                        })
                        .catch((error) => {
                            this.$refs.form.setErrors(error.response.data.errors)
                        })
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            async fetchData () {
                this.isBusy = true

                await window.axios.get(`/crm/customer/${this.customerId}/vehicle/${this.vehicleId}/wheelstorage/create`)
                    .then((response) => {
                        this.tyreTypes = response.data.tyre_types
                        this.tyreManufacturers = response.data.tyre_manufacturers
                        this.tyreModels = response.data.tyre_models
                        this.tyreWidths = response.data.tyre_widths
                        this.tyreHeights = response.data.tyre_heights
                        this.tyreConstructionTypes = response.data.tyre_construction_types
                        this.tyreSpeedRatings = response.data.tyre_speed_ratings
                        this.tyreLoadIndexes = response.data.tyre_load_indexes
                        this.rimDiameters = response.data.rim_diameters
                        this.rimOffsets = response.data.rim_offsets
                        this.rimWidths = response.data.rim_widths

                        // Default Front
                        this.formData.front_tyre_width_id = response.data.tyre_width_default
                        this.formData.front_tyre_height_id = response.data.tyre_height_default
                        this.formData.front_tyre_construction_type_id = response.data.tyre_construction_type_default
                        this.formData.front_tyre_speed_rating_id = response.data.tyre_speed_rating_default
                        this.formData.front_tyre_load_index_id = response.data.tyre_load_index_default
                        this.formData.front_rim_diameter_id = response.data.rim_diameter_default
                        this.formData.front_rim_offset_id = response.data.rim_offset_default
                        this.formData.front_rim_width_id = response.data.rim_width_default
                        this.formData.torque_front = 120

                        // Default Rear
                        this.formData.rear_tyre_width_id = response.data.tyre_width_default
                        this.formData.rear_tyre_height_id = response.data.tyre_height_default
                        this.formData.rear_tyre_construction_type_id = response.data.tyre_construction_type_default
                        this.formData.rear_tyre_speed_rating_id = response.data.tyre_speed_rating_default
                        this.formData.rear_tyre_load_index_id = response.data.tyre_load_index_default
                        this.formData.rear_rim_diameter_id = response.data.rim_diameter_default
                        this.formData.rear_rim_offset_id = response.data.rim_offset_default
                        this.formData.rear_rim_width_id = response.data.rim_width_default
                        this.formData.torque_rear = 120
                    })
                    .catch(() => {
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        }
    }
</script>

<style scoped>

</style>
